@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

} */
:root {
  --themColor: #04B7B1; /* Default color */
}

.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color:white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input{
  display: flex;
  border-radius: 5px;
   width:300px;
   background-color:#d9fbff;
   flex-direction: column;  
  
} 
.login_checkbox{
  display: flex;
 justify-content: space-between;
}

.btn{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img{
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color:white;

}
.eMed_dilboxAnimations {
  width: 5vw;
  height: 10vh;
}
.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
.eMed_login_checkbox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-top: 7%;
}

.butt {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
#label_link {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
#label_link:hover{
  color: blue;
  text-decoration: underline;
}
.eMed_textFiled {
  max-width: 90%;
}
.eMed_passwordTextbox {
  margin-top: 7%;
}
.eMed_visibleIcon {
  cursor: pointer;
}

.eMed_Login_HeadCon {
  margin: 1vw 1vw 0vw 1vw;
  display: flex;
  align-content: center;
}
.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.eMed_Forget_Con {
  flex: 1 1;
  flex-wrap: wrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
Button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7%;
  background-color: rgb(4, 183, 177);
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.link {
  color: white;
  text-decoration: none;
}

#eMed_Forget_Btn {
  margin: 1vw;
  width: 10vw;
  text-transform: capitalize;
}

#eMed_Forget_HelpText {
  margin: 1vw;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_Verify_HelpText {
  margin: 0vw 1vw 1vw 1vw;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_Forget_HeaderText {
  font-size: 1.3vw;
  font-weight: bold;
  margin-left: 1vw;
}

.eMed_Forget_HeadCon {
  margin: 1vw 1vw 0vw 1vw;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.eMed_Forget_Box {
  width: 25vw;
  height: 27vw;
  display: flex;
  flex-direction: column;
  border-radius: 0.3vw;
  box-shadow: 0vw 0.3vw 0.9vw 0vw #d3f5f3;
}


#otp_num1{
  padding: 8.5px 9px !important
}
#otp_num2{
  padding: 8.5px 9px !important
}
#otp_num3{
  padding: 8.5px 9px !important
}
#otp_num4{
  padding: 8.5px 9px !important
}
#otp_num5{
  padding: 8.5px 9px !important
}
#otp_num6{
  padding: 8.5px 9px !important
}

.eMed_Forget_ToastCon {
  width: 25vw;
  height: 4vw;
}

.eMed_Forget_Toast {
  min-width: 20vw;
  height: 2vw;
  margin: 0.5vw;
  border-radius: 0.25vw;
  display: flex;
  align-items: center;
  flex-direction: row;
}

#eMed_Forget_ToastText {
  color: white;
  margin-left: 1vw;
}

.eMed_Resent_CodeCon {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

#eMed_Resent_CodeText {
  font-size: 0.9vw;
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
}

#eMed_Resent_CodeText:hover {
  color: blue;
  text-decoration: underline;
}
.eMed_dialogHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_cancel {
  cursor: pointer;
}

.eMed_dialogBody {
  display: flex;
  flex-direction: column;
}

.eMed_dialogbodyContent {
  display: flex;
  justify-content: center;
}

#eMed_dlgBtn {
  display: flex;
  justify-content: center;
}

#eMed_Dialog_title {
  font-size: 1.2vw;
}
#emedhub_popup_mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}

#emedhub_popup_header {
    display: flex;
    justify-content: space-between;
    height: 3vw;
    padding: 0.9vw;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
}

#emedhub_popup_footer {
    padding: 0.625vw;
    background-color: #FAF9F9;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}

.emedhub_popup_footer_subdiv {
    display: flex;
    align-items: center;
    margin: -1vw;
}

.emedhub_popup_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.emedhub_popup_footer_Text {
    font-size: 0.85vw;
    color: rgba(0, 0, 0, 0.8);
    padding: 0 0.3vw;
}

.emedhub_popup_footer_Text1 {
    font-weight: 600;
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
}

.emedhub_popup_footer_Text2 {
    font-weight: 400;
    padding: 0 0.3vw;
}

.calenderWrap {
    display: inline-block;
    position: relative;
}

.inputBox {
    border: none;
    background-color: #FAF9F9;
    padding: 0.2vw;
    cursor: pointer;
    width: 8vw
}

.calendarElement {
    position: relative;
    box-shadow: 0px 0px 5px gray;
}

.rdrDateDisplayWrapper {
    width: 20vw !important;
}

.rdrMonthAndYearWrapper {
    width: 20vw !important;
}

.rdrMonthName {
    width: 20vw !important;
}

.rdrDays {
    width: 20vw;
    background-color: rgb(204, 204, 249);
    height: 15vw;
}

.rdrWeekDays {
    width: 20vw !important;
}

.rdrCalendarWrapper {
    width: 22vw !important;
}

.rdrMonthsHorizontal {
    width: 22vw !important;
}

.rdrStaticRangeLabel {
    color: black !important;
}

.rdrDay {
    height: 0.5vw !important;
}

.rdrStaticRangeLabel {
    padding: 0.1vw 0.5vw 0.5vw 1vw !important;
}

.rdrCalendarWrapper {
    background-color: #FAF9F9 !important;
}

.date_piker_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.eMed_DeletedPopup_Border {
    border-right: 1px solid #616161;
}

.eMed_DeletedPopup_bullet {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-right: 0.5vw;
    display: flex;
    align-self: center;
}

.eMed_DeletePopup_CrossIcon {
    cursor: pointer;
    width: 0.8vw;
    height: 0.8vw;
}

.eMed_Edit_btn :hover {
    transform: scale(1.2);
}
.eMed_Click_card_hover :hover {
    transform: scale(1.03);
}

.eMed_Clickable_card {
    width: 13vw;
    height: 8vw;
    display: flex;
    flex-Direction: column;
    align-items: center;
    justify-Content: center;
}

.eMed_ApoinmtCards_div {
    width: 6.65dvw;
    height: 5dvw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eMed_ApoinmtCards_count {
    padding-left: 0.3dvw;
    display: flex;
    align-items: flex-end;
    font-size: 1.4dvw;
}

.eMed_ApoinmtCards_label {
    height: 35%;
    display: "flex";
    align-items: flex-start;
    font-size: 0.8dvw;
    text-transform: capitalize;
}

.eMed_ApoinmtCards_color {
    height: 5%;
    width: 100%;
}

.eMed_Clickable_icon {
    height: 2vw;
    width: 2vw;
}

.eMed_ApoinmtCards_icon {
    height: 1.5vw;
    width: 1.5vw;
}

.eMed_ApoinmtCards_Content {
    display: flex;
    flex-direction: row;
    height: 60%;
    align-items: center;
}

.eMed_Clickable_label {
    text-transform: capitalize;
    font-size: 0.9vw;
}

.eMed_refdoc_grid {
    display: flex;
    justify-content: end;
    height: 7vh;
}

.eMed_Com_Patient_Details {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Com_Patient_Avatar {
    display: flex;
    align-items: center;
    margin-right: 0.5vw;
}

.eMed_Com_Patient_AvaIcon {
    width: 3vw;
    height: 3vw;
    border: 2px solid;
    border-radius: 100px;
}

#eMed_Com_Patient_Name {
    font-size: 0.8vw;
    font-weight: bold;
}

#eMed_Com_Patient_UhidText {
    font-size: 0.8vw;
}
#eMed_Com_Patient_admissionTxt{
    font-size: 0.7vw;
    font-weight: 600;
}
.eMed_DialogBox_Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5vw;
}

.eMed_DialogBox_Close {
    display: flex;
    justify-content: flex-end;
    padding: 0.5vw;
    padding-top: 0.3vw;
}

#eMed_DialogBox_Btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#eMed_Dia_Btn {
    text-transform: capitalize;
}

#emedhub_popup_content{
    max-height: 90vh;
    margin: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#emedhub_popup_container{
    width: 100%;
    padding: 1vw;
}

#popup_Patient_details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Popup_Text_Area{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    height: 4vw;
}

.popup_Appoint_details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1vw;
    margin-bottom: 0.5vw;
}

.eMed_Patient_Card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.3vw;
    margin-top: 0.5vw;
    padding-left: 0.5vw;
    margin-right: 0.5vw;
    overflow-wrap: break-word;
}

.eMed_patient_image {
    width: 2.8vw;
    height: 2.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDFFFE;
    border-radius: 0.3vw;
}

.eMed_patient_txt {
    margin-left: 0.8vw;
}

#eMed_ipsrvConfig_accTbl{
    width: 100%;
    height:47dvh;
}

.eMed_ipPackage_Div{
    height: 60vh;
}

.eMed_ipPackage_subDiv{
    padding: 1vw;
    margin-right: 0.5vw;
}

#eMed_ipPackage_rightTbl {
    height: 38vh;
    margin: -1.8vw 0.5vw 0.5vw 0.5vw;
}

#eMed_ipPackage_rightBtnCon {
    height: 9.8vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_ipPackage_txt1{
    font-size: 0.6vw;
    font-weight: 600;
}
#eMed_ipPackage_txt2{
    font-size: 0.8vw;
    font-weight: 600;
}

.eMed_PatientCard_AvaIcon {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_ImgPreviewPop_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_ImgPreviewPop_img{
    min-width: 55vw;
    min-height: 30vw;
}

.eMed_ImgPreviewPop_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_ImgPreviewPop_Img_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31vw;
    width: 60vw;
    overflow-y: scroll;
}
.eMed_Pateint_Detail_LongData {
    flex-direction: row;
    display: flex;
    width: 19vw;
    justify-content: space-between;
}
.eMed_Pateint_Detail_LenthData {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}
.eMed_cnf_Box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_cnf_BtnGr {
    margin: 1vw;
    display: flex;
    align-items: center;
}

.eMed_Header_Search_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_App_Card{
    transition: transform 0.5s;
}

.eMed_App_Card:hover{
    transform: scale(1.1);
}

.eMed_DelCnf_msg{
    display: flex;
    flex-direction: row; 
    margin-top: 1vw;
}

.eMed_Delcnf_txt{
    font-size: 0.6vw;
    color: #616161;
    margin-right: 0.5vw;
}

.eMed_Bill_Success_Preview_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 18vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.eMed_Bill_Success_animation{
    height: 8vw;
    width: 8vw;
}

.PopupDetail{
    color : #616161;
    font-size: 0.95vw;
    width: 20vw;
    display: flex;
    flex-direction: row;
    
}

.PopupSubDetail{
    color: black;
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 0.35vw;
    margin-top: 0.05vw;
}

.eMed_branch_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.3vw;
    margin-top: 0.5vw;
    padding-left: 0.5vw;
    margin-right: 0.5vw;
    overflow-wrap: break-word;
}
.eMed_voice_to_Text{
    height: 75px;
    width: 75px;
    background: linear-gradient(
        #82fbfb,
        #b8d2f7
    );
    position:relative;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    border-radius: 50%;
    display:grid;
    place-items: center;
    top: -40px;
}
.eMed_voice_to_Text::before,
.eMed_voice_to_Text::after{
        content: "";
        position: absolute;
        height: 80%;
        width:80%;
        background-color: #82fbd7;
        border-radius:50%;
        z-index: -1;
        opacity:0.7;
         
        
}
.eMed_voice_to_Text::before{
    animation: eMed_voice_to_TextAnimate 2s ease-out infinite;
}
.eMed_voice_to_Text::after{
    animation : eMed_voice_to_TextAnimate 4s  ease-out infinite;
}
@keyframes eMed_voice_to_TextAnimate{
    100%{
        transform: scale(2.5);
        opacity: 0;
    }
}
 
.errorpage{
  text-align: center;
  padding: 70px;
  font-size: 20px;
   
}
.soonpage{
    font-size: 20px;
    text-align: center;
    padding: 70px;
}
.Lab_homeConatiner {
    background-color: #F5F8F7;
    height: 81vh;
    width: 98%;
    overflow: scroll;

}

.Lab_report_hightlight {
    background-color: #FFF4F3
}

/* .lab_home_main_header {
    height: 80vh;
    width: 98%;
} */
.labhome_table_container {
    flex-direction: row;
    display: flex;
    flex: 1 1;
    margin-top: 0vw;
    height: 28vh;

}

/* .lab_home_main_header {
    margin-top: 1vw;
} */

.Lab_subcontainer {
    margin: 1vw
}

.Lab_home_subHeader {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* justify-content: flex-end; */
}

.Lab_home_emed_homeheader {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -1.5vh;
}

.Lab_home_emed_mainContainer {
    display: flex;
    justify-content: flex-end;
}


.Lab_home_subContainers {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.Lab_home_subContainer2 {
    flex-direction: row;
    display: flex;
    margin-top: 0.5vw;
    justify-content: space-between;
    height: 7vh;
}

.Lab_home_headerStyle {
    font-size: 1vw;
}

#Lab_home_textInput {
    margin-bottom: 0;
}

.Lab_home_dateOfBirth {
    height: 2.5vh;
    width: 10vw;
    margin-right: 0.5vw;
}

.Lab_SavedBill_date {
    height: 2.5vh;
    width: 11vw;
    margin-right: 1vw;
}

.PercentageSymbol {

    font-weight: 300;
    font-size: 0.5vw;
    display: flex;
    justify-content: center;
}

.deteofBirthContainer {
    display: flex;
    flex-direction: row;
}

.Lab_home_invoice_date {
    height: 2vh;
    width: 7.5vw;
    margin-left: 0.6vw;
}

.Lab_home_main {
    margin: -0.3vw;
}

.Lab_Home_Add {
    flex: 0.18 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 52vh;
    margin-top: 1vw;
}

.Lab_Home_Table {
    flex: 0.82 1;
    background-color: white;
}

.Lab_Home_Table_Header {
    background-color: #FAF9F9;
    height: 1.4vh;
    width: 100%;
}

.Lab_Home_Table_body {
    background-color: white;
    display: flex;
    flex: 1 1;
    overflow: scroll;
    width: 100%;
}

.Lab_Status_Table_body {
    background-color: #FFFFFF;
    display: flex;
    flex: 1 1;
    /* overflow: scroll; */
    width: 100%;

}

.Lab_Home_Table_Container {
    border-radius: 0.3vw;
    height: 28vh;

}

.Lab_Home_Table_Container_status2 {
    border-radius: 0.3vw;
    height: 51vh;
    display: flex;
    flex: 0.45 1;
    margin-left: 1vw;
    background-color: white;
    flex-direction: column;
    border-width: 1 px;
    border-color: lightgray;


}

.Lab_Home_Table_Container_status {
    border-radius: 0.3vw;
    height: 39vh;
    display: flex;
    flex: 0.6 1;
    background-color: #F5F8F7;
}

.Lab_home_Radio {
    display: flex;
    flex-direction: row;
}

.Lab_home_Radio_Main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-bottom: 0.5vw;
}

.Lab_Cancel_Bill_Radio {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    border: 1px solid lightgray;
    height: 20vh;
    width: 56.5vw;
    justify-content: center;
    align-items: center;
    border-radius: 0.3vw;
}

.Lab_Cancel_Popup {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vw;
    justify-content: space-between;
    width: 55vw;

}

.Lab_home_Radio_Text {
    font-weight: 500;
    font-size: 1vw;
    flex: 0.18 1;
}

.Lab_Home_Dialogboxwidth {
    padding: 15px;
    width: 30vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Lab_Status_Radio_Text {
    font-weight: 500;
    font-size: 1vw;
    flex: 0.18 1;
    margin-left: 4vw;
}

.Lab_Home_Radio_Button {
    flex: 0.82 1;
    font-size: small;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.Lab_Home_Radio_Button1 {
    flex: 0.82 1;
    font-size: small;
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-left: 0.8vw;
}

#lab_home_table_Head {
    background-color: #FAF9F9;
    font-weight: 600;
    color: #616161;
}

.Lab_Home_Payment_container {
    border-width: 1;
    border-color: #FFFFFF;
    border-radius: 0.3vw;
    background-color: white;
    flex: 0.6 1;
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
}

.Lab_Home_Radio_Split {
    display: flex;
    flex: 1 1;
    margin: 0.5vw;
}

.Lab_Radio_First_Container {
    flex: 0.18 1;
}

.Lab_home_Second_Container {
    flex: 0.22 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Lab_home_amount_view {
    display: flex;
    justify-content: end;
}

.Lab_Home_Payment_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
}

.Lab_Home_Text {
    display: flex;
    align-items: center;
}

.Lab_Home_payment_text {
    flex-direction: row;
    display: flex;
    justify-content: end;
    flex: 1 1;
}

.Lab_home_discount_per {
    flex: 1 1;
    display: flex;
    padding: 0vw;
    justify-content: space-between;

}

.discountTextField {
    display: flex;
    flex: 0.6 1;
    flex-direction: row;
    justify-content: space-between;
    /* margin-left: -1vw; */
}

.Lab_home_buttom_view {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8vw;
    margin-left: 0.5vw;
}

.Lab_home_pop_buttom_view {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.8vw;
    margin-left: 0.5vw;
    width: 15vw;
}

.Lab_Home_Saved_Bill {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    margin-top: 0.8vw;
    background-color: #F5F8F7;
    border-radius: 0.2vw;
    padding: 0.4vw;
    justify-content: center;
    align-items: center;
}

.savedBillText {
    font-weight: 400;
    font-size: 1vw;
}

.savedTextLight {
    color: lightgray;
    font-size: 0.6vw;
    margin-left: 1vw;
}

.Lab_saved_bill_sub_container {
    display: flex;
    flex-direction: row;

}

.Lab_Status_Container {
    background-color: #F5F8F7;
    height: 80vh;

}

.Lab_Status_Table_Containers {
    border-radius: 0.3vw;
    height: 65vh;
    z-index: 0;
    margin-top: 0.5vw;
}

.emed_DialogboxOrder {
    margin-top: 70px;
}

.Lab_Saved_Bill_Main {
    width: 25.5vw;
    height: 30vh;
    overflow: scroll;
}

#eMed_dropDown_Lab_home {
    width: 9.5vw
}

#eMed_dropDown_Lab_home_Title {
    width: 7vw;
    border-radius: 0.5vw;

}

#eMed_dropDown_Lab_status {
    width: 8vw;
    height: 4vh;
    margin-right: 2vw;
}

.sample_collect_popup {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
    background-color: #F5F8F7;
    height: 6vh;
    align-items: center;
    padding: 0.5vw
}

.sample_specimen_collect_popup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F5F8F7;
    height: 3vh;
    align-items: center;
    padding: 1.2vw
}

.specimen_collect_tab {
    padding: 10px !important;
}

.MuiTabs-indicator {
    background-color: var(--themColor) !important;
}

.Lab_status_Speciman_container {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
}

.Lab_status_table_container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    margin: 1vw;
    justify-content: space-between;
    height: 60vh;

}

.Lab_status_speciman_bottom {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    height: 2vh;
}

.Lab_Status_Header1 {
    display: 'flex';
    flex: 0.6 1;
    margin: 0.2vw
}

.Lab_Status_split {
    display: 'flex';
    flex: 0.05 1;
    margin: 0.1vw
}

.Lab_Status_Value {
    display: flex;
    flex: 0.4 1;
    margin: 0.1vw
}

.TableButtonCel {
    margin-top: 0;

}

.Lab_status_Button {
    display: flex;
    flex-direction: row;
}

.Lab_Status_Close_Button {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: flex-end;
}

.lab_print {
    height: 4.5vh;
    width: 3vw;
    margin-top: 0vw;
    align-self: center;
    align-items: center;
}

.headerValue {
    color: #616161;
    font-size: 0.87vw;
    align-self: center;
    margin: 0.5vw
}

.headerStyle {
    font-size: 1vw;
    color: #202020;
}

.Lab_status_Consumable {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
}

.consumablesField {
    height: 4vh;
    margin-top: 0.5vw;
}

.Lab_Home_Table_Sub_Container {
    display: flex;
    flex-direction: row;
    flex: 0.6 1;
    border-width: 1px;
    border-color: lightgray;
    height: 23vh;
}

.consumable_style {
    display: flex;
    flex: 1 1;
}

.ConsumableTableContainer {
    min-height: 66vh;
}

.consumable_button {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin-top: 1vw;
}

.Lab_status_sample_bottom {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
    margin: 1vw;
}

.Lab_Home_No_record {
    height: 22vh;
}

.Lab_Saved_Bill_Button {
    flex: 0.1 1;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 4vh;
    margin-top: 0vw;
}

.Lab_saved_Bill_Text {
    color: white;
}

.SavedBillFirst {
    display: flex;
    flex: 0.65 1;
    flex-direction: column;
}

.SavedBillSecond {
    display: flex;
    flex: 0.25 1;
    flex-direction: column;
}

.Saved_bill_date {
    z-index: 100;
    position: absolute;
    background-color: white;
    margin: 2vw;

}

.datePicker {
    height: 40vh;
    width: 4vw;
}

.Lab_Status_Default_Consumables {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
}

.Lab_Status_Tables_Main {
    display: flex;
    flex: 0.55 1;
    flex-direction: column;
    height: 70vh;
}

.Lab_Status_Tables_Con {
    height: 34vh;
}

.Lab_Previous_Report_Con {
    height: 45vh;
    overflow: scroll;
    margin-top: 1vw;
}

.Lab_Status_Tables_Con1 {
    height: 30vh;
    margin-top: 1vw;
    border-width: 1px;
    width: 100%;
    border-color: #616161;
}

.Lab_Home_DeleteIcon {
    width: 1.3vw;
    height: 1.3vw;
}

.Lab_Home_Delete {
    width: 1vw;
    height: 0.9vw;
}

.lab_status_sub_header {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    /* justify-content: flex-end; */
    margin-right: 1vw;
}

.lab_status_printBtn {
    border-radius: 0.2vw;
    background-color: #ffff;
    border: 1px solid #66686A;
    margin-top: 1vw;
    margin-right: 1vw;
    justify-content: center;
    height: 5vh;
}

.Lab_Status_Subcontainer_Img {
    width: 3vw;
    height: 3vw;
}

.Lab_status_multi_option_button {
    margin-left: 1vw;
}

.emedhub_result_container {
    display: flex;
    justify-content: center;
}


.emedhub_result_card {
    height: 79vh;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    margin: auto;
    margin-top: 0.7vw;
    overflow: hidden
}

.emedhub_result_top_header {
    width: 93vw;
    height: 9vw;
    margin: auto;
}

.emedhub_back_arrow {
    width: 2vw;
    height: 2vw;
    cursor: pointer;
    margin: 0.5vw;
}

.emedhub_result_header {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -3.1vw;
    left: 3.5vw;
}

.emedhub_result_header_title {
    font-size: 1vw;
}

.emedhub_result_header_text {
    font-size: 0.6vw;
}

.emedhub_Print_Button {
    height: 2.5vw;
    width: 2.5vw;
    cursor: pointer;
}

.emedhub_Print_imgBtn {
    position: relative;
    left: 80vw;
    top: -3vw;
}

.emedhub_result_table {
    border: "1px solid gray";
    height: 61vh;
    width: 90vw;
    margin: auto;
    position: relative;
    top: -4vw;
    box-shadow: 0px 0px 2px gray;
    overflow-y: scroll;
}

.emedhub_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 44vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_outsource {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 24vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_outsource_list {
    position: absolute;
    top: 20vw;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 24vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_title {
    display: flex;
    flex-direction: row;
    margin: 1vw 0vw 0vw 0.5vw;
}

.emedhub_popup_title_left {
    flex: 0.5 1;
    padding-left: 0.5vw;
    font-weight: 600;
    font-size: 1.5vw;
}

.emedhub_popup_title_right {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
}

.emedhub_card_close_button {
    flex: 0.5 1;
    text-align: end;
    margin: 0.5vw 1vw 0vw 0vw;
}

.emedhub_popup_title_2 {
    display: flex;
    flex-direction: row;
    margin: 0.5vw 0vw 0vw 2vw;
}

.emedhub_popup_title_Header {
    flex: 0.5 1;
}

.emedhub_popup_title_dropdown {
    width: 13vw;
    position: relative;
    left: 11vw;
}

.emedhub_card {
    width: 38vw;
    height: 33.5vw;
    margin: auto;
    margin-top: 0.5vw;
    padding: 1vw;
    overflow: scroll;
    border: 2px solid lightgray;
    border-radius: 5px;
    box-shadow: 0px 0px 2px lightgray;
}

.emedhub_popup_Impression_card {
    margin-bottom: 1vw;
    box-shadow: 0px 0px 2px gray;
}

.emedhub_card_title {
    height: 2.3vw;
    max-width: 100vw;
    padding: 0.4vw 0vw 0.2vw 0.5vw;
    background-color: #F5F8F7;
}

.emedhub_card_input {
    display: flex;
    flex-direction: column;
    width: 34vw;
    margin: 1vw auto auto auto;
}

.emedhub_popup_Paragraph_card {
    box-shadow: 0px 0px 2px gray;
    margin-bottom: 1vw;
}

.emedhub_popup_Table_card {
    box-shadow: 0px 0px 2px gray;
}

.emedhub_table_input {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    width: 35vw;
    margin: auto;
}

.emedhub_table_input_card {
    width: 34vw;
    margin-top: 1vw;
    display: flex;
}

.emedhub_table_input_row {
    flex: 0.5 1;
}

.emedhub_table_input_column {
    flex: 0.5 1
}

.emedhub_popup_button {
    display: flex;
    justify-content: flex-end;
    margin: 1vw 0.75vw 0vw 0vw;
    height: 2vw
}

.emedhub_table_style {
    width: 34vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.emedhub_table_col {
    position: relative;
    left: 5vw;
    margin-right: 1vw;
}

.emedhub_table_row {
    margin-left: 1vw;
    margin-bottom: 1vw;
}

.table_row_header {
    display: flex;
    margin: 0.5vw;
}

.table_row {
    flex: 0.5 1;
    text-align: center;
    height: 1vw;
    position: relative;
    top: 0.5vw;
}


.emedhub_method_four_card {
    height: 33.5vw;
    width: 38.5vw;
    margin: auto;
    margin-top: 1vw;
    border-radius: 10px;
    box-shadow: 0px 0px 3px gray;

}

.emedhub_method_four_card_item {
    margin-top: 0.5vw;
    display: flex;
    flex-direction: column;
}

.emedhub_culture_screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: "0.75vw";
    margin-left: 1.25vw;
}

.method_four {
    margin-top: 1.5vw;
    height: 2vw;
}

.emedhub_card_method_4_header {
    width: 20vw;
    margin: 1.5vw 0.5vw 1vw 0.5vw
}

.emedhub_card_method_4_table {
    width: 75vw;
    margin-left: 1.25vw;
}

.emedhub_main_button {
    position: relative;
    top: -3.3vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 1.9vw;
}

.emedhub_result_margin {
    margin: 0vw 0vw 0vw 2vw;
}

.emedhub_name_margin {
    margin: 0.5vw 0vw 0.8vw 8.5vw;
}

.emedhub_image_preview {
    border: 1px solid gray;
    height: 35vw;
    width: 38vw;
    margin: 1vw auto;
}

.emedhub_print_popup {
    border: 1px solid lightgray;
    width: 39vw;
    border-radius: 10px;
    margin: auto;
    /* height: 34vw; */
}

.emedhub_print_top_header {
    width: 39vw;
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
}

.emedhub_image_size {
    width: 38vw;
    height: 20vw;
    position: relative;
    top: 1.5vw;
    margin: auto
}

.emedhub_image_upload_button {
    position: relative;
    flex: 0.5 1;
    display: flex;
    padding-right: 0.5vw;
    justify-content: end;
    align-items: center;
}

.emedhub_image_resolution {
    width: 38vw;
    height: 18vw;
    object-fit: contain;
}

.emedhub_image_name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emedhub_multiple_image_size {
    margin: auto 0.3vw;
    position: relative;
    top: 2vw;
}
.emedhub_multiple_image_size_outlist {
    margin: auto 0.3vw;
    position: relative;
}
.emedhub_lab_Result {
    display: flex;
    /* flex: 1;
    flex-direction: row; */
}

.emedhub_icon_style {
    position: relative;
    top: -8.3vw;
    left: 6.3vw;
    width: 1.3vw;
    height: 1.3vw;
}
.emedhub_icon_style_outlist {
    position: relative;
    top: -6.3vw;
    width: 1.3vw;
    height: 1.3vw;
}

.emedhub_multiple_image_resolution {
    width: 7vw;
    height: 7vw;
    margin-right: 0.5vw;
    cursor: pointer;
}

.emedhub_no_data {
    position: relative;
    top: 12vw;
    left: 40vw;
    font-size: 1.2vw;
    font-weight: 600;
    color: #616161;
}

#eMedhub_Table_Pagination {
    position: relative;
    top: 19.5vw;
    border-top: 1px solid lightgray;
}

.Lab_cancel_bill {
    height: 35vh;
    margin: 1vw;
}

.CancelBillComment {
    background-color: #F5F8F7;
    width: 50vw;
}

.Lab_Home_Receive_Bill {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: end;
}

.noRecordsStyle {
    display: flex;
    flex: 1 1;
    justify-content: center;
    margin-top: 5vw;
}

.Saved_Bill_Date {
    border: 1px solid lightgray;
    width: 10vw;
}

.emed_Dialogboxwidth_Lab_status {
    height: 73vh;
}

.Lab_Home_table_paper {
    background-color: white;
}

.MainLabHome {
    position: absolute;
    width: 94.7%;
    height: 72vh;
}

.searchCancel {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
}

.MainSubContainer {
    width: 100%;
    height: 88vh;
    margin-top: -4vw;
    margin-right: -4vw;
    background-color: rgb(0, 0, 0, 0.5);
}

.PreviousReportPopUp {
    height: 60vh;
    width: 30vw;
    padding: 1vw;
}

.Lab_Extra_Speciman {
    display: flex;
    flex-direction: row;
    flex: 1 1;
}

#eMed_Btn_Text {
    text-transform: capitalize;
}

.consumable_container {
    margin-left: 0.5vw;
}

.emed_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Saved_Bill_Icon {
    width: 1.5vw;
    height: 1.5vw;
    flex: 0.1 1;
}

.range_style {
    padding: 0.5vw;
}

.emedhub_transmit_close {
    height: 1vw;
    width: 1vw;
    cursor: pointer;
}

.test_name_style {
    display: flex;
    flex: 0.5 1;
    align-items: center;
    padding-left: 1vw;
}

.image_upload_button {
    width: 10vw;
    position: relative;
    top: -1vw;
}

.image_top_header {
    display: flex;
}

.table_row_input {
    flex: 0.5 1;
}

#table_pagination {
    position: static;
}

.lab_rajesh {
    margin-left: 12vw;
}

.labImageStyle {
    width: 2.5vw;
    height: 4.5vh;
}

.labCancelStyle {
    width: 2.5vw;
    height: 2.5vh;
}

.labDataGrid {
    height: 67vh;
    width: 92vw;
    margin: auto;
    background-color: white;
    overflow: auto;
    margin-top: 1vw;
}

.emedhub_lab_cancel_bill {
    height: 35vh;
    width: 40vw;
    margin: auto;
    background-color: white;
    overflow: scroll;
    margin-top: 1vw;
}

.eMed_sts_cls_img {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
}

.eMed_sts_card {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_sts_samp_collect {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
    margin-right: 1vw;
}

.eMed_tst_date_pick {
    position: relative;
    top: 0.9vw;
    /* right: 3vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.5vw;
    width: 90vw;
}

.eMed_tst_action {
    cursor: pointer;
    margin-right: 1vw;
}

.eMed_tst_icon {
    margin-right: 2vw;
}

.eMed_sts_spc {
    margin-right: 1vw;
    cursor: pointer;
}

.eMed_sts_date {
    position: relative;
    top: 0.9vw;
    /* right: 3vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.5vw;
    width: 90vw;
}


.eMed_create_switch {
    font-size: 0.3vw !important;
}

.eMed_status_pending {
    width: 9vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B82121;
    background-color: #FFE9E9;
    border-radius: 5px;
}

.eMed_Lab_Sts_Tbl_Two {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.eMed_Lab_techFlt {
    height: 12vh;
    display: flex;
    align-items: center;
    padding: 0.5vw;
}

.eMed_Lab_techTxt {
    margin: 0.5vw;
}

.eMed_Lab_techDate {
    position: relative;
    top: 0.55vw;
    width: 12vw;
}

#eMed_Lab_techFont {
    font-size: 0.9vw;
}

.eMed_Lab_Status_specimenNot {
    border: 1px solid #B8170D;
    border-radius: 3px;
    color: #B8170D;
    background: #AF4F4F30;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_specimen {
    border: 1px solid #DE7D24;
    border-radius: 3px;
    color: #DE7D24;
    background: #AF814F30;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_transfer {
    border: 1px solid #00a8f5;
    border-radius: 3px;
    color: #00a8f5;
    background: #def9ff;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_Received {
    border: 1px solid #1E972A;
    border-radius: 3px;
    color: #1E972A;
    background: #4FAF6130;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_ResultUpload {
    border: 1px solid #1E972A;
    border-radius: 3px;
    color: #1E972A;
    background: #4FAF6130;
    display: flex;
    text-wrap: auto;
    align-items: center;
}
#emedhub_inventory_Container {
    height: 90vh;
}

#emedhub_registry_mainContainer {
    width: 100%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.5);
}

#emedhub_registry_mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: 49vh;
    background-color: white;
    border-radius: 10px;
}

#emedhub_registry_header {
    display: flex;
    justify-content: space-between;
    height: 3vh;
    padding: 0.9vw;
    background-color: #FAF9F9;
    outline: none;
}

.emedhub_registry_subDiv {
    display: flex;
    justify-content: space-evenly;
    margin: 1.2vw;
}

#emedhub_registry_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vw;
    height: 4vw;
    padding: 1.5vw;
    background-color: #FAF9F9;
}

.emedhub_registry_footer_subdiv {
    display: flex;
    align-items: center;
    margin: -1vw;
}

.emedhub_registry_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.emedhub_registry_footer_Text1 {
    font-size: 0.7vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}

.emedhub_registry_footer_Text2 {
    font-size: 0.7vw;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}

.active_tab_container {
    max-height: 100vw;
    max-width: 100vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: #F5F8F7;
}

.active_tab_header_card {
    padding: 1vw 1vw 1.2vw 0vw;
    display: flex;
    justify-content: end;
}

.active_tab_image {
    margin-right: 0.5vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
}

.active_tab_table_container {
    height: 65vh;
    width: 93.7vw;
    margin-top: 0.5vw;
    background-color: white;
}

.inactive_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 20vw;
    background-color: white;
    border-radius: 10px;

}

.inactive_popup_header {
    margin: 1vw;
    display: flex;
}

.inactive_popup_textfield {
    margin: 1vw;
    height: 9vw;
}

.inactive_popup_footer {
    display: flex;
    margin: 3vw 1vw 1vw 1vw;
}

.inactive_popup_left {
    display: flex;
    flex-direction: column;
    flex: 0.6 1;
}

.inactive_popup_right {
    display: flex;
    flex: 0.4 1;
    justify-content: end;
    height: 2.5vw;
}

.inactive_span {
    margin-left: 0.5vw;
    color: #616161;
}

.inactive_popup_text {
    color: rgba(0, 0, 0, 0.5);
}

.inactive_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: #616161;
    border-radius: 50%;
    position: relative;
    top: 0.8vw;
}

.inactive_popup_header_text {
    flex: 0.7 1;
}

.inactive_popup_close_card {
    flex: 0.3 1;
    text-align: end;
}

.inactive_popup_close {
    width: 1vw;
    height: 1vw;
    margin-right: 0.5vw;
    cursor: pointer;
}

.active_view_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65.5vw;
    height: 32vw;
    background-color: white;
    border-radius: 10px;
    overflow-y: scroll;
    padding-bottom: 1vw;
}

.active_view_header {
    display: flex;
    margin: 1vw;
    padding: 0.5vw;
    background-color: #F5F8F7;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.active_view_header_text {
    flex: 0.7 1;
    font-size: 1.1vw;
    font-weight: 600;
    position: sticky;
}

.active_view_close {
    flex: 0.3 1;
    text-align: end;
    align-items: center;
    position: relative;
    top: 0.25vw;
}

.active_view_middle_card {
    margin: 1vw;
    height: 23vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex: 1 1;
    flex-direction: column;
}

.active_view_footer_table_card {
    margin: 1vw;
    height: 15vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    overflow: scroll;
}

.active_view_footer {
    display: flex;
}

.active_view_left {
    flex: 0.7 1;
    margin-left: 1vw;
}

.active_view_text {
    color: rgba(0, 0, 0, 0.5);
}

.active_view_right {
    flex: 0.3 1;
    text-align: end;
    margin-right: 1vw;
}

.active_view_textfield {
    padding: 1vw;
}

.mov_container_card {
    background-color: #F5F8F7;
    height: 75vh;
}

.mov_header_card {
    height: 4.5vw;
    padding: 1vw;
    display: flex;
    justify-content: end;

}

.mov_image_card {
    margin-right: 0.5vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
    height: 2.2vw;
    cursor: pointer;
}

.mov_table_card {
    margin-top: 0.5vw;
    height: 65vh;
    background-color: #FFFFFF;
}

.super-app-theme--header {
    background-color: #616161;
}

.stock_edit_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65vw;
    height: 40.5vw;
    background-color: white;
    border-radius: 10px;
}

.stock_edit_header {
    display: flex;
    margin: 1vw;
    padding: 0.5vw;
    background-color: #F5F8F7;
    box-shadow: 0px 0px 2px gray;
    border-radius: 5px;
}

.stock_edit_header_text {
    flex: 0.7 1;
    font-size: 1.15vw;

}

.stock_table_container{
    height: 65vh;
     margin-top: 1vw; 
     background-color:white;
}

.stock_card{
    background-color: #F5F8F7;
    height: 75vh;
}

.stock_print_design{
    height: 2.3vw;
    width: 2.3vw;
    cursor: pointer;
}

.stock_edit_close {
    flex: 0.3 1;
    text-align: end;
    align-items: center;
    position: relative;
    top: 0.25vw;
    padding-right: 0.2vw;
}

.stock_edit_img_close {
    height: 1vw;
    width: 1vw;
    cursor: pointer;
}

.stock_edit_middle_card {
    border-radius: 5px;
    margin: 1vw;
    height: 17vw;
    box-shadow: 0px 0px 2px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
}

.stock_edit_radio_card {
    margin: 1vw;
    height: 5vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    padding: 0.5vw;
}

.stock_edit_input_card {
    margin: 1vw;
    height: 7vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
}

.font {
    font-size: 0.7vw;
}

.span_font {
    font-size: 0.9vw;
}


.checkbox{
    margin-right: 1vw !important;
    width: 8vw !important;
    height: 2.3vw;
    text-transform: capitalize !important;
}

.checkbox span {
    position: relative;
    top: 0.4vw;
    color: white;
    cursor: pointer;
}

.add_product_card{
    display: flex;
    margin: auto;
    margin-left: 2vw;
    width: 82vw;
}

.add_product_flex_one{
    flex: 0.5 1;
}

.add_product_flex_two{
    flex: 0.5 1;
    display: flex;
    justify-content: end;
}

.add_product_top_header{
    display: flex;
    height: 5vh;
    background-color: #F5F8F7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.add_product_top_header_right{
    flex: 0.5 1;
    align-self: center;
    padding-left: 1vw;
}

.add_product_top_header_left{
    flex: 0.5 1;
    display: flex;
    justify-content: end;
    padding-right: 1vw;
    align-self: center;
}

.mandatory_card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    height: 12vw;
    background-color: white;
    border-radius: 10px;
}

.mandatory_card_header{
    background-color: #F5F8F7;
    height: 5vh;
    display: flex;
    justify-content: center;
    font-weight: 600;
    align-items: center;
    margin: 1vw;
}

.mandatory_middle_card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mandatory_middle_card_text{
    font-size: 1vw;
    font-weight: 700;
    color: green;
}

.mandatory_card_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vw;
}

.add_button{
    position: absolute;
    top: 17vw;
    left: 60vw;
}

.minus_button{
    position: absolute;
    top: 17vw;
    left: 48vw;
}

.eMed_upload{
    height: 2.2vw;
    cursor: pointer;
}

.eMed_pro_name{
    margin-left: 0.5vw;
}

.eMed_pro_name > span{
    color: red;
}

.eMed_pause{
    margin-right: 1vw;
}

.eMed_date_picker{
    position: relative;
    top: -1vw;
    width: 0vw !important;
    right: 10vw;

}
#emed_CalenderHead {
    display: flex;
    flex-direction: row;
    width: 100px;
}

#emed_CalenderClose_Icon {
    margin-left: "40%";
    margin-right: '15px';
}

#emed_Calender_SearchIcon {
    margin-left: 80%;
    margin-top: -12%;
}

#emed_Calender_TodayButton {
    margin-top: -5%;
    margin-left: 65%
}

.eMed_Custum_pop_buttons{
    margin-top: 2vw;
}

.emed_Calender_Pop{
    height: 30vw;
    overflow: hidden;
}
#emed_CalenderHead {
    display: flex;
    flex-direction: row;
    width: 100px;
}

#emed_CalenderClose_Icon {
    margin-left: "40%";
    margin-right: '15px';
}

#emed_Calender_SearchIcon {
    margin-left: 80%;
    margin-top: -12%;
}

#emed_Calender_TodayButton {
    margin-top: -5%;
    margin-left: 65%
}
.emedhub_Purchasecontainer{
    width: 100%;
    height: 80vh;
}
.emedhub_purchase_contentContainer{
    width: 100%;
    /* margin: 1vw 0; */
}
.emedhub__purchase_page{
    width: 100%;
    margin-top: 0.625vw;
}
.emedhub_vendor_btnDiv{
    padding: 0.2vw;
    border-right: 2px solid #66686A;
}
.emedhub_vendor_printBtn{
    width:2.3vw;
    height:2vw;
    border-radius:0.3vw;
    background-color:#ffff;
    border:1px solid #66686A;
    padding:0.5vw;
}
#emedhub_ventor_Table{
    height: 68vh;
    margin: 1vw;
}
#emedhub_deliveryChallen_Table{
    height: 70vh;
    margin-left: 1vw;
    margin-right: 1vw;
}
#emedhub_vendorDetails_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    max-height: 50vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
#emedhub_vendorDetails_header{
    display: flex;
    justify-content: space-between;
    height: 3vw;
    padding: 0.9vw;
    background-color: #FAF9F9;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
}
.emedhub_vendor_textBox{
    width:17.85vw;
}
.emedhub_vendor_dropDown{
    width: 17.85vw;
    height: 2.7vw;
    font-size: 1vw; 
    color: #66686A;
    padding-left: 0.5vw;
    border-color: #CDD1D5;
    border-radius: 0.2vw;
    outline: none;
}
.emedhub_vendor_subDiv{
    display: flex;
    justify-content: space-evenly;
    margin: 1.8vw;
}
#emedhub_vendorDetails_footer{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top: 3vw;
    height: 4vw;
    padding: 1.5vw;
    background-color: #FAF9F9;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}
.emedhub_vendorDetails_footer_subdiv{
    display:flex;
    align-items:center;
    margin:-1vw;
}
.emedhub_vendorDetails_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}
.emedhub_vendorDetails_footer_Text1{
    font-size: 0.7vw;
    font-weight: 600; 
    color: rgba(0, 0, 0, 0.5); 
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}
.emedhub_vendorDetails_footer_Text2{
    font-size: 0.7vw;
    font-weight: 400; 
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0.3vw;
}
.emedhub_vendor_imgBtn{
    width: 2vw;
    height:2vw;
    padding: 0.3vw;
    display:flex;
    align-items: center;
    background-color:#04B7B1;
    margin: 0.3vw;
    border-radius: 0.3vw;
    cursor: pointer;
}
.emedhub_vendor_img1{
    width: 2.2vw;
    height: 2.3vw;
    border-radius: 0.3vw;
}
.emedhub_vendor_img2{
    width: 2vw;
    height: 2vw;
    border-radius: 0.3vw;
}
#emedhub_vendorDetails_error{
    z-index: 2;
    font-size: 0.78vw;
    position: absolute;
    margin-top: 0.4vw;
    margin-left: 0.7vw;
    color: #ff0000;
}
#emedhub_directpo_mainDiv {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: space-evenly;
    background-color: #f5f8f7;
}

#emedhub_directpo_leftDiv {
    width: 84.6vw;
    margin: 1vw;
}

.emedhub_directPo_imgBtn {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0vw 1vw 0vw 0vw;
}
.emedhub_directPo_img{
    width: 2vw;
    height: 2vw;
    border-radius: 0.3vw;
}
#emedhub_directpo_rightDiv {
    width: 44.9vw;
    margin: 1vw;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}
#emedhub_directpo_rightSubDiv{
    height:70vh;
    overflow: scroll;
}
#emedhub_directPo_paper1 {
    max-height: 16.98vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 1vw;
    margin-bottom: 1.5vw;
}
.emedhub_directpo_subDiv{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1.5vw;
    margin: 1.5vw 2vw;
}
#emedhub_directpo_table{
    background-color: #FFFFFF;
    height:53.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}
.emedhub_directpoTable_Paper{
    height:53.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}
.emedhub_directpoTable_head{
    display: flex;
    flex: 1 1;
}
#emedhub_directpoTable_headTxt{
    background-color: #faf9f9;
    color: #616161;
    font-weight: 600;
    font-size: 0.9vw;
}
#emedhub_directpoTable_cell1{
    font-size: 0.9vw;
    font-weight: 600;
}
.emedhub_directpoTable_cell{
    color: #66686A;
}
.emedhub_directpo_RightHead {
    background-color: #faf9f9;
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.emedhub_directpo_RightHead_text {
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 1.5vw;
}
.emedhub_directpo_RightContentBox {
    background-color: #FFFFFF;
    padding: 1vw;
}
#emedhub_directpo_RightContentBox1{
    max-height: 53vh; 
}
#emedhub_directpo_RightContentBox2{
    max-height: 95vh;
}
.emedhub_directpo_RightContentSubDiv{
    display: flex;
    justify-content: space-between;
}
.emedhub_directpo_RightContent_TileBlock{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.emedhub_directpo_RightBtnCon {
    background-color: #faf9f9;
    height: 3vh;
    padding: "0.8vw";
}
.emedhub_confirmOrder_mainDiv{
    display:flex;
    flex-direction: column;
}
#emedhub_confirmOrder_subDiv1{
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-around;
    max-height: 16.98vh;
    padding:0.625vw;
}
#emedhub_confirmOrder_subDiv2{
    margin:1vw;
    height:35vh;
}
.emedhub_confirmOrder_Div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.emedhub_vendorMonthly_paper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-height: 16vw;
    padding: 0.625vw;
}

.emedhub_vendorMonthly_mainBox {
    width: 100%;
    height: 80vh;
    padding: 1vw;
    background-Color: #f5f8f7;
}

.emedhub_vendorMonthly_subdiv {
    padding: 0.625vw;
}

.emedhub_vendorMonthly_title {
    font-size: 0.7vw;
}

#emedhub_ventorMonthly_Table {
    height: 38vh;
    background-color: #FFFFFF;
}
.emedhub_wantedListTable{
    height: 63vh;
   border-radius: 0.3vw;
   overflow: auto;
}
.emedhub_confirmOrder_Table{
    height: 30vh;
    border-radius: 0.3vw;
}
.emedhub_subloc_stkTranfer_Table{
    height: 35vh;
    border-radius: 0.3vw;
    overflow-y: scroll;
}
#emedhub_vendorMonthly_popupBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    max-height: 60vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.emedhub_paymentdetail_subdiv{
    display:flex;
    flex:1 1
}
#emedhub_directpoRight_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    max-height: 30vw;
    padding: 3vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.emedhub_popupIcon_img{
    width: 1.3vw;
    height: 1.3vw;
    border-radius: 0.3vw;
}
.emedhub_popupIcon_img1{
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 0.3vw;
}

.eMed_dir_del{
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;
}

.eMed_pur_print{
    width: 2vw;
    height: 2vw;
}

#eMed_pro_date_picker{
    margin: 0.7vw;
}

.eMed_pur_epr{
    margin-bottom: 0.5vw;
    margin-left: 0.9vw;
}

.eMed_bat_font{
    font-weight: 700;
}

.eMed_pur_deliv_img{
    width: 4vw;
    height: 4vw;
    border-radius: 0.3vw;
}

.eMed_pur_ctry_txt{
    width: 41vw;
}

.eMed_pur_txt{
    width: 61vw;
}

.eMed_pur_back_color{
    background-color: #FFFFFF;
}

.eMed_pur_txt_field{
    margin: 1vw;
}

.eMed_pur_card{
    padding: 0.5vw;
}

.eMed_pur_txt_one{
    font-size: 0.95vw;
    font-weight: bold;
    padding: 0.3vw;
}

.eMed_pur_txt_two{
    font-size: 0.7vw;
    color: #66686A;
}

.eMed_pur_footer{
    margin-bottom: 0.5vw;
}

.eMed_pur_bill_txt{
    font-weight: bold;
    padding: 0.3vw;
}

.eMed_pur_pay_text{
    background-color: #FFFFFF;
    padding: 0.5vw;
}

#emedhub_vendorMonthly_popupBox_new{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-height: 65vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.eMed_Main_container {
    margin-top: 0.5vw;
    background-color: #F5F8F7;
}

.eMed_Top_Container {
    height: 13vh;
    display: flex;
    flex-direction: row;
}

.eMed_Table_Container {
    height: 65vh;
}

.eMed_Cards_Container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Action_Container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}

.eMed_action_img {
    height: 2vw;
    width: 2vw;
}

.eMed_action_img_small {
    height: 1vw;
    width: 1vw;
}

.eMed_Table_Wrapper {
    width: 92vw;
    height: 65vh;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Filter_Icon_div {
    width: 4vw;
    /* border-right: 2px slategray solid; */
    /* margin-right: 1vw; */
}

.eMed_Filter_Billing_Container {
    width: 30vw;
    margin-top: 5vw;
}

.eMed_Filter_Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
}

.eMed_Filter_TextFields {
    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.eMed_Filter_Header {
    height: 5vh;
    background-color: #FAF9F9;
    display: flex;
    align-items: center;
    margin-top: 0.5vw;
}

.eMed_CheckBox_Group {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1vw;
}

.eMed_Filter_Btn_Div {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1vw;
    margin-top: 1vw;
}

.eMed_Filter_Btns {
    text-transform: capitalize !important;
    margin-right: 1vw !important;
}

.eMed_Dropdown {
    width: 13.5vw;
    margin-top: 0.8vw !important;
}

.eMed_Filter_DateFilter {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1vw;
    padding-left: 1vw;
}

.eMed_CheckBoxDiv {
    width: 9vw;
}

.eMed_Bill_Filter_TextFeild_Big {
    width: 27vw !important;
    margin-top: 0.8vw !important;
}

.eMed_Bill_Filter_TextFeild_small {
    width: 13.5vw !important;
    margin-top: 0.8vw !important;
}

.eMed_CreditPoP_TitleDiv {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_CrdPop_InputDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
    height: 3.5vw;
}

.eMed_CreditBill_ErrorTxt {
    color: red;
    font-size: 0.65vw !important;
    margin-top: 0.3vw !important;
}

.eMed_CrdPop_AmtTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0.5vw;
    margin-bottom: 1vw;
}

.eMed_CrdPop_Check_Group {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5vw;
}

.eMed_CrdPop_CheckBoxDiv {
    width: 5vw;
}

.eMed_CrdPop_Amount_TextFields {
    width: 16vw;
    margin-top: 0.7vw !important;
    background-color: #E4F2F0;
    padding: 0 !important;
}

.eMed_CrdPop_Denomi_div {
    background-color: #E4F2F0;
    height: 12vw;
    border-radius: 0.3vw;
    margin-top: 0.5vw;
}

.eMed_CrdPop_Denomi_div_New {
    background-color: #E4F2F0;
    height: 15vw;
    border-radius: 0.3vw;
    padding: 0.5vw;
    width:26vw
}

.eMed_CrdPop_Denomi_invidual_div {
    margin-left: 1.2vw;
    margin-top: 0.8vw;
    width: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_CrdPop_Denomi_MainGrid_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 1.2vw;
}

.eMed_crdPop_amountTextField {
    background-color: white;
    height: 1.5vw;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
    width: 5vw;
    border-radius: 0.3vw !important;
}

.eMed_popup_amount_label {
    margin-left: 1vw !important;
    font-size: 1.3vw !important;
}

.eMed_countsAmount_Label {
    width: 2.5vw !important;
    text-align: right;
}

.eMed_BillPreview_Main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 40vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_BillPreview_Top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_BillPreview_Image {
    height: 33vw;
    border: 1px solid grey;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 0.26vw;
}

.eMed_BillPreview_BtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_Recpt_header_amt_card {
    min-width: 0vw !important;
    padding: 0vw !important;
    text-transform: capitalize !important;
}

.eMed_Summery_Cards_row {
    display: flex;
    flex-direction: row;
    margin-left: 1.5vw;
}

.eMed_Summery_Cards_wrapper {
    display: flex;
    flex-direction: row;
    width: 60vw;
    align-items: center;
    padding: 1.5vw;
}

.eMed_Cards_Receiptcon {
    max-width: 150vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
}

.eMed_Summary_Report_Loader_comp{
    height: 20vw;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_IP_Exp_printimg {
    width: 1.5vw;
    height: 1.5vw;
}

#eMed_IP_ExpPop_TltTxt {
    color: #616161;
    font-weight: 600;
}

.eMed_IP_ExpPop_MainDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 39.8vw;
    background-color: white;
    border-radius: 5px;
    outline: none;
}

.eMed_IP_ExpPop_Header {
    height: 2vw;
    display: flex;
    justify-content: space-between;
    margin-left: 1vw;
    align-items: center;
    padding: 0.5vw;
}

.eMed_IP_ExpPop_wrapper {
    height: 33.9vw;
    padding: 0.5vw;
    overflow: auto;
}

.eMed_IP_ExpPop_btnBox {
    height: 4vw;
    padding: 0.5vw;
}

.eMed_IP_ExpPop_imgDiv {
    margin: 0.5vw;
    width: 27.9vw;
    height: 16.8vw;
}

.eMed_IP_ExpPop_img {
    width: 27.9vw;
    height: 16.8vw;
}

#eMed_IP_ExpPop_Icon {
    position: relative;
    bottom: 18vw;
    left: 26.8vw;
}

.eMed_IP_ExpPop_txtbox {
    margin: 0.5vw;
}

.eMed_IP_ExpPop_imgSubDiv {
    width: 14vw;
    height: 10vw;
    border: 1px dotted var(--gray);
    border-radius: 0.3vw;
}

#eMed_opip_billTxt{
    font-size: 0.9vw;
}

.eMed_split_Label_Amount{
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    width: 10vw;
    cursor: pointer;
}
.eMed_split_Wrap{
    display: flex;
    flex-direction: row;
    margin-left: 1vw;
    margin-top: 0.5vw;
}

.eMed_Crdtype_Header{
    display: flex;
    width: 30vw;
    align-items: center;
    justify-content: space-between;
    height: 7vw;
    padding-left: 1vw;
    padding-right: 1vw;
}
.eMed_PayCard_Icon{
    width:30%;
    display:flex;
    justify-content:center
}
.eMed_Icon_Img{
    height:2vw;
    width:2vw;
}
.eMed_PayCard_Content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
.eMed_PayCard_div {
    width: 12vw;
    height: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.eMed_Amt_card_div{
    width: 10vw;
    height: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
    overflow: visible !important;
}
.eMed_Amt_card{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width:10vw !important
}
.eMed_Amt_card_amount{
    font-size:1.1vw;
    font-weight:600;
}
.eMed_Amt_card_label{
    font-size:0.9vw !important;
    text-align: center;
}
.eMed_Amt_small_size_label{
    font-size:0.85vw !important;
    text-align: center;
}
.com_billing_popup {
    width: 100%;
    /* height: 15dvw; */
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.eMed_CashCounter_IPOP_Search_Pop{
    margin-top: 29vh;
    margin-left: 32vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 23vw !important;
  }
  
.billing_tbl_head {
    background-color: #F5F8F7 !important;
    font-size: 0.85rem !important;
    font-weight: 600 !important;
    padding: 0.1rem 0.5rem !important;
}

.billing_tbl_body {
    font-size: 0.85rem !important;
    padding: 0.1rem 0.5rem !important;
}

.reports_container {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color: #F5F8F7;
    height: 75vh;
    max-width: 100vw;
}

.reports_header_card {
    padding: 1vw 0vw 0vw 1vw;
    display: flex;
}

.reports_left {
    display: flex;
    flex: 0.6 1;
}

.reports_flex_one {
    flex: 0.25 1;
}

.reports_flex_two {
    flex: 0.25 1;
}

.reports_flex_three {
    flex: 0.25 1;
}

.reports_flex_four {
    flex: 0.25 1;
}

.reports_doctor_left {
    display: flex;
    flex: 0.6 1;
}

.reports_doctor_flex_one {
    flex: 0.25 1
}

.reports_doctor_flex_two {
    flex: 0.25 1
}

.reports_doctor_flex_three {
    flex: 0.25 1
}

.reports_doctor_flex_four{
    flex: 0.25 1
}

.reports_doctor_right {
    display: flex;
    flex: 0.4 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1.6vw;
}

.reports_right {
    display: flex;
    flex: 0.4 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1.6vw;
    position: relative;
    cursor: pointer;
}

.report_date_picker {
    margin-right: 1vw;
}

.reports_button {
    margin-right: 1vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
    position: relative;
    top: -0.4vw;
}

.reports_button_two {
    position: relative;
    top: -0.4vw;
}

.reports_table_card {
    /* max-height: 100vh; */
    height: 61vh;
    width: 92vw;
    margin: auto;
    background-color: white;
    overflow: scroll;
    margin-top: 1vw;
}

.reports_font_size {
    font-size: 1.2vw;
    font-weight: 600;
}

.reports_box_one {
    background-color: white;
    height: 5vw;
    width: 12vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reports_bill_left {
    display: flex;
    flex: 0.7 1;
}

.reports_bill_flex_one {
    flex: 0.2 1
}

.reports_bill_flex_two {
    flex: 0.2 1;
}

.reports_bill_flex_three {
    flex: 0.2 1
}

.reports_bill_flex_four {
    flex: 0.2 1
}

.reports_bill_flex_five {
    flex: 0.2 1
}

.reports_bill_box_one {
    position: relative;
    right:1vw;
}

.bill_background_pending {
    background-color: #FFE9E9;
    color: #B82121;
}

.bill_background_paid {
    background-color: #ECFFE9;
    color: #1E972A;
}

.paid {
    width: 7vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1E972A;
    background-color: #ECFFE9;
    border-radius: 5px;
}

.pending {
    width: 7vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B82121;
    background-color: #FFE9E9;
    border-radius: 5px;
}
.eMed_bill_print {
    background-color: lightgray;
}
.reports_print {
    height: 2.2vw;
    cursor: pointer;
    position: relative;
    top: -0.5vw;
    right: 0.5vw;
    /* border-left: 2px solid gray; */
    /* padding-left: 0.5vw; */
}

.eMed_rts_upload{
    height: 2.2vw;
    cursor: pointer;
    position: relative;
    top: -0.5vw;
    margin-right: 0.5vw;
}

.eMed_rts_date_pick{
    position: relative;
    top: 0.9vw;
}

.eMed_rts_dropdown_lab_new{
    width: 22vw;
    margin: 1vw 0vw;
}
.eMed_rts_container{
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_rts_content{
    background-color: white;
    margin: 1vw 1vw 0vw 1vw;
    width: 91.8vw;
    min-height: 6vw;
    flex-wrap: wrap;
}

.eMed_rts_content_top{
    padding: 1vw;
}

.eMed_rts_txt_one{
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vw;
}

.eMed_rts_content_btm{
    padding-top: 0.5vw;
    display: flex;
    flex-direction:row ;
    flex-wrap: wrap;
}

.eMed_rts_icon{
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 1vw;
}

.eMed_rts_btn{
    text-transform: capitalize;
}

.eMed_rts_image{
    width: 2vw;
    height: 2vw;
    margin-right: 0.5vw;
}

.eMed_Cards_Container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    padding-right: 0.5vw;
}

.eMed_Cards_amount{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5vw;
}

.eMed_Action_Container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}

.eMed_Top_Container {
    height: 13vh;
    display: flex;
    flex-direction: row;
}

.eMed_rts_table{
    height: 66vh;
    width: 92vw;
    margin: auto;
    background-color: white;
}

.eMed_rts_filter_container{
    width:25vw;
    margin-top: 5vw;
}

.eMed_filter_Top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
}

.eMed_action_img_small{
    height: 1vw;
    width: 1vw;
}

.eMed_rts_dropdown{
    width: 20vw;
    margin: 1vw 1vw !important;
}
.eMed_age_container{
    width: 20vw;
    margin: 1vw 1vw !important;
    display: flex;
    flex-direction: row;
}

.eMed_rts_middle{
    width: 23vw;
    margin: auto;
    height: 75vh;
}

.eMed_date_filter{
    width: 11vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 11vw;
    margin-bottom:1.5vw;
}

.eMed_rts_btn{
    padding: 0.625;
}

.eMed_rts_home_container{
    background-color: #F5F8F7;
    height: 83vh;
    margin-top: 0.7vw;
}

.eMed_rts_popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 20vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_rts_home_header{
    padding: 1vw;
}

.eMed_rts_home_txt_one{
    color: gray;
}
.eMed_rts_home_txt{
    background-color: #E4F2F0;
}

.eMed_rts_home_btn{
    margin-right:1vw !important;
    text-transform: capitalize !important;
}

.eMed_rts_home_btn_two{
    text-transform: capitalize !important;
}

.eMed_rts_fab{
    position: absolute !important;
    bottom: 5vw !important;
    right: 5vw !important;
}

.eMed_reports_text_btn{
    text-transform: capitalize !important;
    color: black !important;
}
.eMed_reports_text_btn:hover{
    text-transform: capitalize !important;
    color: var(--themColor) !important;
    transform: scale(1.01) !important;
}

.stickyHeader {
    position: "sticky";
    left: 0;
    z-index: "9999 !important";
 }

 .eMed_rts_filter_container_New{
    width:29vw;
    margin-top: 5vw;
}

.eMed_rts_middle_New{
    /* width: 29vw; */
    max-height: 74.5vh;
    overflow: auto;
}
.eMed_MainComponentDiv{
    height: 78vh;
}
.eMed_SubComponentDiv{
    height: 72vh;
}
.eMed_tabWrapper{
    display:flex;
    flex-direction: row;
    width:100%;
    border-bottom: 1px solid lightgray;
}
.css-eopcd7-MuiButtonBase-root-MuiTab-root{
    max-width: 23vw !important;
    min-width: 5.5vw !important;
}

.eMed_NoAccess_Text{
    font-weight: 600 !important;
    padding-left: 40vw !important;
}
.eMed_ModuleScreen_Wrapper {
    height: 85vh;
    width: 98%;
    /* border: 1px solid lightgray; */
    border-radius: 0.26vw;
    box-shadow: 5px;
    overflow-y: scroll;
}

.eMed_Proceed_Btn {
    display: flex;
    justify-content: center;
    margin-top: 1.5vw;
}

.eMed_Cards_Wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0.5vw;
    max-height: 41vh;
    background-color: "red";
    overflow-y: scroll;
}
.eMed_Main_div { 
     max-height:80vh;
     overflow-y:scroll;
}
.eMed_OP_Appointment {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_OP_AptHead {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6vh;
}

.eMed_OP_AddPatient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_OP_Apt_Cards {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
}

.eMed_OP_PatientType_CreateBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.eMed_OP_PatientType {
    display: flex;
    flex-direction: row;
}

.eMed_OP_TypeNew {
    font-size: 0.8vw;
}

.eMed_OP_TypeEstablished {
    font-size: 0.8vw;
    margin-top: -.3vw;
}

.eMed_OP_AddPatient_Icon {
    width: 4vw;
    height: 4vw;
}

.eMed_Apt_TblHead {
    background-color: #FAF9F9;
}

.eMed_OP_Patient_Iconcircle {
    width: 0.8vw;
    height: 0.8vw;
}
.eMed_OP_Patient_Iconcircle_Appointment{
    width: 1.1vw;
    height: 1.1vw;
    margin-top: 0.8vh;
}

.eMed_OP_AptType_Icon {
    margin-left: 0.3vw;
}

.eMed_OP_APtTbl_PayPending {
    margin-left: 0.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_OP_AptTbl_PayIcon {
    height: 0.8vw;
    width: 0.8vw;
    margin-right: 0.3vw
}

#eMed_OP_AptTbl_PayText {
    font-size: 0.9vw;
}

.eMed_OP_AptTbl_PayCompletedIcon {
    height: 1vw;
    width: 1vw;
    margin-right: 0.3vw;
}

.eMed_OP_AptTbl_Status_Icon {
    width: 1.5vw;
    height: 1.5vw;
}

.eMed_OP_AptTbl_StatusCom_Icon {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_OP_Apt_ActionBtn {
    display: flex;
    flex-direction: row;
    width: 3vw;
    justify-content: space-between;
}

.eMed_OP_Apt_BtnDiv {
    display: flex;
    flex-direction: row;
}

.eMed_AptTbl_Con {
    height: 65vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_OP_AptTbl_Comment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 12vw;
}

#eMed_OP_AptTbl_AptText {
    font-size: 0.8vw;
}

#eMed_OP_AptTbl_AptText1 {
    font-size: 0.7vw;
}

#eMed_OP_Btn {
    min-width: 0.2vw !important;
    padding: 0.5vw !important;
    padding-top: 0.2vw !important;
    padding-bottom: 0.2vw !important;
}

.eMed_NxtAppPop_Title_Div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_NxtAppPop_TextDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 1.3vw;
}

.eMed_NxtAppPop_Duration_Grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 1vw;
}

.eMed_NxtAppPop_Dropdown {
    width: 10vw;
}

.eMed_NxtAppPop_Duration_GridItem {
    margin-top: 1vw !important;

}

.eMed_details_label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 10vw;
}

.eMed_NxtAmt_POp_BtnDiv {
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.eMed_nxtAppoint_img {
    height: 1vw;
    width: 1vw;
}

.eMed_nxtAppoint_img_small {
    height: 0.8vw;
    width: 0.8vw;
}

.eMed_bill_container {
    /* margin-top: 0.7vw; */
    background-color: #F5F8F7;
}

.eMed_bill_content {
    height: 80vh;
}
.eMed_bill_content1 {
    height: 70vh;
}
.eMed_bill_ipContainer{
    height: 70vh;
    position: relative;
}
.eMed_bill_ipContent{
    height: 68vh;
}
.eMed_bill_ipContent1{
    height: 66vh;
}
.eMed_bill_content2 {
    height: 7vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95vw;
    margin-top: 3vw;
}
.eMed_bill_Ipcontent2{
    /* width: 100%; */
    height: 7vh;
    /* background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
    /* position: absolute;
    bottom: 0.3vw; */
}

.eMed_createapt_right1 {
    height: 42vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_createapt_right2 {
    height: 33vh;
    background-color: white;
    width: 30vw;
    margin-top: 0.5vw;
    margin-right: 1vw;
    overflow-y: scroll;
}

.eMed_createapt_right1_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 1vw;
}

.eMed_createapt_right2_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 27vw;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
}

.eMed_createapt_right2_field_img{
    width: 4vh;
    height: 4vh;
}

.eMed_createapt_left {
    width: 60vw;
    height: 73vh;
    margin-top: 1vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.eMed_ipcreateapt_left{
    margin-top: 0.4vw;
}
.eMed_createapt_left1 {
    height: 15vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
}

.eMed_createapt_left2 {
    height: 54vh;
    margin-left: 1vw;
    margin-top: 1vw;
    width: 60vw;
    overflow-y: scroll;
}
.eMed_ipcreateapt_left2{
    height: 52vh;
    margin-top: 0.625vw;
}

.eMed_createapt_left1_paper1{
    background-color: white;
    height: 15vh;
    width: 48vw;
}

.eMed_createapt_left1_paper2{
    background-color: white;
    height: 15vh;
    width: 11vw;
    margin-left: 1vw;
}

.eMed_createapt_left1_paper1_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    width:95%;
    height: 9vh;
}


.eMed_createapt_left1_paper2_icon {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.eMed_createapt_left1_paper2_img {
    width: 6.5vw;
    height: 6.5vw;
    border-radius: 5vw;
}

.emed_qckbill_box1 {
    height: 10vh;
    width: 93vw;
}

.emed_qckbill_box1_accordian {
    height: 10vh;
    width: 93vw;
    background-color:  #F5F8F7 !important;
}
.emed_qckbill_box1_common {
    background-color:  #ffffff !important;
}

.emed_qckbill_box2 {
    height: 60vh;
    width: 90vw;
}

.emed_qckbill_box2_Grid {
    display: flex;
    flex-direction: row;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
}

.eMed_quickbill_left {
    width: 60vw;
    height: 70vh;
    margin-top: 0.3vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.eMed_quickbill_left_view1 {
    width: 61.5vw;
    height: 7vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_quickbill_left_view2 {
    width: 60vw;
    height: 62vh;
    margin-left: 1vw;
    background-color: white;
    overflow-y: scroll;
}
 
.eMed_quickbill_right {
    width: 32vw;
    height: 70vh;
    margin-left: 1vw;
    margin-top: 0.3vw;
}

.eMed_quickbill_right_view1{
    height: 7vh;
    display: flex;
    align-items: center;
    margin-left: 0.5vw;
}

.eMed_quickbill_right_view2 {
    height: 62vh;
    background-color: white;
    overflow-y: scroll;
}

.emed_qckbill_box1_accordian_detail_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#eMed_OP_oldComments_Con {
    height: 35vh;
    margin-bottom: 1vw;

}

.eMed_OP_oldComments_Con1 {
    width: 25vw;
    border: 1px solid #E3E2E2;
    background-color: #E4F2F0;
    border-radius: 0.3vw;
    padding: 0.3vw;
    display: flex;
    flex-direction: column-reverse;
}

.eMed_OP_Cmts_Text {
    color: #616161;
    font-size: 0.7vw;
    font-weight: bold;
}

.eMed_OP_Cmts_Text1 {
    color: #616161;
    font-size: 0.5vw;
    display: flex;
    justify-content: flex-end;
}
.eMed_OP_CancelPatient{
    background-color: #E3E2E2;
}

.eMed_TextBox_SearchIcon{
    height: 2vw;
    width: 2vw;
    margin-left: 2vw;
}

.eMed_Services_chips{
    margin: 0.2vw !important;
}
.eMed_Chips_Wrapper{
    display: flex;
    flex-wrap: wrap;
    max-height: 8vw;
    overflow-y: scroll;
    margin: 0.3vw;
    padding-top: 0.5vw;
    padding-left: 1vw;
}
.eMed_Date_Time_Style{
    width: 14.5vw;
    margin: 1vw 0vw 0vw 1vw;
    border-top-right-radius:5px ;
    border-top-left-radius: 5px;
}

.eMed_service_select_div{
    height: 18vw;
}

.eMed_Buttons_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5vh;
    padding: 0.3vw;
}

.eMed_OP_no_comments_txt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
}

#eMed_OP_no_comments_div {
    height: 16vh;
    margin-bottom: 1vw;
    width: 25vw;
}

.eMed_Header_OP_home_div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Header_OP_Noshow_btn{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-bottom: 0.2vw red solid !important;
    border-radius: 2 !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
}

.eMed_Header_OP_Noshow_btn_on{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-bottom: 0.4vw red solid !important;
    border-radius: 2 !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
}

.eMed_Demo_Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_Demo_Created_Con {
    display: flex;
    flex-direction: row;
    width: 4vw;
    justify-content: space-between;
    margin-right: 0.3vw;
}

#eMed_Demo_Created_Text {
    font-size: 0.6vw;
    color: #888888;
}
.eMed_App_Doctor_table_head{
    height: 2vw;
    background-color: rgb(226, 226, 226);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  .eMed_App_Doctor_table_data {
    height: 2vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 23vw;
  }

  .emedhub_popup_lobby {
    width: 100%;
    height: 36dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
  }
.eMed_refdoc_content {
    height: 80vh;
}

.eMed_refdoc_left {
    width: 60vw;
    height: 73vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_refdoc_right {
    height: 73vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_common_Tbl_left {
    width: 57vw;
    height: 76vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_common_Tbl_right {
    height: 76vh;
    background-color: white;
    width: 34vw;
    margin-top: 1vw;
    margin-left: 0.5vw;
}

.eMed_refdoc_txt_card {
    margin-top: 1vw;
    height: 30.5vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_refdoc_btn_card {
    position: relative;
    top: 13.3vw;
    height: 7vh;
    background-color: #FAF9F9;
}


.eMed_refdoc_add_btn {
    width: 6vw;
    height: 4.5vh;
    position: relative;
    top: 0.5vw;
}

.eMed_refsrc_txt_card {
    height: 61vh;
    padding: 1vw;
}

.eMed_refsrc_txt {
    height: 8vh;
}

.eMed_refdoc_grid {
    display: flex;
    justify-content: end;
    height: 7vh;
}

#eMed_Config_mainDiv {
    /* width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1vw;
    padding-top: 0; */
}

.eMed_Config_Wrapper {
    margin-top: 0.5vw;
    padding-top: 0.3vw;
    border-radius: 0.3vw;
    width: 100%;
    height: 97%;
    overflow-y: auto;
}

#eMed_Config_header {
    padding: 0.5vw;
    margin-top: 0.5vw;
    /* padding-left: 1.5vw; */
    font-weight: 600;
}

.eMed_Config_Div {
    margin-top: 0.5vw;
}

.eMed_srvConfig_Box {
    height: 80vh;
}

#eMed_srvConfig_Container {
    margin-top: 1.5vw;
    padding: 0 0.625vw 0 0.625vw;
}

.eMed_srvConfig_Div {
    height: 75vh;
}

.eMed_srvConfig_subDiv {
    /* padding: 1vw; */
    height: 65vh;
    overflow: scroll;
  
}
.eMed_Colour_Container{
    display: flex;
    flex-direction: row;

}

.eMed_Colour_text{  
    margin-left: 1vw;
    margin-top: -48vh;
}

.eMed_Colorchoser{
    display: flex;
    flex-direction: row;
    padding: 1px;
    border: 1px solid rgb(12, 12, 12);
    border-radius: 50%;
    height: 4.0vh;
    width: 2vw;
    margin-top: 2vh;
    margin-left: 1.5vw;  
}
.favouriteColor{
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(12, 12, 12);
    height: 4.5vh;
    width: 2.5vw;
    margin-top: 2vh;
    margin-left: 1.5vw;
    border: none;
   
}
 
.emed_ColorShow{
    width: 5vw; 
    border:2px solid black;
    border-radius: 5px;
    margin-top: 1vh;
    margin-left: 10px ;
    height: 5vh;
}
.emed_ColorShowgrid{
    width: 1vw; 
    border:1px solid black;
    border-radius: 3px;
    margin-top: 1vh;
    margin-left: 10px ;
    height: 1vh;
    background-color: rgb(36, 21, 248);

}

.eMed_arrowBtn {
    width: 0.8vw;
    height: 0.8vw;
}

#eMed_srvConfig_divs {
    margin: 0.5vw;
}

#eMed_srvConfig_rightTbl {
    width: 29.8vw;
    height: 26vh;
    margin: 0.5vw;
}

#eMed_srvConfig_accTbl {
    max-height: 30vh;
}

.eMed_srvConfig_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_srvConfig_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}

#eMed_Config_btn {
    text-transform: capitalize;
}

#eMed_srvConfig_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_srvConfig_rightTbl2 {
    width: 29.8vw;
    height: 53vh;
    margin: 0.8vw 0.5vw;
}

#eMed_activeBtn {
    width: 1.6vw;
    height: 1.6vw;
}

.eMed_usrconf_container {
    height: 80vh;
    background-color: #F5F8F7;
}

.eMed_usrconf_header {
    height: 8vh;
    display: flex;
    justify-content: end;
    align-items: center;
}

.eMed_usrconf_btn {
    min-width: 0vw !important;
    height: 2vw;
}

.eMed_usrconf_img {
    height: 2vw;
    width: 2vw;
}

.eMed_refdoc_txtbox {
    width: 13vw;
}

.eMed_refdoc_add {
    padding-left: 1.2vw;
}

.eMed_conf_nodata {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_refdoc_container {
    margin-top: 1.05vw;
    background-color: #F5F8F7;
}

.eMed_common_container {
    margin-top: 0.2vw;
    background-color: #F5F8F7;
}

.eMed_refdoc_title {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #F5F8F7;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_docnotes_sub_title {
    height: 4vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    font-weight: 600 !important;
    color: #616161;
    font-size: 0.9vw !important;
}


.eMed_refdoc_clr_btn {
    padding-left: 8vw;
}


.eMed_corpconfig_txt_card {
    height: 61.5vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_roomconfig_txt_card {
    height: 62vh;
    padding: 1vw 1vw;
}

.eMed_Right_contents {
    height: 63.5vh;
    padding: 1vw 1vw;
    overflow-y: scroll;
}

.eMed_corpConfig_btn_card {
    position: relative;
    top: 1vw;
    height: 7vh;
    background-color: #FAF9F9;
}

.eMed_Patient_Country {
    width: 13.5vw;
    height: 2.6vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    margin-top: 0.8vw;
}

.eMed_ref_btn {
    height: 6.8vh;
    padding: 0.625vw;
}

.eMed_refsrc_btn {
    height: 6vh;
    padding: 0.625vw;
}

.eMed_demolayout_right1 {
    height: 13vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_demolayout_right2 {
    height: 54.8vh;
    background-color: white;
    width: 30vw;
    margin-top: 0.2vw;
    margin-right: 1vw;
    overflow-y: scroll;
}

.eMed_demolayout_right3 {
    height: 7vh;
    background-color: white;
    width: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_demolayout_left1 {
    width: 60vw;
    height: 23vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
    /* overflow-y: scroll; */
}

.emed_demo_left_title_right {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-right: 1vw;
    /* background-color: #FAF9F9; */
    font-weight: 500 !important;
    color: #888888;
}

.eMed_demolayout_left2 {
    width: 60vw;
    height: 50vh;
    margin-top: 0.2vw;
    margin-left: 1vw;
    background-color: white;
    /* overflow-y: scroll; */
}

.emed_demolayout_right1_box {
    padding-top: 1vh;
    flex-direction: row;
    display: flex;
}

.emed_demolayout_right1_select {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.eMed_demolayout_right2_field {
    display: flex;
    flex-direction: row;
    padding: 0.2vw;
    margin-left: 1vw;
    border-bottom: 1px solid #E3E2E2;
}

.eMed_demolayout_right2_field_img {
    margin-right: 1vw;
}

.eMed_DisSum_Lay_DragImg {
    margin-right: 1vw;
    width: 1vw;
    height: 1vw;
}

.eMed_demolayout_left_TextInput {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 1vw;
    padding-bottom: 1vh;
    /* height: 20vw;
    overflow-y: scroll; */
}

.eMed_demolayout_left_TextInput_field {
    margin-top: 2.5vh;
    margin-left: 1vw;
    width: 12vw;
}

#eMed_srvTemp_rightTbl {
    width: 29.8vw;
    height: 31.8vh;
    margin: 0.5vw;
}

.eMed_conf_user_table {
    height: 70vh;
}

.eMed_usrConf_tbl {
    background-color: white;
    width: 92.6vw;
    margin: auto;
}

.eMed_DisSum_Lay_Select {
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: flex-end; */
    /* padding-right: 1vw; */
    background-color: #FAF9F9;
}

.eMed_DisSum_Lay_Spec {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.eMed_DisSum_Lay_select2 {
    width: 10vw;
}

.eMed_DisSum_TxtBox {
    width: 17vw;
}

.eMed_DisSum_right_Btn {
    border-radius: 0px 0px 3px 3px;
}

.eMed_Dissum_lay_Con {
    margin: 0.8vw;
    height: 57.8vh;
}

.eMed_demolayout_right2_field1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2vw;
    margin-left: 1vw;
    border-bottom: 1px solid #E3E2E2;
}

.eMed_DisSum_Lay_StarIcon {
    width: 1vw;
    height: 1vw;
}

.eMed_DisSum_Lay_LeftHead {
    display: flex;
    flex-direction: row;
    padding: 0.5vw;
}

.eMed_DisSum_Lay_right_Head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
}

#eMed_DisSum_Lay_right_HeadTxt {
    font-weight: 600;
}

#eMed_DisSum_Lay_right_HeadTxt2 {
    font-size: 0.7vw;
    color: #616161;
}

.eMed_DisSum_Lay_right_Feilds {
    height: 46vh;
    overflow: scroll;
}

.eMed_DisSum_Lay_StarBtn {
    margin-right: 1vw;
}

.eMed_DisSum_Lay_AdmDig {
    display: flex;
    flex-direction: row;
    width: 43vw;
    justify-content: space-between;
}

.eMed_DisSum_Icd_TxtBox {
    width: 26vw;
}

.eMed_DisSum_Lay_TblDel {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_DisSum_Lay_CommonBox {
    margin: 1vw;
}

.eMed_DisSum_Lay_CommonBox1 {
    margin: 2vw 1vw 1vw 1vw;
}

.eMed_DisSum_com_TxtBox {
    width: 57.6vw;
    height: 12vh;
}

.eMed_DisSum_Lay_disBox {
    margin: 2vw 1vw 1vw 1vw;
    border: 1px solid #E3E2E2;
    height: 29vh;
    border-radius: 0.3vw;
}

.eMed_DisSum_Lay_Diet {
    width: 27vw;
}

.eMed_DisSum_AddIcon {
    display: flex;
    flex-direction: row;
    margin: 1vw;
    justify-content: space-between;
}

.eMed_DisSum_Lay_CommonBox2 {
    margin: 1vw 1vw 2vw 1vw;
    height: 20vh;
}

.eMed_DisSum_Lay_leftCon {
    height: 65vh;
    overflow: scroll;
}

.eMed_DisSum_Lay_AddField {
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
}

.eMed_DisSum_Lay_Anaesthsia {
    width: 57vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_DisSum_Lay_Dietcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_DisSum_Lay_Advice {
    width: 55.5vw;
    margin-top: 1vw;
}

#eMed_ipsrvConfig_accTbl {
    width: 100%;
    height: 47dvh;
}

.eMed_ipPackage_Div {
    height: 63vh;
}

.eMed_ipPackage_subDiv {
    padding: 1vw;
    margin-right: 0.5vw;
}

#eMed_ipPackage_rightTbl {
    height: 36vh;
    margin: -1.8vw 0.5vw 0.5vw 0.5vw;
}

#eMed_ipPackage_rightBtnCon {
    height: 9.8vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_ipPackage_txt1 {
    font-size: 0.6vw;
    font-weight: 600;
}

#eMed_ipPackage_txt2 {
    font-size: 0.8vw;
    font-weight: 600;
}

.eMed_WardConfig_Table {
    padding: 1vw;
    /* border: #888888 1px solid; */
    height: 15vw;
    margin-top: 0.3vw !important;
    border-radius: 0.3vw;
}

.eMed_Row_Div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1vw;
    margin-right: 1vw;
}

.eMed_small_textFields {
    width: 15vw;
    margin-top: 1vw !important;
}

.eMed_small_textFields2 {
    width: 13vw;
    margin-top: 1vw !important;
}

.eMed_small_textFields3 {
    width: 14.5vw;
    margin-top: 1vw !important;
}

.eMed_Right_Fields_Div {
    height: 70vh;
}

.eMed_SplitUp_TextFields_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw;
}

.eMed_Accord_Div {
    height: 10vw;
    overflow-y: scroll;
}

.eMed_Room_Accord {
    min-height: 20vw;
    overflow-y: scroll;
    margin-top: 0.3vw
}

.eMed_Dis_Template_MainCon {
    width: 93vw;
    height: 76vh;
    background-color: white;
    border: 1px solid #E3E2E2;
    border-radius: 0.3vw;
    margin: auto;
}

.eMed_Dis_Tem_HeadCon {
    background-color: #FAF9F9;
    height: 6vh;
    border-radius: 4px 4px 0px 0px;
}

.eMed_Dis_Layout_selectCon {
    margin: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 45vw;
}

.eMed_Dis_Template_NoRecord {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13vh;
}

.eMed_DisSum_Template_Diet {
    width: 43vw;
}

.eMed_DisSum_Lay_AddTest {
    display: flex;
    flex-direction: row;
    width: 90vw;
    justify-content: space-between;
}

.eMed_Dis_Tem_ActionBtn {
    display: flex;
    flex-direction: row;
}

.eMed_DisSum_Tem_MediTextBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_Dis_Tem_Tbl {
    height: 70vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_Dis_Tem_TblHead {
    background-color: #FAF9F9;
}

#eMed_ipsrvConfig_rightTbl {
    width: 29.8vw;
    height: 27vh;
    margin: 0.5vw;
}

.eMed_DisSum_Tem_Nolayout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45vh;
}

.eMed_Dis_Sum_Tem_lay_Scroll {
    overflow: scroll;
    height: 53vh;
}

.eMed_Dis_Sum_Tem_BtnGroup {
    display: flex;
    justify-content: center;
}

.eMed_Print_Option_Con {
    height: 64vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_Print_SCon {
    height: 13vw;
    width: 31vw;
}

.eMed_Print_FooCon {
    height: 16vw;
    width: 31vw;
}

.eMed_Pr_SizeImg {
    display: flex;
    flex-direction: row;
    width: 14.5vw;
    justify-content: space-between;
    margin: 1vw;
}

.eMed_Pr_OriImg {
    display: flex;
    flex-direction: row;
    width: 16vw;
    justify-content: space-between;
    margin: 1vw;
}

.eMed_Img_cursor {
    cursor: pointer;
}

.eMed_Print_title {
    height: 4vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #FAF9F9;
}

.eMed_ABill_Head {
    color: #616161 !important;
    background-color: #F5F8F7 !important;
    font-weight: 600 !important;
    padding: 0.5vw !important;
}

.eMed_ABill_Tbl {
    height: 66.6vh;
}

.eMed_ABill_Tblcell {
    padding: 0.5vw 0vw 0.5vw 0.5vw !important;
}

.eMed_AB_NoRecords {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.eMed_AB_NoRecords1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vh;
}

.eMed_leftTbl_NoRecords {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.eMed_AB_leftData {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_AB_Left_Tbl {
    height: 55vh;
    overflow: scroll;
}

.eMed_user_conf_container {
    height: 80vh;
}

.eMed_user_conf_header {
    height: 10vh;
    display: flex;
    padding: 1vw;
    justify-content: flex-end;
    align-items: center;
}

.eMed_user_conf_content {
    height: 70vh;
}

.eMed_user_conf_grid {
    width: 92vw;
    margin: auto;
    background-color: white;
}

.eMed_user_conf_img {
    border-right: 2px solid gray;
    margin-right: 1vw;
    padding-right: 1vw;
    position: relative;
    top: 0.2vw;
}

.eMed_edit_img {
    height: 1.6vw;
    width: 1.6vw;
    border-radius: 3px;
}

.eMed_user_btn {
    min-width: 0vw !important;
}

.eMed_new_container {
    height: 80vh;
    display: flex;
}

.eMed_new_left {
    flex: 40vw 1;
}

.eMed_new_right {
    flex: 60vw 1;
}

.eMed_new_right_card {
    margin: 0.5vw;
    height: 77.5vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 1px black;
    padding: 0.5vw 0vw 0vw 0vw;
}

.eMed_new_right_top {
    margin: 0.2vw;
    height: 12vw;
    border-bottom: 1px solid lightgray;
}

.eMed_new_middle {
    margin: 0.2vw;
    height: 33.3vw;
    overflow: scroll;
}

.eMed_new_btn {
    height: 3.3vw;
}

.eMed_new_right_middle {
    /* height: 15vw; */
    margin-left: 0.2vw;
}

.eMed_input_text_box_UI {
    width: 12vw;
}
.eMed_input_text_box {
    margin: 0.5vw 1vw !important;
    width: 15vw;
}

.eMed_input_select_box {
    margin: 0.5vw 2.5vw 0.5vw 1vw;
}

/* .eMed_new_right_bottom{
    height: 17vw;
} */

.eMed_select_text_box {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
    width: 13vw;
}

.eMed_select_text_box_ui {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
    width: 12.3vw;
}

.eMed_permission_box {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
}

#eMed_permission_header {
    font-weight: 600;
    color: #616161;
    font-size: 0.95vw;
}

.eMed_permission_hdrBox {
    height: 4vw;
    border-bottom: 1.5px solid lightgray;
}

#eMed_permission_subheader {
    color: #616161;
    font-size: 0.89vw;
}

.eMed_permission_card {
    height: 25.8vw;
    overflow: scroll;
}

#eMed_permission_checkTxt {
    font-size: 0.89vw;
    font-weight: 600;
    color: #616161;
}

#eMed_permission_Txt {
    font-size: 0.8vw;
    color: #616161;
    margin-left: 1.7vw;
}

#eMed_permission_TimeTxt {
    font-size: 0.8vw;
    color: #616161;
}

.eMed_new_btn_two {
    margin: 0.5vw 0vw 0.5vw 0vw !important;
    text-transform: capitalize !important;
}

.eMed_new_table {
    border: 1px solid lightgray;
}

.eMed_tbl_head {
    font-weight: 600 !important;
    color: #616161 !important;
    background-color: #F5F8F7 !important;
}

.eMed_new_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F8F7;
}

.eMed_new_user_tbl {
    height: 15vw;
    overflow: scroll;
}

.eMed_new_left_content {
    margin: 0.5vw;
    height: 77.5vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 1px black;
    padding: 0.5vw 0vw 0vw 0vw;
}

.eMed_left_header {
    height: 4vw;
    padding-top: 0.3vw;
}

.eMed_left_content {
    height: 33vw;
}

.eMed_new_user_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_new_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eMed_new_image_card {
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.eMed_new_profile_ui {
    height: 5.5vw;
    width: 5.5vw;
}
.eMed_new_profile {
    height: 7vw;
    width: 7vw;
}
.eMed_Useradd_btn_ui {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    position: relative;
    right: 1.5vw;
    top: 4.3vw;
    cursor: pointer;
}

.eMed_add_btn {
    position: relative;
    bottom: 2vw;
    left: 2.3vw;
    cursor: pointer;
}
.eMed_new_img_res_ui {
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    object-fit: fill;
}

.eMed_new_img_res {
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    object-fit: fill;
}

.eMed_new_delete {
    height: 1.5vw;
    width: 1.5vw;
    cursor: pointer;
}

.eMed_new_img_btn {
    min-width: 0vw !important;
    height: 7vw;
    width: 7vw;
    display: flex;
}

.eMed_visibility_icon {
    position: relative;
    right: 3vw;
    top: 1vw;
}

.eMed_delete_user {
    margin: 1vw;
    display: flex;
}

.eMed_delete_img {
    height: 1vw;
    width: 1vw;
    margin-right: 0.5vw;
}

.eMed_top_edit_btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
}

.eMed_edit_img_user {
    height: 1.8vw;
    width: 1.8vw;
    border-radius: 5px;
}

.eMed_lock_img_user {
    height: 1.8vw;
    width: 1.8vw;
}

.eMed_bac_user_btn {
    height: 1.8vw;
    width: 1.8vw;
    margin-left: 1vw;
    cursor: pointer;
}

.eMed_mod_card {
    /* border: 1px solid gray; */
    height: 26.8vw;
    overflow: scroll;
    margin: 0vw 1vw;
}

.eMed_list_image {
    height: 1.2vw;
    width: 1.2vw;
    cursor: pointer;
}

.eMed_userAccessRow {
    border: 1px solid rgb(219, 218, 218);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-right: 0.5vw;
}

.eMed_DisSum_Tem_Anaesthsia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2vw 1vw 1vw 1vw;
}

.eMed_txt_header {
    font-size: 0.9vw;
    color: #616161;
    font-weight: 600;
}

.eMed_tgleBar_grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
}

.eMed_tgleBar_grid_action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.7vw;
}

#eMed_tgleBar_grid_txt {
    color: #616161;
}

.eMed_Config_Btn {
    min-width: 0vw !important;
}

.eMed_New_Ip_Btn {
    min-width: 0vw !important;
    text-transform: capitalize !important;
}

.eMed_New_Ip_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 49vw;
    height: 30vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.eMed_New_OP_Tbl {
    height: 35vw !important;
    overflow: scroll;
}

.eMed_Dis_Layout_selectCon1 {
    margin: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
}

.eMed_top_edit_btn_ui {
    display: flex;
    height: 2.5vw;
    justify-content: space-between;
    align-items: center ;
    border-bottom: 1px solid lightgray;
}

.eMed_Card_Details{
    font-weight: 600;
}
.Billing_No_record {
    height: 10vw;
    width: 100%;
}

.Billing_Table_Container {
    flex-direction: row;
    display: flex;
    flex: 1 1;
    margin-top: 0vw;
    height: 28vh;
}

.Billing_Table_Wrapper {
    flex: 0.82 1;
    background-color: white;
}

.Billing_Table_Header {
    background-color: #FAF9F9;
    height: 1.4vh;
    width: 100%;
}

#Billing_table_Head_text {
    background-color: #FAF9F9;
    font-weight: 600;
    color: #616161;
}

.Billing_Table_body {
    background-color: white;
    display: flex;
    align-items: flex-start;
    flex: 1 1;
    width: 100%;
}

.eMed_quickBill_view3 {
    display: flex;
    flex-direction: row;
    padding-top: 0.3vw;
}

.eMed_quickBill_view3_sub1 {
    flex: 0.6 1;
    height: 15vh;
}

.eMed_quickBill_view3_sub2 {
    flex: 0.4 1;
    height: 15vh;
}

.eMed_quickBill_view4 {
    height: 12vh;
}

.eMed_quickBill_view5 {
    display: flex;
    flex-direction: row;
    height: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}

.eMed_quickBill_Btn_div {
    display: flex;
    justify-content: flex-end;
    height: 5vh;
    align-items: center;
}

.eMed_Esstial_Fields_title {
    font-size: 1.5vw;
    padding-left: 2vw;
}

.eMed_Services_TextFields {
    width: 30vw !important;
    margin-left: 1vw !important;
    margin-top: 0.5vw !important;
}

.eMed_WebCamPop_Main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 37vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_WebCam_Header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_WebCam_Div {
    height: 25vw;
    width: 40vw;
    margin-top: 1.5vw;
}

.eMed_WebCam_Btn_div {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    margin-left: 8vw;
    margin-top: 1.5vw;
}

.eMed_WebCam_Img_Div {
    height: 25vw;
    width: 33.2vw;
    margin-top: 1.5vw;
    margin-left: 3.4vw;
}

.emedhub_icon_img_close {
    position: relative;
    top: -0.6vw;
    left: -0.6vw;
    width: 1.8vw;
    height: 1.8vw;
}

.emedhub_icon_imgCap_close {
    position: relative;
    top: -0.6vw;
    left: -0.6vw;
    width: 1.5vw;
    height: 1.5vw;
}

.emedhub_doc_image_size {
    display: flex;
    flex-direction: row;
}

.eMed_createapt_right2_field_img_inv {
    width: 2.5vw;
    height: 2.5vw;
    cursor: pointer;
}

.eMed_createapt_right2_field_capImg {
    width: 2vw;
    height: 2vw;
    cursor: pointer;
}

.eMed_demo_dropdown_inputs {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
}

.eMed_demo_dropdown_inputs2 {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    background-color: #F5F8F7;
}

.eMed_op_bill_tbl_Card {
    width: 59.8vw;
    margin-left: 0.9vw;
    height: 30vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    background-color: white;
}

.eMed_op_bill_del_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_op_bill_img {
    height: 1.5vw;
    width: 1.5vw;
}

.eMed_op_bill_tbl {
    height: 12vw;
    overflow: auto;
}

.eMed_op_bill_tbl_footer {
    height: 14.5vw;
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgray;
}

.eMed_op_bill_tbl_one {
    height: 8vw;
    display: flex;
}

.eMed_op_bill_tbl_two {
    height: 5.5vw;
    border-top: 1px solid lightgray;
}

.eMed_flex_one {
    flex: 0.65 1;
}

.eMed_flex_two {
    flex: 0.35 1;
    justify-content: space-between;
}

.eMed_payment_op_detail {
    display: flex;
    background-color: #FAF9F9;
}

.eMed_op_pay_one {
    flex: 0.5 1;
    padding: 0.5vw;
}

.eMed_op_pay_two {
    flex: 0.5 1;
}

.eMed_cor_txt {
    padding-left: 0.5vw;
}

.eMed_table_bottom {
    height: 3.2vw;
    display: flex;
    border-top: 1px solid lightgray;
    margin-top: -0.3vw;
}

.eMed_Tbl_btm_one {
    flex: 0.6 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Tbl_btm_two {
    flex: 0.4 1;
    display: flex;
    justify-content: flex-end;
}

.eMed_op_bill {
    height: 64vh;
}

.eMed_op_Bill_list_bill {
    height: 27.5vh;
    overflow: scroll;
}

.eMed_OP_bill_card {
    height: 62vh;
}

.eMed_OP_bill_table_card {
    height: 61vh;
    background-color: white;
    width: 61.5vw;
    margin: auto;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.eMed_OP_bill_right_card {
    width: 30vw;
    height: 61vh;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
}

.eMed_OP_credit_Bill {
    height: 63.5vh;
}

.eMed_OP_bill_right {
    width: 29vw;
    height: 63vh;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
    border-radius: 5px;
    margin: auto 0.8vw auto 0vw;
}

.eMed_OP_bill_table {
    height: 63vh;
    background-color: white;
    width: 62vw;
    margin: auto 0.8vw;
    box-shadow: 0px 0px 1px gray;
    border-radius: 5px;
}

.emed_createAppoint_Checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2vw;
    padding-top: 0.3vw;
}

.eMed_cor_font {
    font-size: 0.7vw;
    color: #616161;
    font-weight: 600;
}

.eMed_service_name {
    height: 7vw;
}

.eMed_TextField_Small_font {
    font-size: 0.5vw !important;
}

.eMed_Discount_Reason {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 15vw;
    background-color: white;
    overflow: scroll;
}

.eMed_Service_Discount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32vw;
    height: 61vh;
    background-color: white;
    overflow: scroll;
}

.eMed_Unavailable_point{
    height: 1vw;
    width: 1vw;
    border-radius: 100%;
    margin-right: 0.5vw;
}
:root {
    --gray: #616161;
  }

.eMed_card_header {
    height: 6.3vh;
}

.eMed_card_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}

#eMed_card_img {
    width: 3vw;
    height: 3vw;
}

#eMed_card_lblTxt {
    font-size: 0.8vw;
}

#eMed_card_cntTxt {
    font-size: "0.8vw";
    font-weight: 600;
}

#eMed_DocRepo_Container {
    padding: 0.625vw;
}

.eMed_DocRepo_Box {
    height: 80vh;
}

.eMed_DocRepo_mainDiv {
    height: 74vh;
}

.eMed_DocRepo_Div {
    height: 70vh;
}

.eMed_DocRepo_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_DocRepo_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}

.eMed_DocRepo_subDiv {
    padding: 0.625vw;
    height: 59.8vh
}

#eMed_DocRepo_divs{
    margin: 0.5vw;
}

.eMed_DocRepo_imgDiv{
    margin: 0.7vw 0.5vw;
    width: 27vw;
    height: 34vh;
}

.eMed_DocRepo_imgSubDiv{
    width: 14vw;
    height: 10vw;
    border: 1px solid var(--gray);
    border-radius: 0.3vw;
}

#eMed_img_subTxt{
    font-size: 0.6vw;
    color: var(--gray);
}

#eMed_img_lblTxt {
    font-size: 0.9vw;
    color: var(--gray);
}

#eMed_upload_img{
    width: 2vw;
    height: 2vw;
}
#eMed_DocRepo_datePicker{
    position: relative;
    top: 0.6vw;
}

#eMed_upload_input {
    display: none;
}

.eMed_DocRepo_img {
    width: 63vw;
    height: 64vh;
}

.eMed_Testrep_Img_item {
    width: 27vw;
    height: 5vw;
    margin-top: 0.5vw;
    padding: 0.5vw;
    overflow: scroll;
    overflow-y: hidden;
}

.eMed_Testrep_Img {
    width: 3vw;
    height: 3vw;
    cursor: pointer;
}

#eMed_Testrep_Icon {
    position: relative;
    top: -4.3vw;
    left: 2.5vw;
}

#eMed_DocRepo_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    max-height: 80vh;
    margin: 0 auto;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}

#eMed_DocRepo_popupClose {
    width: 100%;
    height: 3vh;
    padding: 0.625vw;
    margin-top: 0.5vw;
}

#eMed_DocRepo_popupDiv {
    width: 70vw;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 2vw;
}

.eMed_DocRepo_popupIcon {
    width: 1vw;
    height: 1vw;
}

.eMed_nur_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_nur_container1 {
    background-color: #F5F8F7;
    height: 78vh;
}

.eMed_nur_top_card {
    height: 5.6vw;
}

.eMed_nur_grid_container {
    height: 72dvh;
}

.eMed_nur_grid_container1 {
    height: 65dvh;
}

.eMed_nur_left {
    padding-top: 0.5vw;
}

.eMed_nur_table{
    height: 70dvh;
    width: 100%;
    background-color: white;
}

.eMed_nur_right {
    padding-top: 0.5vw;
}

.eMed_nur_right_card{
    background-color: white;
    height: 70vh;
    width: 100%;
}

.eMed_nur_right_txtbox{
    width: 100%;
    height: 58.5vh;
    overflow: scroll;
}

.eMed_nur_rgt_title{
    background-color: #F5F8F7;
    width: 25vw;
    height: 2.5vw;
    padding: 0.5vw;
}

.eMed_nur_btn_card{
    background-color: #F5F8F7;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_nur_btn_one{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
}

.eMed_nur_btn_two{
    text-transform: capitalize !important;
}

.eMed_nur_inner_content{
    padding-left: 1vw;
    font-size: 0.8vw !important;
    color: gray;
}

.eMed_nur_txtBox{
    width: 10vw;
}

.eMed_nur_gestTxtBox{
    width: 5dvw;
}

.eMed_nur_inputAdor{
    display: flex;
    justify-content: center;
}

.eMed_input_label{
    display: flex;
    flex: 0.35 1;
    margin-left: 1vw !important;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1vw;
}

.eMed_input_txtbox{
    flex: 0.65 1;
    margin: 1vw 1vw 0 1vw;
}

.eMed_nur_grid_one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 1.2vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.2vw;
}

.eMed_nur_image{
    display: flex;
    justify-content: center;
}

.eMed_nur_grid_txt{
    margin-left: 0.5vw;
}

.eMed_date_picker{
    margin: 1vw 1.5vw;
    width: 15vw;
    background-color: #F5F8F7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.eMed_nur_date_picker{
    margin: 1vw 1vw !important;
}

.eMed_temp_txt{
    width: 5.3vw;
}

.eMed_Time_Style{
    width: 22.5vw;
    margin: 1vw 0vw 0vw 1vw;
    border-top-right-radius:5px ;
    border-top-left-radius: 5px;
}

.eMed_testrp_div{
    width: 68.5vw;
    height: 2vh; 
    position: relative;
    bottom: 30vh;
}

.eMed_testrp_arrow{
    transform: translate(0, -50%);
    z-index: 1;
    cursor: pointer;
}

.eMed_testrp_arrowSize{
    font-weight: 600;
}
.eMed_Patient_Details_Long{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 1.2vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    padding: 0.2vw;
}
:root{
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}
#eMed_IP_Appointment{
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: var(--bgColor);
}
.eMed_IP_AptHead{
    border-bottom: 1px solid var(--headBorder);
    box-shadow: 0px 2px var(--headboxShadow);
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    height: 8vh;
}
.eMed_IP_AptDateFlt {
    width: 13vw;
    position: relative;
    top: 0.36vw;
}
.eMed_IP_AptTbl_Status_Icon {
    width: 1.5vw;
    height: 1.5vw;
}
.eMed_Price_Card_Img {
    width: 1vw;
    height: 1vw;
}
.eMed_Price_Card_Icon {
    display: flex;
    flex-direction: row;
    width: 25vw;
    align-items: center;
}
.emedhub_Price_popup_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3vw;
    align-items: center;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
    }
.eMed_IP_AptTbl_Status_Icon1 {
    width: 1.6vw;
    height: 1.6vw;
    margin-top: 0.3vw;
}
.eMed_IPAptTbl_Con {
    height: 68vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_IP_checkoutDiv{
    width:32vw;
    background-color: var(--error);
    height: 4.5vh;
    padding: 0.5vw;
    border-radius: 0.3vw;
    margin-bottom: 0.5vw;
}
.eMed_IP_pending_Icon{
    width: 1.3vw;
    height: 1.3vw;
}
.eMed_reservePop_Con{
    min-height: 35vh;
}
.eMed_reservePop_subDiv{
    width: 65vw;
    display: flex;
    justify-content: space-evenly;
    margin: 1.8vw 0.5vw;
}

#eMed_Bed_Card{
    height: 68vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}

#eMed_Bed_Reser_Card{
    height: 55vh;
    width: 80vw;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#eMed_Bed_CardView{
    width: 10.5vw;
    height: 8.5vw;
    margin: 0.5vw;
    display: flex;
}

#eMed_Bed_wardText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-top: 0.8vw;
    width: 7vw;
}

#eMed_Bed_FloorCon{
    width: 1.4vw;
    height: 1.4vw;
    display: flex;
    border-radius: 0.1vw;
    justify-content: center;
    align-content: center;
}

#eMed_Bed_FloorText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: 7vw;
}

#eMed_Bed_Circle{
    display: flex;
    justify-content: center;
    width: 100vw;
    align-self: center;
}
#eMed_Bed_Reserve_CardView{
    width: 9vw;
    height: 6.5vw;
    margin: 0.5vw;
    display: flex;
}

#eMed_Bed_Reserve_wardText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-top: 0.6vw;
    width: 5vw;
}

#eMed_Bed_Reserve_FloorText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: 5vw;
}
#eMed_Bed_Reserve_FloorCon{
    width: 1vw;
    height: 1vw;
    display: flex;
    border-radius: 0.1vw;
    justify-content: center;
    align-content: center;
}

.eMed_Checkout_popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:35vw;
    height: 25vh;
    background-color: white;
    box-shadow: 24;
    padding: 4;
    border-radius: 10px;
}

.eMed_Emergy_Tag{
    background-color: #ffc0cb !important;
}

.eMed_bedavl_pop_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25dvw;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    border: none;
    outline: none;
    padding: 0.5vw;
    border-radius: 0.4vw;
}

.eMed_bedavl_grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 0.625dvw;
    gap: 0.625dvw;
    justify-items: start;
    align-items: start;
    margin: 0.625dvw;
}
.eMed_IPappoint_Stepper_box{
    height: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 3vw;
}

#eMed_back_btn{
    color: black;
    text-transform: capitalize;
}

.eMed_Ipappoint_container {
    margin-top: 0.5vw;
    background-color: #F5F8F7;
}

.eMed_IPappoint_content {
    height: 80vh;
    margin-top: 0.5vw;
}

.eMed_IPappoint_right {
    height: 72vh;
    background-color: white;
    width: 30vw;
    margin-right: 1vw;
}

.eMed_IPappoint_left {
    width: 61vw;
    height: 72vh;
    margin-left: 1vw;
    background-color: white;
    padding-top: 0 !important;
    overflow-y: scroll;
}
.eMed_Ipappoint_btn_div{
    display: flex;
    justify-content: center;
}

#eMed_invd_Button{
    text-transform: capitalize;
    margin-left: 1vw;
}

.eMed_IPappoint_title {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #FAF9F9;
    font-weight: 600 !important;
}
.eMed_IPappoint_Grid_row{
    padding-left: 1.8vw;
}

.eMed_IPappoint_Medico_div{
    display: flex;
    flex-direction: row;
}

.eMed_Ipadmission_Avatar_box{
    display: flex;
    justify-content: center;
    margin-top: 1vw;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vw;
}
.eMed_Ipadmission_Avatar_img{
    height: 10vw;
    width: 10vw;
    border-radius: 5vw;
}

.eMed_IPappoint_Patient_Details{
    width:"100%";
    display: flex;
    justify-content: flex-start;
    margin: 2vw;
}

.eMed_TextEditor_Accord{
    min-height: 14vh;
}

.eMed_IP_BarCode_Box{
    /* width:"100%"; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1vw;
    margin-left: 4vw;
    margin-right: 4vw;
    border:solid grey 1px;
    border-radius: 0.3vw;
    padding-bottom: 1vw;
}
.eMed_IP_barcode_title{
    height: 4vh;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: black;
    font-size: 0.8vw !important;
    border-top-right-radius: 0.3vw;
    border-top-left-radius: 0.3vw;
}

.eMed_IP_barcode_img{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
}

.eMed_IP_BarCode_PrintBox{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
    width: 100%;
}

.eMed_MobileNum_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
    outline: none;
}

.eMed_MobileNum_img{
    min-width: 55vw;
    min-height: 30vw;
}

.eMed_MobileNum_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_MobileNum_wrapper{
    justify-content: center;
    height: 31vw;
    width: 29vw;
    overflow-y: scroll;
}

.eMed_Patient_Num_Search_pop{
    display: flex;
    width: 28vw;
    justify-content: space-between;
    padding: 1vw;
    align-items: center;
}

.eMed_Reservation_Modal_main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 60vh;
    background-color: white;
    border-radius: 5px;
}

.eMed_Reservation_Modal_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_Reserve_Table_Wrapper{
    height: 58vh;
}

.eMed_reserve_dropdown{
    width: 15vw;
    margin: 1vw 1vw !important;
}
:root {
    --gray: #616161;
    --white: #FFFFFF;
    --fill: #E4F2F0;
}

#eMed_recPayment_Container {
    padding: 0.625vw;
}

.eMed_recPayment_Box {
    height: 80vh;
}

.eMed_recCard_header {
    height: 7.5vh;
}

.eMed_recCard_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}

.eMed_recPayment_mainDiv {
    width: 94vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0.8vw;
}

.eMed_IP_recPayment_Div {
    height: 58vh;
    /* margin-top: -0.95vw; */
}

.eMed_recPayment_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5vw;
}

.eMed_recPayment_rightHead_txt {
    font-weight: 600;
    margin-left: 1.3vw;
    color: #616161;
}

.eMed_recPayment_subDiv {
    width: 100%;
    height: 54vh;
    overflow-y: scroll;
}

.eMed_refundPayment_subDiv {
    width: 100%;
    height: 15vw;
    overflow-y: scroll;
}

.emedhub_recPayment_RightContentBox {
    background-color: var(--white);
    padding: 0.625vw;
    margin-left: 0.4vw;
}

#eMed_recPayment_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_recPayment_btn {
    text-transform: capitalize;
}

#eMed_recPayment_Txt {
    font-size: 1vw;
    font-weight: 600;
}

#eMed_recPayment_divs {
    margin: 0.5;
}

#eMed_recPayment_radioTxt {
    font-size: 0.78vw;
    color: var(--gray);
}

#eMed_recPayment_checkTxt {
    font-size: 0.88vw;
    color: var(--gray);
}

#eMed_recPayment_radioCorpTxt {
    font-size: 0.7vw;
    color: var(--gray);
}

.eMed_recPayment_Denomi_div {
    background-color: var(--fill);
    width: 27.5vw;
    height: 12vw;
    border-radius: 0.3vw;
}

.eMed_recPayment_Denomi_invidual_div {
    margin-left: 1.2vw;
    margin-top: 0.8vw;
    width: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#eMed_recPayment_advTxt {
    font-weight: 600;
}

.eMed_patient_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_bill_content {
    height: 68vh;
    display: flex;
}

.eMed_bill_btn_one {
    margin: 0.5vw 0.5vw !important;
    text-transform: capitalize !important;
}

.eMed_bill_tbl_footer_IP {
    display: flex;
    justify-content: space-between;
    height: 1.77vw;
    width: 22vw;
    margin-right: 1vw;
}

.eMed_header_img {
    margin-left: 0.5vw;
    height: 1vw;
    width: 1vw;
}

.eMed_delete_img {
    height: 1.2vw;
    width: 1.2vw;
}

.eMed_right_grid_one {
    height: 21vw;
    margin-bottom: 1.5vw !important;
    background-color: white;
}

.eMed_recPayment_gridDiv{
    height: 61.3vh;
    background-color: white;
}

.eMed_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.eMed_bill_search {
    width: 25vw;
}

.eMed_chip {
    margin: 0.3vw 0.3vw !important;
}

.eMed_right_sub_grid {
    height: 5vw;
    margin-top: 0.5vw;
    display: flex;
}

.eMed_date_picker_grid {
    background-color: #F5F8F7;
    height: 5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    width: 16vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.eMed_date_picker_grid_amount {
    background-color: #F5F8F7;
    height: 5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    width: 12vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.eMed_bill_right_card {
    width: 30vw;
    height: 64vh;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
}

.eMed_bill_select {
    width: 16.5vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_select_two {
    width: 8vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_radio {
    display: flex;
    flex-direction: row !important;
    margin-left: 0.5vw;
}

.eMed_discount_txt {
    margin-right: 0.5vw !important;
    width: 7vw;
}

.eMed_Bill_add_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_edit_btn_bill {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    margin-top: 0.3vw !important;
}

.eMed_bill_table {
    height: 40.5vh !important;
}

.eMed_search_icon {
    width: 2.5vw !important;
    position: relative;
    right: 2.4vw;
    height: 2.5vw !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #04B7B1;
    color: white;
    padding: 0.4vw;
    cursor: pointer;
}

.eMed_bill_header_one {
    margin-left: 0.8vw !important;
}

.eMed_patient_header {
    height: 9.5vh;
}

.eMed_bill_btn_one {
    margin: 0.5vw 0.5vw !important;
    text-transform: capitalize !important;
}

.eMed_bill_table_card {
    height: 64vh;
    background-color: white;
    width: 61vw;
    margin: auto;
    box-shadow: 0px 0px 1px gray;
}

.eMed_bill_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 22vw;
    margin-right: 1vw;
}

.eMed_header_img {
    margin-left: 0.5vw;
    height: 1vw;
    width: 1vw;
}

.eMed_Time_img {
    height: 1.2vw;
    width: 1.7vw;
    padding-right: 0.5vw;
    margin-top: 0.1vw;
}

.eMed_Tick_img {
    height: 1.7vw;
    width: 1.7vw;
    margin-top: 0.05vw;
    padding-right: 0.5vw;
}

.emedhub_DatePicker_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vw;
    margin-top: 1vw;
    margin-bottom: 0.5vw;
}

.eMed_Time_field{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.eMed_delete_img {
    height: 1.2vw;
    width: 1.2vw;
}

.eMed_right_grid_one {
    height: 21vw;
    margin-bottom: 1.5vw !important;
    background-color: white;
}

.eMed_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.eMed_bill_search {
    width: 20vw;
}

.eMed_bill_list {
    overflow: scroll;
    height: 14vw;
}


.eMed_bill_select {
    width: 16.5vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_select_two {
    width: 8vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_radio {
    display: flex;
    flex-direction: row !important;
    margin-left: 0.5vw;
}

.eMed_discount_txt {
    margin-right: 0.5vw !important;
    width: 7vw;
}

.eMed_add_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_edit_btn_bill {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    margin-top: 0.3vw !important;
}

/* .eMed_search_icon {
    width: 2.5vw !important;
    position: relative;
    right: 2.4vw;
    height: 2.5vw !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #04B7B1;
    color: white;
    padding: 0.4vw;
    cursor: pointer;
} */

.eMed_bill_header_one {
    margin-left: 0.8vw !important;
}

.eMed_patient_header {
    height: 7vh;
}

.eMed_patient_content {
    display: flex;
    margin: auto;
}

.eMed_patient_grid {
    height: 29vw;
    width: 22.8vw;
    padding: 0.2vw;
    margin-top: 0.5vw;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    margin: 1vw 0.2vw 0.5vw 0.25vw;
}

.eMed_patient_table_header {
    padding: 0.5vw;
    height: 3vw;
    display: flex;
}

.eMed_patient_text {
    flex: 0.8 1;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_patient_img {
    flex: 0.2 1;
    height: 2.2vw;
    display: flex;
    justify-content: flex-end;
}

.eMed_patient_img_res {
    width: 2vw;
    height: 2vw;
}

.eMed_patient_datagrid {
    background-color: white;
    height: 25dvw !important;
    width: 21.5dvw;
    margin: auto;
}

.eMed_patient_admin {
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
}

.eMed_patient_card {
    height: 70vh;
    overflow: scroll;
    margin-top: 0.75vw;
}

.eMed_patient_box {
    margin-left: 0.3vw;
}

.eMed_table_footer {
    display: flex;
    border-top: 1px solid lightgray;
}

.eMed_table_footer {
    display: flex;
}

.eMed_bill_footer_right {
    display: flex;
    flex-direction: column;
    flex: 0.5 1;
    justify-content: space-between;
}

.eMed_bill_footer_left {
    flex: 0.5 1;
    position: relative;
    left: 1vw;
}

.eMed_bill_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_service_name {
    overflow: scroll;
}

.eMed_service_type {
    height: 7vw;
}

.eMed_Service_title {
    margin-left: 0.5vw !important;
    font-size: 0.8vw !important;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_room_container {
    height: 80vh;
    background-color: #F5F8F7;
}

.eMed_room_header {
    height: 9.5vh;
}

.eMed_room_content {
    margin-top: -0.6vw;
    height: 68vh;
    display: flex;
}

.eMed_room_left_content {
    flex: 0.65 1;
}

.eMed_room_right_content {
    flex: 0.35 1;
}

.eMed_room_left_card {
    width: 59.5vw;
    margin: 0.3vw auto;
    height: 67.5vh;
}

.eMed_room_right_card {
    margin: 0.3vw auto;
    width: 31.5vw;
    height: 67.5vh;
    box-shadow: 0px 0px 1px gray;
}

.eMed_room_grid {
    background-color: white;
}

.eMed_room_right_header {
    height: 5vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
}
.eMed_room_right_content {
  height: 56vh;
}

.eMed_room_right_top {
    height:24.15vh;
    background-color: white;
}

.eMed_room_right_bottom {
    background-color: white;
    height: 28.8vh;
    margin-top: 2vw;
}

.eMed_room_right_footer {
    background-color: #F5F8F7;
    height: 5.5vh;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

.eMed_room_select_one {
    width: 14vw;
    margin: 1vw 0.5vw 0.5vw 1vw !important;
}

.eMed_room_select_two {
    width: 6.5vw;
    margin: 1vw 0vw 0.5vw 1vw !important;
}

.eMed_room_radio {
    margin-left: 1vw !important;
}

.eMed_room_box_one {
    width: 14vw;
    margin: 1vw 0.5vw 0.5vw 1vw !important;
    height: 4.5vw;
}

.eMed_room_date_field {
    margin-top: 0.5vw !important;
    padding: 0.5vw !important;
}

.eMed_room_text_one {
    width: 6vw;
    margin-top: 1vw !important;
    margin-left: 0.5vw !important;
}

.eMed_room_text {
    width: 7vw;
    height: 4.5vw;
}

.eMed_room_change_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 17vw;
    background-color: white;
    border-radius: 10px;
}

.eMed_room_model_header {
    margin: 1vw;
    background-color: #F5F8F7;
}

.eMed_room_modal_btn {
    margin: 1vw;
    display: flex;
    justify-content: space-between;
}

#eMed_medication_Container {
    height: 80vh;
    padding: 0.625vw;
}

.eMed_medication_Box {
    height: 70vh;
}

.eMed_medication_mainDiv {
    height: 65vh;
}

.eMed_medication_Div {
    width: 100%;
    height: 64vh;
    background-color: var(--white);
}

.eMed_medication_TblDiv{
    width: 100%;
    height: 63vh; 
}

.eMed_medication_subDiv {
    width: 100%;
    height: 51.7vh;
    overflow-y: scroll;
}

.eMed_lab_subDiv {
    width: 100%;
    height: 58.7vh;
}

.eMed_medication_rightHead {
    height: 6.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5vw;
}

.emedhub_medication_RightContentBox {
    background-color: var(--white);
    padding: 0.5vw;
    margin: 0 auto;
}

#eMed_medication_chipDiv {
    width: 27.5vw;
    /* min-height: 20vh;
    max-height: 30vh; */
    margin: 0.1vw auto;
}

#eMed_medication_chip {
    border-radius: 0.3vw;
    margin: 0.3vw 0.5vw 0.3vw 0vw;
    height: 2.3vw;
}

.emedhub_medication_img {
    width: 1.5vw;
    height: 1.5vw;
}
.emedhub_medications_img {
    width: 1.5vw;
    height: 1.5vw;
    margin-top: 0.3vw;
}

.eMed_medication_maenDiv {
    width: 27.5vw;
    height: 23vh;
    margin: 0.5vw;
    padding: 0.2vw;
}

#eMed_medication_maenChip {
    border-radius: 0.3vw;
    width: 2.4vw;
    height: 2.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75vw;
    font-weight: 600;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
}

#eMed_medication_maenChipTxt {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0.4vw;
    font-size: 0.78vw;
    font-weight: 600;
    color: var(--gray);
}

.eMed_medication_btnDiv {
    margin-top: 0.5vw;
}

#eMed_medication_btn {
    width: 13.90vw;
    text-transform: capitalize;
}

.eMed_medication_instruImg {
    width: 3.8vw;
    height: 3.8vw;
}

#eMed_medication_instruDiv {
    width: 27.5vw;
    height: 8vh;
    padding: 0 auto;
}

.eMed_medication_txtbox {
    display: flex;
    margin: 0.3vw;
}

.eMed_medication_durChipDiv {
    width: 27.5vw;
    height: 13vw;
    padding: 0.2vw;
}

#eMed_medication_durChip {
    border-radius: 0.3vw;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7vw;
    font-weight: 600;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
}

.eMed_medication_tapperDiv {
    width: 28vw;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.625vw auto;
}

.eMed_medication_tapperSubDiv {
    width: 27.5vw;
}

.eMed_Discharge_PaperBillView{
    width: 100%;
    height: 69vh;
    margin-bottom: 0vw;
}
.eMed_Discharge_txtbox{
    width: 42vw;
}

.eMed_nur_note_container {
    height: 74vh;
    background-color: #F5F8F7;
}

.eMed_nur_note_header {
    height: 11vh;
}

.eMed_nur_note_card {
    height: 62vh;
    margin-top: 0.3vw;
    display: flex;
    margin-left: 0.3vw;
}

.eMed_nur_note_left {
    flex: 0.6 1;
    height: 64vh;
}

.eMed_nur_note_right {
    flex: 0.4 1;
    height: 64vh;
}

.eMed_nur_notes_right_card {
    width: 36.5dvw;
    height: 64.5dvh;
    margin: 0.2dvw auto;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
}

.eMed_nur_notes_left_card {
    height: 64.5dvh;
    width: 55dvw;
    margin: 0.2dvw auto;
}

.eMed_nur_notes_grid {
    background-color: white;
}

.eMed_nur_notes_title {
    background-color: #F5F8F7;
    height: 5vh;
    padding: 0.4vw;
}

.eMed_nur_notes_content {
    height: 52.8vh;
    margin-left: 0.5vw;
    overflow: auto;
}

.eMed_nur_notes_btn_card {
    background-color: #F5F8F7;
    height: 5.5vh;
}

.eMed_nur_notes_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_nur_note_header {
    display: flex;
}

.eMed_Discharge_PaperView {
    width: 92vw;
    height: 69vh;
    background-color: white;
    margin: 1.5vw 0.5vw 0vw 0.8vw;
}

.eMed_IP_Discharge_Header {
    background-color: #FAF9F9;
    height: 7vh;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_IP_Discharge_Summary_Select {
    width: 73vw;
    padding: 0.3vw;
    display: flex;
    justify-content: space-between;
}

.eMed_room_model_content {
    margin: 1vw;
    display: flex;
    justify-content: center;
}

.eMed_IP_Discharge_BtnGroup {
    display: flex;
    justify-content: center;
}

.eMed_IP_Discharge_Select {
    width: 13vw;
}

.eMed_IP_Discharge_Select {
    width: 13vw;
}

.eMed_vital_Container {
    height: 65dvh;
    margin-top: 0.5dvw;
}

.eMed_vital_left {
    height: 65dvh;
}

.eMed_vital_table {
    height: 64dvh;
    width: 100%;
    background-color: white;
}

.eMed_vital_table1 {
    height: 62.5dvh;
    width: 100%;
    background-color: white;
}

.eMed_vital_right {
    height: 65dvh;

}

.eMed_vital_right_card {
    height: 64vh;
    width: 100%;
}

.eMed_vital_right_card1 {
    height: 62.5dvh;
    width: 100%;
}

.eMed_vital_right_txtbox {
    height: 52.5vh;
    overflow: scroll;
}

.eMed_vital_right_txtbox1 {
    height: 50.3vh;
    overflow: scroll;
}

.eMed_vital_more {
    min-width: 0vw !important;
    position: relative;
    color: #616161 !important;
    width: 0.5vw;
}

.eMed_SN_Con {
    width: 92.5vw;
    height: 70vh;
    overflow: auto;
    margin: 1.5vw 0.5vw 0vw 0.8vw;
}
.eMed_SN_IpCon{
    width: 100%;
    height: 70vh;
    overflow: auto;
    margin: 1.5vw 0.5vw 0vw 0vw;
}

.eMed_SN_Acc_Header {
    flex-direction: row-reverse;
    display: flex;
}

.eMed_SN_Acc_HeadBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 89vw
}

.eMed_SN_Acc_HeadText {
    display: flex;
    align-items: center
}

.eMed_SN_Acc_HeadLay {
    width: 89vw;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1vw;
}

.eMed_SN_Acc_billHeadLay {
    width: 100%;
}

.eMed_SN_NoLayout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41vh;
}

.eMed_SN_Sign_View {
    margin: 2vw 1vw 1vw 1vw;
    width: 87vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_SN_Add_Btn {
    margin-top: 1vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5vw;
}

.eMed_nur_notes_details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 0.8vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    padding: 0.2vw;
}

.eMed_patient_nur_notes {
    margin-left: 0.75vw;
}

.eMed_history_nurse_grid {
    height: 22vw;
    width: 50vw;
}

.eMed_vital_grid_history {
    height: 22vw;
    width: 70vw;
}

.eMed_table_empty {
    position: relative;
    top: 8vw;
}

.eMed_DisSum_Anaesthsia {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1vw;
}

.eMed_text_room_change{
    display: flex;
    flex-direction: column;
    padding: 1vw;
}

.eMed_text_box_room{
    margin: 1vw;
}

.eMed_room_canceled{
    background-color: gray;
}

.eMed_medication_head{
    width: 100%;
    display: flex;
    flex: 1 1;
}
#eMed_medication_headTxt {
    font-weight: 600;
    font-size: 0.9vw;
    color: #616161;
}
#eMed_medication_rtnTbl{
    max-height: 40vh;
}
.eMed_medication_Img{
    width: 2vw;
    height: 2vw;
}
.eMed_Medication_arrowImg{
    width: 0.9vw;
    height: 1vw;
}
#eMed_Medication_tableTxt{
    font-weight: 600;
    color: #616161;
    font-size: 0.9vw;
    background-color: #F5F8F7 !important;
    padding: 0.5vw !important;
}
.eMed_Medication_tableDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}
.eMed_medicationCard_header{
    height: 6vh;
    margin-bottom: 0.8vw;
}

#eMed_Serivce_Search{
    cursor: pointer; 
    border-left: 1px solid #E3E2E2;
    width: 3vw; 
    height: 2vw;
    display: flex;
    justify-content: center;
    margin-right: -0.9vw
}

.eMed_op_Bill_list{
    height: 14vw;
    overflow: scroll;
}
.eMed_pain_left_card {
    /* width: 60.5dvw; */
    width: 100%;
    margin: 0.3dvw auto;
    height: 64dvh;
    margin-top: 0.5dvw;
}
.eMed_pain_right_card {
    height: 64dvh;
    /* width: 30dvw; */
    width: 100%;
    /* margin-left: 1.5dvw; */
    margin-top: 0.5dvw;

}
.eMed_pain_rgt_title{
    background-color: #F5F8F7;
    width: 30vw;
    height: 2.5vw;
    padding: 0.5vw;
}
.emedhub_auto_complate {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* margin: 0.625dvw 0dvw; */
}
.emedhub_pain_level {
  width: 13dvw;
}
.emedhub_Nurse_list {
  width: 13dvw;
  margin-left: 0.5dvw;
}
.emedhub_pain_button {
    background-color: var(--white);
    padding: 0.5vw;
    margin: 0 auto;
}
.emedhub_pain_sub_container {
    height: 51vh;
}
.emedhub_toolbar_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -0.7vw;
}
.emedhub_pain_chart_main {
    height: 30vh;
    width: 20vw;
}
.emedhub_pain_range {
  height: 1.2vw;
  width: 1.2vw;
}


.eMed_insulin_left_card{
    flex:0.65 1;
    height: 65vh;
    overflow-y: scroll;
}

.eMed_insulin_right_card {
    flex: 0.35 1;
    height: 65vh;
    overflow-y: scroll;
}

.eMed_bill_Tab{
    min-height: 0vw !important;
    height: 2.5vw !important;
}

.eMed_DisSum_Copy_popMain {
    position: absolute;
    top: 42%;
    left: 42%;
    transform: translate(-40%, -40%);
    width: 70vw;
    height: 83vh;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
}

.eMed_DisSum_Copy_head_Box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}

.eMed__DisSum_Copy_BtnGr {
    margin: 1vw;
    display: flex;
    align-items: center;
}

.eMed_drugRight_txtDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.eMed_drugMenu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_drugMenu_item {
    width: 1dvw;
    height: 1dvw;
    margin-right: 0.5dvw;
}

.eMed_ipop_qtyDiv {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 0px;
}

.eMed_ipop_right_txt {
    font-weight: 600;
    color: #616161;
    margin: 0dvw 0.5dvw !important;
    font-size: 0.9dvw;
}

.eMed_nutri_txtDiv {
    width: 100%;
    padding: 0.5dvw !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1dvw;
    gap: 1dvw;
}

.eMed_drug_status_start {
    background-color: #E3FFE6;
}

.eMed_drug_status_stop {
    background-color: #FFE3E3;
}

.eMed_drug_status_hold {
    background-color: #FFF8BC;
}

.eMed_drug_status_Completed{
    background-color: #FFFFFF;
}

.eMed_ipop_txtDiv {
   margin: 0dvw 0.5dvw 0.2dvw 0.5dvw !important;
}

/* text editor header styles */

.discharge_Sum_outer_container{
    margin-bottom: 20px; /* Space between sections */
    padding: 10px;
    background-color: #f9f9f9; /* Light background for content sections */
    border: 1px solid #ddd; /* Border around each section */
}

.discharge_Sum_inner_container{
    width: 100%;
    margin-bottom: 5px; /* Space between sections */
    padding: 5px;
    background-color: #f9f9f9; /* Light background for content sections */
    border: 1px solid #ddd; /* Border around each section */
}

/* Sticky header for each section */
.discharge_Sum_header_container {
    position: sticky;
    top: 0; /* Sticks to the top within the parent container */
    background: white; /* Ensure header background is opaque */
    z-index: 10; /* Keep header above other content */
    padding: 5px;
    border-bottom: 1px solid #ccc; /* Line below the header */
    font-weight: bold; /* Make header text stand out */
  }

  .last_image {  
    border-right: none; 
  } 
.eMed_DocNotes_Left_Main{
    flex:0.65 1;
    height: 64dvh;
    margin: 0.5dvw 0.5dvw 0.5dvw 0.8dvw;
    overflow-y: scroll;
}

.eMed_DocNotes_Right_Main{
    flex: 0.35 1;
    margin: 0.5dvw;
    height: 64dvh;
    width:32dvw
}

.eMed_DocNotes_DateTime{
    background-color: 'red';
    height: 5vw;
    margin-top: 1vw;
    margin-left: 0.5vw;
    width: 16vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.emed_Duration_head{
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1vw;
    background-color: #FAF9F9;
    font-weight: 600 !important;
    padding-right: 1vw;
}

.eMed_DocNotes_Duration_Warpper{
    display: flex;
    flex-wrap: wrap;
    padding: 0.5vw;
    padding-left: 2vw;
}
.eMed_DocNotes_Duration_Counts{
    margin: 0.4vw;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.2vw;
    cursor: pointer;
    border: 1px solid ;
}

.eMed_DocNotes_FreqChips_Wrapp{
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    display: flex;
    flex-wrap: wrap;

}

.eMed_DocNotes_FreqChip{
    margin: 0.2vw !important;
    border-radius: 0.3vw !important;
    height: 4.5vh !important;
    font-size: 0.9vw !important;
}

.eMed_DocNotes_TextFields{
    width: 28vw !important;
    margin-left: 1.5vw !important;
    margin-top: 0.5vw !important;
    margin-bottom: 0.5vw !important;
}

.eMed_DocNotes_icd_TxtBox{
    width: 28vw;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 2vw;
    margin-top: 1.5vw;
}

.eMed_DocNotes_ProvDiag_TextField{
    width: 52vw !important;
    margin-left: 1.5vw !important;
    margin-top: 0.5vw !important;
    margin-bottom: 0.5vw !important;
}

.eMed_DocNotes_nodata {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_DocNotes_datePic_Textfield {
    margin-top: 1.5vw !important;
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
    width: 15vw !important;
}

.eMed_DocNotes_Txt {
    font-weight: 600;
}

.eMed_DocNotes_DateTxt {
    font-size: 0.85dvw;
}

.eMed_WrtNotes_Cont {
    height: 100%;
    padding: 0px 12px;
}

.eMed_WrtNotes_docDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_WrtNotes_lblCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_WrtNotes_docImg {
    width: 3dvw;
    height: 3dvw;
    border-radius: 50%;
}

.eMed_IPDoc_fltTxtCont {
    width: 20dvw;
    margin: 0.5dvw;
}

.eMed_IPDoc_FilterBox {
    width: 28dvw;
    margin-top: 5dvw;
}

.eMed_IPDoc_fil_content {
    width: 100%;
    height: 74dvh;
    margin: 0.5dvw;
}

.eMed_IPDoc_Date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_IPDoc_fil_text {
    width: 26dvw;
}

.eMed_IPDoc_BtnText {
    width: 6dvw;
    text-transform: capitalize !important;
}

.eMed_IPDoc_printBtn {
    height: 2dvw;
    width: 4.8dvw;
    text-transform: capitalize;
}

.eMed_DocNotes_durChip_Wrapp{
    padding: 1vw;
    display: flex;
    flex-wrap: wrap;
}
.eMed_Pat_Bill_Container{
    height: 74.5vh;
}

.eMed_Pat_Bill_Header{
    height: 8.5vh;
}

.eMed_Pat_Bill_content{
    height: 65vh;
}

.eMed_Pat_Bill_Table{
    width: 92vw !important;
    margin: 0.5vw auto;
    height: 30.5vw;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow-y: scroll;
}

.eMed_Pat_Bill_Tbl_Header{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
    padding: 0.5vw !important;
    color: #616161 !important;
}

.eMed_Credit_bill_pop_Tbl_Header{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
    padding: 0.1vw !important;
    color: #616161 !important;
}

.eMed_Credit_bill_pop_Tbl_Body{
    padding: 0.5vw !important;
}

.eMed_Pat_Bill_Tbl_Two{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.eMed_Pat_Tbl_one_style{
    padding: 0.5vw 0.5vw 0.5vw 0.5vw !important;
}

.eMed_Pat_tbl_two_btn{
    min-width: 0vw !important;
    width: 2vw;
    height: 2vw;
}

.eMed_Pat_Bill_img{
    width:1.5vw;
    height: 1.5vw;
}

.eMed_Pat_Bill_more {
    min-width: 0vw !important;
    position: relative;
    color: #616161 !important;
    width: 0.5vw;
}
.eMed_bill_View_card {
    height: 60vh;
    background-color: white;
    width: 50vw;
    box-shadow: 0px 0px 1px gray;
    /* margin-top: 8vw !important; */
}
.eMed_bill_view_table {
    height: 40vh !important;
}
.eMed_bill_view_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 20vw;
    margin-right: 1vw;
}
.eMed_billview_table_footer {
    display: flex;
    border-top: 1px solid lightgray;
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
}

:root {
    --gray: #616161;
    --white: #FFFFFF;
    --fill: #E4F2F0;
}
#eMed_AdHistory_Container {
    height: 80vh;
    padding: 0.625vw;
}
.eMed_AdHistory_Box {
    height: 78vh;
}
.eMed_AdHistory_header{
    height: 6.5vh;
}
.eMed_AdHistory_Div {
    width: 100%;
    height: 70vh;
    background-color: var(--white);
    overflow-y: auto;
}
#eMed_AdHistory_Accord{
    margin-bottom: 0.7vw;
    border-radius: 0.3vw;
}
#eMed_AdHistory_AccordTxt{
    font-weight: 600;
    font-size: 0.9vw;
    color:var(--gray);
    margin-left: 0.7vw;
}
#eMed_AdHistory_AccordDetails{
    height: -moz-fit-content;
    height: fit-content;
}  
.eMed_AdHistory_Accord_MainTbl{
    height: 33vh;
}
.eMed_AdHistory_Accord_SubTbl_Div{
    height: 30vh;
}
.eMed_AdHistory_AccordTbl {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.emed_billMedication_Box {
    height: 73vh;
}

.eMed_pd_maintable {
    height: 71vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Table_top_btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_Emp_billing_left{
    /* width: 60%; */
    height: 63vh;
    margin-left: 1vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    background-color: white;
}

.eMed_Emp_billing_right{
    /* width: 40%; */
    height: 63vh;
    margin-left: 1vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    background-color: white;
}

.eMed_Emp_Billing_Table{
    /* width: 92vw !important; */
    height: 63vh !important;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow: scroll;
}
.eMed_Return_Billing_Table{
    /* width: 92vw !important; */
    height: 55vh !important;
    background-color: white;
    box-shadow: 0px 0px 1px light;
    border: 1px solid lightgray;
    overflow: scroll;
}

.eMed_emp_billing_Popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 30vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_emp_billing_Popup_Top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_EmpBill_SearchTextBox{
    display: flex;
    align-items: flex-end;
    width: 15vw;
    padding-bottom: 1vw;
}

.eMed_emp_bill_split_tableBody{
    max-height: 15vw !important;
}

.eMed_Corp_Card_Con{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 7vw;
}

.eMed_Corp_Card_Wrap{
    width: 40%;
    display: flex;
    justify-content: space-between;
}
#eMed_Ip_Est_card{
    display: flex;
    height: 13vh;
}
.eMed_Ip_Est_AmtCrd{
    width:80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.eMed_Ip_Est_fltDiv{
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}
#eMed_Ip_Est_head_card{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1.5vw;
}
.eMed_Ip_Est_head_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}
.eMed_Ip_Est_ptCard {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    margin-right: 0.1vw;
    overflow-wrap: break-word;
}
.eMed_Ip_Est_image {
    width: 2.8vw;
    height: 2.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDFFFE;
    border-radius: 0.3vw;
    margin-right: 0.5vw;
}
.eMed_Ip_Est_img{
    width: 1.8vw;
    height: 1.8vw;
}
.eMed_Ip_Est_details {
    flex-direction: row;
    display: flex;
    width: 17vw;
    height: 2.8vw;
    justify-content: space-between;
}
.eMed_Ip_Est_mainDiv{
    height: 67vh;
    width: 100%;
    background-color: white;
    padding: 0.65vw;
}
#eMed_Ip_Est_txt{
    font-size: 0.9vw;
    color: #616161;
    font-weight: 600;
}
.eMed_Ip_Est_btn{
    width: 1.5vw;
    height:1.5vw;
    border-radius: 50%;
    margin-right: 0.5vw;
}
.eMed_Ip_Est_Tbl{
    height: 55vh;
}
#eMed_Ip_Est_txt1{
    font-size: 0.9vw;
    font-weight: 600;
}
#eMed_Ip_Est_txt2{
    font-size: 0.8vw; 
    font-weight: 600;
}
.eMed_Ip_Est_txtbox {
    display: flex;
    margin: 0.3vw;
}
.eMed_Ip_Est_date_filter{
    width: 15vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 6vw;
    margin-bottom:1.5vw;
}
.eMed_IP_Est_printimg{
    height: 1.5vw;
    width: 1.5vw;
}
.eMed_IP_Est_Search_Pop{
    margin-top: 20vh;
    margin-left: 5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
  }
  .eMed_IP_Est_Search_item{
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
    align-items: center;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 4vw;
  }
  .eMed_IP_Est_Dialogbox {
    padding: 15px;
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#eMed_expConfig_Container {
    margin-top: 1.5vw;
    padding: 0 0.625vw 0 0.625vw;
}
.eMed_expConfig_Box {
    height: 80vh;
}
.eMed_expConfig_Div {
    height: 75vh;
}
.eMed_expConfig_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.eMed_expConfig_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}
.eMed_expConfig_SubDiv {
    height: 65vh;
    padding: 0.5vw;
}
.eMed_expConfig_txtbox{
    margin: 1vw 0.625vw ;
}
#eMed_expConfig_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}
.eMed_Pharma_Billing_container {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_Pharma_Header_Card {
    /* border-bottom: 1px solid rgb(203, 191, 191); */
    border-bottom: 1px solid lightgray;
    /* box-shadow: 0px 2px #e3e0e0; */
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6vh;
}

.eMed_Phrama_Table_Container{
    border: 1px solid lightgray;
    height: 35vh;
    width:92.3vw !important;
    background-color: white;
    margin: auto;
}

.eMed_Pharma_tbl_header{
    color: #616161 !important;
    font-size: 0.85vw !important;
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.eMed_Pharma_del_btn{
    min-width: 0vw !important;
    height: 1.5vw !important;
    width: 1.5vw !important;
}

.eMed_Pharma_payment_card{
    height: 1.5vw;
    display: 'flex';
    align-items: center;
    position: sticky;
    background-color: #ffffff !important;
}

.eMed_Drug_Sugg_Pop{
    width:70vw;
    max-height:40vh;
}

#eMed_Pres_pop_NORec{
    text-align: center;
    position: relative;
    top: 10vh;
}
.eMed_Pres_DataView{
    width: 28.5vw;
    height: 55vh;
    overflow: scroll;
}
.eMed_App_DataView{
    width: 28.5vw;
    height: 48vh;
    overflow: scroll;
}
.eMed_Pres_Header{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5vw;
}
.eMed_Saved_DataView{
    width: 39vw;
    height: 55vh;
    overflow: scroll;
}

.eMEd_pharma_Home_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.9vw;
    padding-right: 0.9vw;
}

.eMed_pharma_header_icon_img{
    width: 1.5vw;
    height: 1.5vw;
}

.eMed_pharma_header_icon_warp {
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #EDFFFE; */
    border-radius: 0.3vw;
    margin-right: 0.5vw;
}

.eMed_Pharma_Mobile_Search_Pop{
    margin-top: 22vh;
    margin-left: 5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 24vw;
  }

.eMed_Pharma_IPOP_Search_Pop{
    margin-top: 22vh;
    margin-left: 25.5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 23vw !important;
  }

  .eMed_Presc_History_pop_main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 40vw;
    background-color: white;
    border-radius: 5px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.eMed_Presc_History_pop_table{
    width: 58vw;
    height: 25vw;
    /* margin-left: 1vw; */
    background-color: white;
}

.eMed_DrugBox_Scheduled{
    background-color: rgb(245, 231, 231) !important;
    border-color: rgb(150, 13, 11) !important;
    border-radius: 0.4vw !important;
    border-width: 1 !important;
}

.eMed_ReturnCredit_Bill_Label{
    font-size: 1vw !important;
    color: red !important;
    font-weight: 600 !important;
}

.eMed_Pharmabill_Header_Card {
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 7dvh;
}

.eMed_Pharmabill_Billing_container {
    width: 100%;
    height: 81dvh;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_Pharmabill_Table_Container {
    border: 1px solid lightgray;
    height: 34.8dvh;
    width: 92.3dvw !important;
    background-color: white;
    margin: auto;
    margin-top: 0.2dvw;
}

.eMed_Pharmabill_tbl_footer {
    display: flex;
    justify-content: space-between;
    width: 22dvw;
    margin-right: 1dvw;
}
.eMed_Purcahse_Table_Container{
    height: 70vh;
}

.eMed_Purchase_Table_Wrapper {
    width: 92vw;
    height: 70vh;
    margin-left: 1vw;
    background-color: white;
}

.eMed_Purchase_Table_top{
    height: 9vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.1vw;
    padding-left: 2vw;
    align-items: center;
}

.eMed_AddVendor_Grid_row{
    padding: 0.5vw;
    height: 9vh;
}
.eMed_Add_vendor_page_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 8vh;
    align-items: center;
    padding-right: 3vw;
}
.eMed_Add_vendor_page_top2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20vw;
}

#eMed_pharmadrctpo_Container {
    width: 100%;
    margin-top: 1.5vw;
    padding: 0 0.5vw;
}

#eMed_pharmartnDrctpo_Container {
    width: 100%;
}

#eMed_pharmadrctpo_mainDiv {
    height: 80vh;
    background-color: #f5f8f7;
}

#eMed_pharmartnDrctpo_mainDiv {
    height: 75vh;
}

#eMed_pharmadrctpo_paper1 {
    height: 15vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 0.5vw;
    margin-bottom: 1.5vw;
}

#eMed_pharmadrctpo_viewSkDiv {
    height: 13vh;
    width: 93.5vw;
    background-color: #FFFFFF;
    padding: 0.5vw;
    margin-bottom: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

#eMed_pharmartnDrctpo_paper1 {
    height: 15vh;
    border-radius: 0.3vw;
    background-color: #FFFFFF;
    padding: 0.4vw;
    margin-bottom: 1vw;
}

.eMed_pharmadrctpo_subDiv {
    display: flex;
    justify-content: flex-start;
    height: 7vh;
}

.eMed_pharmadrctpo_txt {
    margin: 0.5vw;
}

#eMed_pharmadrctpo_table {
    height: 57vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}

#eMed_pharmartnDrctpo_table {
    height: 54vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
}

.eMed_pharmadrctpo_tblhead {
    display: flex;
    flex: 1 1;
}

#eMed_pharmadrctpo_tblheadTxt {
    background-color: #faf9f9;
    color: #616161;
    font-weight: 600;
    font-size: 0.8vw;
    padding: 0.5vw 0.5vw !important;
}

#eMed_pharmadrctpo_tblbodyTxt {
    font-size: 0.9vw;
    font-weight: 600;
}

#eMed_pharmadrctpo_tblTxt {
    color: #66686A;
    font-size: 0.75vw;
}

#eMed_pharmadrctpo_Txtclr {
    color: #66686A;
    padding: 0.5vw 0.5vw !important;
}

#eMed_pharmartnDrctpo_rhtDiv {
    height: 72vh;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}

#eMed_pharmadrctpo_rhtDiv {
    height: 75vh;
    border-top-left-radius: 0vw;
    border-top-right-radius: 0.3vw;
    border-bottom-left-radius: 0vw;
    border-bottom-right-radius: 0vw;
}

.eMed_pharmadrctpo_tileBlk {
    background-color: #E4F2F0;
    border-radius: 0.3vw;
    padding: 0.3vw;
    height: 4vw;
    margin-top: 0.6vw;
}

#eMed_pharmadrctpo_tabDiv {
    background-color: #E4F2F0;
    height: 5vh;
    margin-bottom: 0.2vw;
}

#eMed_pharmadrctpo_stsTxt{
    font-size: 0.9vw;
    color: #FF9900;
}

#eMed_pharmadrctpo_txtbox {
    margin: 0.7vw;
}

#eMed_pharmadrctpo_rhtSubDiv {
    height: 59vh;
    overflow: scroll;
}

.eMed_pharmadrctpo_rhtHead {
    background-color: #faf9f9;
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_pharmadrctpo_rhtHead_txt {
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.eMed_pharmadrctpo_rhtCntBox {
    background-color: #FFFFFF;
    padding: 1vw;
}

#eMed_pharmadrctpo_rhtCntBox1 {
    max-height: 53vh;
}

#eMed_pharmadrctpo_rhtCntBox2 {
    max-height: 95vh;
}

.eMed_pharmadrctpo_rhtCnSubDiv {
    display: flex;
    justify-content: space-between;
}

.eMed_pharmadrctpo_rhtCnt_TleBlk {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#eMed_pharmadrctpo_rhtBtnCon {
    background-color: #faf9f9;
    height: 4.8vh;
    padding: 0.625vw;
}

#eMed_pharmadrctpo_btcBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    max-height: 30vw;
    padding: 3vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}
.eMed_Add_vendor_form_box{
    height: 74vh;
    overflow: scroll;
}

.eMEd_Add_Vendor_Form_Headtext{
    font-weight: 600;
    /* padding-left: 1vw; */
}

.eMed_add_vendor_dropdown_inputs{
    width: 95%;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
}

.eMed_PharmacfrmOrd_mainDiv{
    width: 100%;
}
#eMed_PharmacfrmOrd_subDiv1{
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    max-height: 16.98vh;
    padding:0.625vw;
}

.eMed_PharmaVendor_mainBox {
    width: 100%;
    height: 85vh;
    background-Color: #f5f8f7;
    padding: 0.625vw;
}

.eMed_PharmaVendor_paper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 14vw;
}

#eMed_PharmaVendor_headTxt {
    font-size: 0.75vw;
    color: #66686A;
}

#eMed_PharmaVendor_Txt {
    font-weight: 600;
}

#eMed_PharmaVendor_Table {
    height: 44vh;
    background-color: #FFFFFF;
}

#eMed_PharmaVendor_popupBox {
    width: 55vw;
    max-height: 60vw;
    padding: 0.5vw;
    background-color: #FFFFFF;
}

#eMed_PharmaVendor_popHeadTxt {
    font-weight: 600;
}

#eMed_PharmaVendor_popNorTxt {
    color: #66686A;
}

.eMed_OrdTxtField input[type="number"]::-webkit-inner-spin-button,
.eMed_OrdTxtField input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

#eMed_pharmaReturnPO_table {
    height: 50vh;
}

.eMed_date_filter_vendor{
    width: 15vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 8vw;
    margin-bottom:1.5vw;
}

.eMed_PO_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 40vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}

.eMed_Pharma_rts_header{
    height: 15vh;
    padding: 1vw 0vw;
    display: flex;
}

.eMed_Pharma_rts_tbl_card{
    height: 65vh;
}

.eMed_Pharma_table{
    background-color: white;
    width: 92vw;
    margin: auto;
}

.eMed_Pharma_rts_btn{
    min-width: 0vw !important;
    width: 2.8vw !important; 
    height: 2vw !important;
    margin-right: 0.5vw !important;
}

.eMed_Pharma_rts_actionimg {
    width: 2.3vw;
    height: 2.3vw;
}
.eMed_pharma_bill_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 30vw;
    margin-right: 1vw;
    margin-top: 0.25vw;
}
.eMed_recPayment_Div {
    height: 67vh;
    margin-top: 0.65vw;
}
.emedhub_recPayment_RightContentBox {
    background-color: var(--white);
    padding: 0.45vw;
    margin-left: 0.4vw;
}
.eMed_pharma_settings_Main{
    height: 75vh;
    border-radius: 5px;
    /* box-shadow: 0px 0px 2px gray; */
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.eMed_pharma_settings_option_Main{
    height: 6vh;
    width: 95%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1vw;
}

.eMed_pharma_settings_option_action{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
}

#eMed_pharma_settings_stkTbl {
    width: 100%;
    height: 47vh;
}

.eMed_pharma_usrconfig_container {
    height: 80vh;
    background-color: #F5F8F7;
    margin: 0.625vw;
}

.eMed_pharma_usrconfig_tbl {
    height: 77vh;
}
.eMed_generic_editDiv {
    width: 100%;
    height: 38vh;
    overflow-y: scroll;
}

#eMed_generic_chipDiv {
    width: 98%;
    height: 25vh;
    margin: 0.1vw auto;
    overflow-y: scroll;
}

.eMed_generic_dropdown {
    width: 20vw;
    margin-bottom: 1vw;
}

#eMed_generic_chip {
    border-radius: 0.3vw;
    margin: 0.3vw 0.5vw 0.3vw 0vw;
    height: 2vw;
}
.eMed_sublocation_add {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 33vw;
    height: 50vh;
    background-color: white;
    border-radius: 10px;
}

.eMed_subloc_con{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
}
.eMed_subloc_conSub{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
    width: 23vw;
}

.eMed_SubLoc_patBill{
    border: 1px solid #888888;
    border-radius: 0.3vw;
    display: flex;
    flex-direction: row;
    margin-top: 0.5vw;
}

.eMed_SubLoc_VRLine{
    border-right: 1px solid #888888;
    height: 2.3vw;
    margin-right: 1vw;
    display: flex;
    align-self: center;
}

.eMed_stock_edit_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65vw;
    background-color: white;
    border-radius: 10px;
}

.eMed_active_view_textfield {
    padding: 0.625vw;
}

.eMed_stock_edit_radio_card {
    margin: 0.7vw;
    height: 5vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    padding: 0.5vw;
}

.eMed_stock_edit_input_card {
    margin: 0.625vw;
    height: 7vw;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
}

.eMed_stock_edit_middle_card {
    border-radius: 5px;
    margin: 0.625vw;
    min-height: 17vw;
    max-height: 20vw;
    box-shadow: 0px 0px 2px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
}

.eMed_active_view_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.image_card {
    margin-right: 0.5vw;
    padding-left: 1vw;
    height: 2.2vw;
    cursor: pointer;
}

.eMed_pharmaAdd_Container {
    padding: 0.625vw;
    height: 80vh;
    background-color: #faf9f9;
}

.eMed_pharmaAdd_mainBox {
    height: 70vh;
    margin: 0.625vw;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}

.eMed_pharmaAdd_subDiv {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    align-items: center; */
    margin: 1.5vw 0.5vw;
}

#eMed_pharmaAdd_headTxt {
    color: #616161;
    font-weight: 600;
    text-align: left;
    padding: 0.625vw;
    border-bottom: 1px solid lightgray;
}

#eMed_pharmaAdd_btnCont {
    height: 8vh;
    padding: 0.625vw;
    margin-top: 3vw;
    margin-right: 3vw;
}

.eMed_pharmaAdd_txtBox {
    width: 17vw;
}

.eMed_pharmaUpdatePop_Con {
    min-height: 14vw;
}

.eMed_pharmaUpdatePop_subDiv {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    align-items: center; */
    margin: 0.5vw;
}

.eMed_pharmaUpdatePop_txtBox {
    width: 14vw;
}

#eMed_pharmaUpdatePop_btnCont {
    height: 5vh;
    padding: 0.625vw;
    margin-right: 0.625vw;
}

.eMed_pharmaRtnStk_container {
    height: 85vh;
}

.eMed_pharmaRtnStk_Head {
    height: 8vh;
    margin: 0.625vw;
}

#eMed_pharmaRtnStk_txt {
    color: #616161;
}

.eMed_pharmaRtnStk_content {
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_pharmaRtnStk_rtncont {
    height: 70.9vh;
}

.eMed_pharmaRtnStk_rtnDiv {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#eMed_pharmaRtnStk_rtnBtnCon {
    background-color: #faf9f9;
    height: 5vh;
    padding: 0.625vw;
}

.eMed_pharma_box{
    background-color: white;
    height: 70vh;
    box-shadow: 1px 1px 3px lightgray;
    border-radius: 10px;
}

.eMed_view_popup {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
  }
.eMed_SubLoc_DrugBox_Scheduled{
    background-color: rgb(245, 231, 231) !important;
    border-color: rgb(150, 13, 11) !important;
    border-radius: 0.4vw !important;
    border-width: 1 !important;
}

.eMed_subLocCrdt_Box {
    width: 100%;
    height: 60vh;
}

.eMed_subLocCrdt_headBox {
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.625vw;
}

#eMed_subLocCrdt_viewTbl {
    width: 100%;
    height: 45vh;
    margin-bottom: 0.625vw;
}

.eMed_Sub_Loc_Rpt_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    height: 30vw;
    background-color: white;
    overflow: scroll;
}
.eMed_common{
    min-width: 0vw !important;
}

#emedhub_Transit_mainBox{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25vw;
    max-height: 40vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
    outline: none;
}

.emedhub_transit_img{
    width: 1vw;
    height: 1vw;
    border-radius: 0.3vw;
}
 
.eMed_batch_delete {
    margin-left: 0.5vw;
    display: flex;
    align-self: center;
    cursor: pointer;
    width: 1vw;
    height: 1vw
}
:root{
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}

.eMed_OT_Main_Container{
    width: 100%;
    height: 100%;
    margin-top: -0vw;
}

.eMed_OT_page_header{
    border-bottom: 1px solid var(--headBorder);
    box-shadow: 0px 2px var(--headboxShadow);
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    height: 8vh;
}

.eMed_Price_Card_Icon {
    display: flex;
    flex-direction: row;
    width: 25vw;
    align-items: center;
}

.eMed_IP_AptDateFlt {
    width: 13vw;
    position: relative;
    top: 0.36vw;
}

.eMed_OT_Apt_Cards {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.eMed_OT_APtTbl_PayPending {
    margin-left: 0.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_OT_AptTbl_PayIcon {
    height: 0.9vw;
    width: 1vw;
    margin-right: 0.3vw
}

#eMed_OT_AptTbl_PayText {
    font-size: 0.9vw;
    font-weight: 600;
}

.rendercell{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.eMed_TestConfig_MainContainer {
  width: 100%;
  height: 80vh;
  background-color: #f5f8f7;
  margin-top: 1vw;
}

.eMed_TestConfig_Con {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eMed_TestConfig_leftSide {
  width: 84.6vw;
  height: 74vh;
  background-color: white;
  margin: 1vw;
  border-radius: 0.3vw;
}

.eMed_TestConfig_RightSide {
  width: 44.9vw;
  height: 74vh;
  background-color: white;
  margin: 1vw;
  border-radius: 0.3vw;
}

.eMed_TestConfig_RightHead {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  display: flex;
  align-items: center;
}

.eMed_Testconfig_RightHead_text {
  font-weight: bold;
  margin-left: 1.5vw;
  font-size: 1vw;
  color: #616161;
}

.eMed_TestConfig_RightContentBox {
  height: 59.1vh;
  overflow: scroll;
  position: relative;
}

.eMed_TestConfig_RightBtnCon {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0vw 0vw 0.3vw 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eMed_TestConfig_LeftPageination {
  background-color: #faf9f9;
  height: 7.3vh;
  border-radius: 0vw 0vw 0.3vw 0.3vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.eMed_TestConfig_RightTestName {
  margin: 1vw;
  margin-bottom: 0;
  /* width: 28vw; */
  display: flex;
  flex-direction: column;
}

.eMed_TestConfig_RightMethod {
  margin: 1vw;
  width: 29vw;
  margin-bottom: 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_TestConfig_RightHead2 {
  background-color: #faf9f9;
  height: 7.3vh;
  display: flex;
  margin-top: 1vw;
  align-items: center;
}

.eMed_ConfigTable_Paper {
  height: 61vh;
}

#eMed_config_TableRow {
  background-color: #faf9f9;
  font-weight: bold;
  height: 1vh;
  font-size: 0.9vw;
  color: #616161;
}

#eMed_config_TableRow1 {
  background-color: #faf9f9;
  font-weight: bold;
  height: 1vh;
  border-radius: 0.3vw 0 0 0;
  font-size: 0.9vw;
  color: #616161;
}

.eMed_TestConfig_Right_SideTable {
  margin: 1vw;
}

.eMed_ConfigRightTable_Paper {
  height: 32vh;
  overflow: scroll;
  border-style: 3px solid #c9c1c1;
  border-radius: 0.3vw;
}

.eMed_TestConfig_ImpressionCon {
  height: auto;
  margin: 1vw;
  border: 1px solid #e3e2e2;
  border-radius: 0.3vw;
}

.eMed_TestConfig_ImpressionHeadCon {
  background-color: #faf9f9;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  margin-top: 0vw;
  height: 5vh;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e2e2;
}

.eMed_TestConfig_ImpressionText {
  font-weight: 600;
  margin-left: 1vw;
  color: #616161;
}

.eMed_TestConfig_ImpressionAdd {
  margin: 1vw;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.eMed_TestConfig_Addpara {
  display: flex;
  justify-content: center;
}

.eMed_TestConfig_RightMethodAddBtn {
  margin: 1vw;
  width: 29vw;
  margin-bottom: 0vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.eMed_TestConfig_NoRecords {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23vh;
}

.eMed_TestConfig_ParaHeadCon {
  background-color: #faf9f9;
  border-radius: 0.3vw 0.3vw 0vw 0vw;
  margin-top: 0vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e2e2;
}

.eMed_TestConfig_CrossIcon {
  display: flex;
  align-self: flex-end;
  margin-right: -0.5vw;
  z-index: 1300;
  margin-bottom: -0.7vw;
  height: 1vw;
  cursor: pointer;
}

.eMed_TestConfig_HeaderCross {
  margin-right: 1vw;
  height: 1.3vw;
  cursor: pointer;
}

.eMed_TestConfig_EditIcon {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 0.5vw;
  cursor: pointer;
}

.eMed_TestConfig_DeleteIcon {
  width: 1.3vw;
  height: 1.3vw;
}

.eMed_PatientDemo_Name {
  font-weight: 600;
  font-size: 1vw;
}

.eMed_PatientDemo_Address1 {
  font-size: 0.6vw;
  color: #616161;
  margin-top: -1vw;
}

.eMed_PatientDemo_Address2 {
  font-size: 0.6vw;
  color: #616161;
  margin-top: -0.7vw;
}

.eMed_Patient_Country {
  width: 14vw;
  height: 2.6vw;
  border-color: #bbb9b9;
  color: #888888;
  border-radius: 0.3vw;
}

#eMed_Btn_Text {
  text-transform: capitalize;
}

.eMed_warning {
  display: flex;
  justify-content: space-between;
}

.eMed_imp_txt {
  display: flex;
  flex-direction: column;
}

.eMed_Testconfig_AddIcon {
  width: 1.5vw;
  height: 1.5vw;
}

.eMed_Search_Box_ {
  display: flex;
  justify-content: end;
  padding-top: 0.7vw;
}

.eMed_Main_Tbl_card {
  border: 1px solid lightgray;
  margin: 0.5vw;
  width: 90vw !important;
}

.eMed_Main_Tbl_head {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  background-color: #f5f8f7 !important;
  color: #616161 !important;
}

.eMed_Lab_act_btn {
  min-width: 0vw !important;
}

.eMed_Lab_Rst_Tbl_Card{
  background-color: white;
  height: 78vh;
  width: 92.6vw;
  border: 1px solid lightgray;
}

.eMed_Lab_Rst_Tbl_Container{
  height: 77.5vh;
  border: 1px solid lightgray;
  background-color: white;
}

.eMed_Tbl_rst_Cell_One{
  border-right: 1px solid lightgray;
  width: 10vw;
}

.eMed_Tbl_rst_Cell_Two{
  border-right: 1px solid lightgray;
  width: 18vw;
}

.eMed_Tbl_rst_Cell_Three{
  border-right: 1px solid lightgray;
  width: 12vw;
}

.eMed_Tbl_rst_Cell_four{
  border-right: 1px solid lightgray;
  width: 15vw;
}

.eMed_lab_rest_New_Popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45vw;
    max-height: 40vw;
    background-color: white;
    /* border-radius: 8px; */
    overflow: scroll;
}

.react-image-crop__handle.react-image-crop__handle--n,
.react-image-crop__handle.react-image-crop__handle--e,
.react-image-crop__handle.react-image-crop__handle--s,
.react-image-crop__handle.react-image-crop__handle--w {
  display: none !important;
}
.eMed_Doc_Notes_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25vw;
    height: 30vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Test_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Test_Trans_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28vw;
    height: 10vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Diagonis_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 30vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Counsellor_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_lab_master_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 42vw;
    background-color: white;
    overflow: auto;
}

.eMed_Doc_Notes_Prescrip_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    max-height: 45vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Prescrip_Fav_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28vw;
    max-height: 15vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Exam_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}
.css-1a8ioxl-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height: 55px;
}

.eMed_icon_styles{
background-color: #04B7B1;
border-radius: 0.2vw;
height: 2vw;
width: 2vw;
display: flex;
align-items: center;
justify-content: center;
margin-top: 0.2vw;
margin-right: 0.5vw;
cursor: pointer;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 13px 11.5px !important;
}

.eMed_Dosage_Type_Box{
    background-color: rgb(223, 223, 223);
    padding: 0.4vw 1vw 0.4vw 1vw;
    border:solid black 1px;
    /* border:solid #04B7B1 1px; */
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.eMed_Dosage_Type_Box_Selectable{
    padding: 0.4vw 1vw 0.4vw 1vw;
    border:solid #04B7B1 1px;
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.eMed_Dosage_Type_Selected_Box{
    background-color: #04B7B1;
    padding: 0 1vw 0 1vw;
    border:solid white 1px;
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.custom-dropdown {
    position: relative;
}

.dropdown-content {
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.eMed_OtherDocNotes_DrawerDiv{
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
}

.eMed_OtherDocNotes_Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_DocPatCate_Box {
    background-color: rgb(247, 243, 243);
    border-color: rgb(128, 128, 128);
    border-radius: 0.4vw;
    margin-bottom: 0.5vw;
    padding-right: 0.8vw;
    padding-left: 0.8vw;
    padding-top: 0.3vw;
    padding-bottom: 0.3vw;
    font-size: 0.9vw !important;
    border: 1px solid #000000;
    margin-right: 1vw;
}

.eMed_docNotess_title{
    font-size: 1vw !important;
    font-weight: 600 !important;
}

.eMed_Vitals_header_div{
    display: flex;
    justify-content: space-between;
    background-color: #efefef;
    font-weight: 600 !important;
    color: #616161;
    align-items: center;
    height: 6vh;
    border: 1px solid #000000;
    border-radius: 0.4vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_Vitals_body_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6vh;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_DocNotes_no_records_text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_Other_DocNotes_DrawerIcon{
    margin-left: 1vw !important;
    background-color: white !important;
    box-shadow: -3px 0.1px 0.5px 0.2vw #c1e8e1 !important;
    border-radius: 4px 0 0 4px !important;
}

.eMed_Other_DocNotes_testrgt {
    width: 100%;
    height: 50dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll
}
.eMed_Doc_Img_Pop_Up{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 35vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}
.eMedhub_highlight_column{
    background-color:#FFE1DE !important;
}

.eMedhub_highlight_column:hover{
    background-color:#FFE1DE !important;
}

.doc_header_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    height: 3.5vw;
    padding: 0.2vw;
    margin-top: 0.5vw;
}

.eMed_Chip_Border {
    height: 2vw !important;
    background-color: white !important;
    font-weight: 600 !important;
    border: 1px solid #04B7B1 !important;
    border-radius: 6px !important;
}
.eMed_Chip_redBorder {
    height: 2vw !important;
    background-color: white !important;
    font-weight: 600 !important;
    border: 1px solid red !important;
    border-radius: 6px !important;
}

.eMed_Chip_OnClick{
    height: 2vw !important;
    background-color: #04B7B1 !important;
    font-weight: 600 !important;
    border: none !important;
    color: white !important;
    border-radius: 6px !important;
}
.eMed_Chip_redOnClick{
    height: 2vw !important;
    background-color: #04B7B1 !important;
    font-weight: 600 !important;
    border: 2px solid red !important;
    color: white !important;
    border-radius: 6px !important;
}

.eMed_BoxShadow {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: 2.2vw;
    width: 9vw;
    margin-top: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center
}

.eMedhub_Shadow_Properties{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.doc_header_card_New {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    height: 3.5vw;
    margin-top: 0.5vw;
    padding-left: 0.2vw;
}
#eMed_BM_mainDiv {
    width: 100%;
    height: 84vh;
    background-color: #F5F8F7;
    overflow-y: scroll;
    scroll-behavior: smooth;
    /* padding: 0.5vw; */
}

.eMed_Ovarall_Chart_Wrapper {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.5vw auto;
}
.eMed_OP_Chart_Wrapper {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.5vw auto;
    margin-top: 1vw;
}


.eMed_BM_Div1 {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.5vw auto;
}

.eMed_Individual_DashChart {
    width: 100%;
    /* height: fit-content; */
    height: 18.8vw;
    /* margin-bottom: 0.6vw; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: white;
    border-radius: 0.4vw;
    /* box-shadow: 0px 0px 3px gray; */
    grid-column-gap: 0.5vw;
    column-gap: 0.5vw;
}

.eMed_DashChart_CardView {
    width: 12.35vw;
    /* height: fit-content; */
    height: 9.2vw;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background-color: white;
    border-radius: 0.4vw;
    /* box-shadow: 0px 0px 3px gray; */
}

.eMed_DashChart_CardView_Wrapper{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0.5vw;
    column-gap: 0.5vw;
    grid-row-gap: 0.5vw;
    row-gap: 0.5vw;
}

.eMed_Divider_Div{
    margin-left: 0.5vw;
    margin-bottom: 1vw !important;
}

.eMed_CharhPage_Header_div{
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5vw;
}

.eMed_CharhPage_Header_filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1vw;
}

.eMed_CharhPage_filter_elements{
    width : 23vw !important;
    margin-bottom: 1vw;
    padding-left: 2vw;
}

.eMed_CharhPage_filter_elements_compare{
    width : 20vw !important;
    margin-bottom: 1vw;
    padding-left: 2vw;
}

.eMed_CharhPage_filter_elements_select{
    width : 22vw !important;
    margin-bottom: 1.8vw;
    padding-left: 2vw;
}

.eMed_Chart_Dashboard_Filter_main{
    margin-top: 5vw;
    width: 25vw;
}

.eMed_Dashboard_Chart_wrapper{
    height: 80vh;
    overflow-y: scroll;
    width: 100%;
    padding-left: 0.5vw;
}
.eMed_revenueCard_div {
    width: 12.35vw;
    height: 9.2vw;
    padding: 0.5vw;
}

.eMed_revenueCard_imgDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5vw;
}

.emedhub_revenueCard_img {
    width: 2.4vw;
    height: 2.4vw;
    margin-right: 0.3vw;
}

.eMed_revenueCard_countDiv {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.6vw;
}

#eMed_revenueCard_headTxt {
    font-size: 0.85vw;
    font-weight: 600;
}

#eMed_revenueCard_Txt {
    font-size: 0.85vw;
    color: #616161;
}

#eMed_revenueCard_countTlt {
    font-size: 0.8vw;
    color: #616161;
    display: flex;
    align-items: center;
}

#eMed_CountCard_CountLabel {
    font-size: 0.8vw;
    display: flex;
    align-items: center;
}

#eMed_revenueCard_comparTlt {
    font-size: 0.7vw;
    color: #616161;
    display: flex;
    align-items: center;
}

#eMed_revenueCard_count {
    font-size: 0.8vw;
    color: #616161;
    font-weight: 600;
    padding-left: 0.3vw;
}

#eMed_CountCard_Amount {
    font-size: 0.8vw;
    color: #616161;
    font-weight: 600;
}

#eMed_revenueCard_profitSts {
    font-size: 0.6vw;
    color: #616161;
}

#eMed_revenueCard_amount {
    font-size: 1.23vw;
    font-weight: 600;
}

#eMed_CountCard_CountValue {
    font-size: 1vw;
    font-weight: 600;
    padding-left: 0.3vw;
}

.eMed_revenueCard_profit {
    width: 0.8vw;
    height: 0.8vw;
    margin-right: 0.2vw;
}

.eMed_revenueCard_profit_expand {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 0.6vw;
}

.eMed_revenueTbl_titleDiv {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vw 0.5vw;
    border-bottom: 1px solid #ddd9d9;
}

.emedhub_expand_img {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_barChart_chartTltDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10%;
}

.eMed_barChart_chartTltDiv_expand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20%;
    padding-right: 2vw;
    padding-top: 1vw;
}

.eMed_barChart_content{
    width: 100%;
    height: 90%;
}

.eMed_barChart_imgDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5vw;
}


#eMed_barChart_Txt {
    font-size: 0.8vw;
    color: #616161;
}

#eMed_barChart_Txt_expand {
    font-size: 1vw;
    color: #616161;
}

.eMed_doughnut_chartDiv {
    width: 100%;
    height: 85%;
    padding: 0.6vw;
}

.eMed_expandPop_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_expandPop_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31vw;
    width: 100%;
    overflow-y: scroll;
    padding: 0.625vw;
}

.eMed_chart_wrapper{
    width: 100%;
    height: 18.8vw;
}

#eMed_chart_headTxt {
    font-size: 0.9vw;
    font-weight: 600;
}

.eMed_noDataDiv {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-ticks{
    font-size: 0.8vw;
}
.eMed_DOC_WebCam_Img_Div {
    height: 25vw;
    width: 33.2vw;
    margin-top: 1.5vw;
    margin-left: 1.4vw;
}

.eMed_sessions_div {
    border: 1px solid #BDBDBD;
    padding: 0.5vw;
    border-radius: 0.4vw;
    margin-left: 0.5vw;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 5.5vh;
    width: 15.5vw
}

.eMed_Sticky_first_Column {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2; /* Adjust as needed to ensure it appears above other columns */
}

.eMed_button{
    color: #fff;
    padding: 0.25vw 1vw 0.25vw 1vw;
    border-radius: 0.2vw;
    cursor: pointer;
}
.rad_bill_trns_container{
    height: 79vh;
}

.rad_bill_trns_header_card{
    display: flex;
    padding: 0.5vw 0vw;
}

.rad_bill_trns_cards{
    display: flex;
    flex: 0.7 1;
}

.rad_bill_trns_btns{
    flex: 0.3 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
    align-items: center;
}

.rad_bill_trns_content_card{
    padding: 0.5vw;
    height: 66vh;
}

.rad_bill_btn{
    height: 2vw;
}
.rad_Rpts_container {
    height: 79vh;
}

.rad_rpts_header_card {
    padding: 0.5vw 0vw;
    display: flex;
}

.rad_rpts_cards {
    flex: 0.7 1;
    display: flex;
}

.rad_rpts_btns {
    flex: 0.3 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
    align-items: center;
}

.rad_rpts_content_card {
    height: 66vh;
    padding: 0.5vw;
}
.rad_srv_container{
    height: 79vh;
    display: flex;
    grid-gap: 0.5vw;
    gap: 0.5vw;
    padding: 0.5vw;
}

.rad_lft_container{
    border: 1px solid lightgray;
    flex: 0.65 1;
    background-color: white;
}

.rad_rgt_container{
    border: 1px solid lightgray;
    flex: 0.35 1;
    background-color: white;
}

.rad_rgt_header{
    height: 5vh;
    display: flex;
    align-items: center;
    padding-left: 0.5vw;
    justify-content: space-between;
}

.rad_rgt_btn{
    height: 2vw !important;
    margin-right: 1vw !important;
    text-transform: capitalize !important;
}

.rad_rgt_btn_card{
    display: flex;
    justify-content: center;
    align-items: center;
    height:6vh;
}

.rad_rgt_content{
    height: 65.5vh;
    padding: 0.5vw;
}

.rad_rgt_tbl_head{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.rad_lft_scd_tbl{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.rad_tst_container{
    height: 79vh;
    padding: 0.5vw;
}

.rad_tst_tmp_header{
    background-color: white;
    height: 8vh;
}

.rad_tst_tmp_content{
    background-color: white;
    margin-top: 1vw;
    height: 67vh;
}

.rad_srv_tmp_pap{
    display: flex;
    padding: 0.5vw;
    align-items: center;
}
.rad_home_container {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.rad_home_header_card {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 7vh;
    align-items: center;
}

.rad_home_header{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rad_home_card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rad_home_icon {
    width: 4vw;
    height: 4vw;
}

.rad_home_font {
    font-size: 0.8vw;
}

.rad_home_icon_circle {
    width: 0.8vw;
    height: 0.8vw;
}

.rad_home_icon_est {
    font-size: 0.8vw;
    margin-top: -.3vw;
}

.rad_home_cards {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
}

.rad_bill_tbl_head{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.rad_input_size {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    background-color: #F5F8F7;
}

.rad_demo_header_one{
    background-color: white;
    height: 15vh;
    width: 50vw;
}

.rad_demo_header_card {
    height: 15vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5vw;
}

.rad_demo_header_two{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    height: 9vh;
    padding-right: 0.5vw;
}

.rad_demo_header_lft{
    background-color: white;
    height: 15vh;
    margin-left: 1vw;
}

.rad_popup_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78vw;
    height: 82vh;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 17%;
    margin-left: 1vw;
    cursor: pointer;
  }
  
  .editor {
    padding: 0.5vw;
    margin: 0.2vw;
  }
  .otherEditor {
    padding: 0.5vw;
    margin: 0.2vw;
    height: 45vh;
    overflow: scroll;
  }
.eMed_Canteen_Menuitem_Con {
    width: 94vw;
    height: 79vh;
}

.eMed_Canteen_Menuitem_LeftCon {
    width: 35vw;
    height: 76.2vh;
    background-color: white;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_Canteen_Menuitem_rightCon {
    width: 60vw;
    height: 76.2vh;
    background-color: white;
    margin: 1vw;
}

.eMed_Canteen_leftHeadCon {
    height: 6vh;
    background-color: #F5F8F7;
    display: flex;
    align-items: center;
    border: 1px solid #F5F8F7;
    border-radius: .3vw .3vw 0px 0px
}

.eMed_Canteen_Headtext {
    font-weight: 600 !important;
    color: #616161;
    padding-left: 1vw;
}

.eMed_Canteen_BtnCon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7vh;
    background-color: #F5F8F7;
    border: 1px solid #F5F8F7;
    border-radius: 0vw 0vw .3vw .3vw;
}

.eMed_Canteen_BtnText {
    width: 6vw;
    text-transform: capitalize !important;
}

.eMed_Canteen_TopCon {
    height: 13vh;
    display: flex;
    flex-direction: row;
    margin: .5vw 1vw 0vw 1vw;
}

.eMed_Canteen_SecondCon {
    height: 63.5vh;
    margin-top: .5vw;
    background-color: white;
    margin: .5vw 1vw 0vw 1vw;
}

.eMed_Canteen_CardCon {
    width: 88vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Canteen_FilterCon {
    width: 12vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.eMed_Canteen_img {
    height: 2vw;
    width: 2vw;
}

.eMed_Canteen_CloseImg {
    height: 1vw;
    width: 1vw;
}

.eMed_CanteenFilter_Head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    width: 28vw;
}

.eMed_Canteen_FilterBox {
    width: 30vw;
    margin-top: 5vw;
}

.eMed_Canteen_fil_content {
    width: 28vw;
    height: 76vh;
    margin: auto;
}

.eMed_Canteen_Date {
    margin-top: 1vw;
    width: 14.2vw;
    position: relative;
    left: 14.8vw;
}

.eMed_Canteen_fil_text {
    width: 28vw;
}

.eMed_Canteen_CheckBoxDiv {
    width: 8vw;
}

.eMed_Canteen_CardBox {
    width: 20vw;
    height: 3.5vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    margin-right: 1vw;
}
.eMed_Asset_Sts_log_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48vw;
    height: 30vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}

.eMed_Asset_Sts_Cge_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    height: 20.5vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}
.eMed_Chart_Layout {
    height: 73vh;
    margin: 0.2vw;
    box-shadow: 0px 0px 2px gray;
}

.eMed_charts_sub_title {
    /* height: 6vh; */
    display: flex;
    align-items: center;
    padding-left: 1vw;
    font-weight: 600 !important;
    color: #616161;
    margin-top: 0.8vw;
}

.eMed_Ante_Chart_Tabs {
    background-color: white;
    margin: 0.5vw;
    box-shadow: 0px 0px 2px gray;
    border-radius: 0.1vw;
}
.eMed_Main_Container{
    height: 79dvh;
    background-color: #f5f8f7;
}

.eMed_Header_Container{
    display: flex;
    height: 15dvh;
}

.eMed_Header_Rgt_Content{
    flex: 0.9 1;
    display: flex;
    align-items: center;
}

.eMed_Header_Lft_Content{
    flex: 0.1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.eMed_Table_Content{
    height: 65dvh;
    padding: 0.5vw;
}

.eMed_Header_Btn{
    height: 2vw !important;
    width: 2vw !important;
    margin-right: 0.5vw !important;
}
:root {
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}

.eMed_IPDocAptTbl_Con {
    height: 70dvh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_IPDoc_AptHead {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6dvh;
}

.eMed_IPDocFlt_Con {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    margin: 3px auto;
    padding: 0.625dvw 0dvw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eMed_IPDocFlt_txt {
    font-size: 1dvw;
    font-weight: 700;
    padding-left: 0.5dvw;
    border-bottom: 1px solid rgb(203, 191, 191);
}

.eMed_IPDocFlt_subCon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_IPDoc_autoComplete {
    width: 14dvw;
    margin: 0dvw 0.3dvw !important;
}

.eMed_Header_IP_Status_btn {
    margin-right: 1dvw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-radius: 2px !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5dvw !important;
    padding-right: 0.5dvw !important;
    width: 6dvw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Tab_batch_count {
    background-color: white(233, 99, 99);       /* Light gray background */
    border: 1px solid #dcaeae;      /* Gray border */
    border-radius: 12px;          /* Rounded corners */
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
    color: red;                 /* Gray text color */
    font-weight: bold;
    display: inline-block;
    min-width: 30px;
    text-align: center;
    font-size: 0.8vw;
}

.emed_tab_font {
    text-transform: capitalize;
    min-height: 1vw;
    font-size: 0.90vw;
    font-weight: 600;
}

.emed_approve_btn {
    height: 30px !important;
    text-transform: capitalize !important;
}
.emed_multibill_container {
    height: 100%;
    background-color: #F5F8F7;
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
    height: 80dvh;
}

.emed_multibill_header {
    height: 10dvh;
    display: flex;
    flex-direction: row;
}

.emed_multibill_content {
    height: 51dvh;
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
    overflow-y: auto;
}

.emed_multibill_footer {
    height: 17dvh;
    display: flex;
    flex-direction: row;
}

.emed_multilab_tblbody {
    background-color: white;
    display: flex;
    flex: 1 1;
    width: 100%;
}
.doc_notes_header_card {
    height: 5dvh;
    background-color: #F5F8F7;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1.5px solid lightgray; */
}

.doc_notes_tbl_header {
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    background-color: #F5F8F7 !important;
    padding: 0.3rem 0.5rem !important;
    border-bottom: none !important;
}

.doc_notes_layout {
    background-color: white !important;
    border: 1.5px solid lightgray !important;
}

.doc_notes_header_card_font {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
}

.doc_notes_tbl_body_cell {
    padding: 0.3rem 0.5rem !important;
}

.doc_autocomplete_height {
    height: 4.5dvh !important;
}

.doc_autocomp_drop_font {
    font-size: 0.9rem !important;
    padding: 0rem !important;
}

.doc_medi_popup {
    width: 100%;
    height: 48dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_med_tab {
    min-height: 0vw !important;
    height: 2.5vw !important;
}

.doc_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.doc_diagram_popup {
    width: 100%;
    height: 34dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_addendum_popup {
    width: 100%;
    /* height: 15dvw; */
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_med_box {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 0.7rem;
}

.doc_med_margin_top{
    margin-top: 0.5rem !important;
}

.doc_med_tbl{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}
.emed_multibill_container {
    height: 100%;
    background-color: #F5F8F7;
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
    height: 80dvh;
}

.emed_multibill_header {
    height: 10dvh;
    display: flex;
    flex-direction: row;
}

.emed_multibill_content {
    height: 51dvh;
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
    overflow-y: auto;
}

.emed_multibill_footer {
    height: 17dvh;
    display: flex;
    flex-direction: row;
}

.emed_multilab_tblbody {
    background-color: white;
    display: flex;
    flex: 1 1;
    width: 100%;
}
.fieldHeaderfordoctor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  max-width: 22%;
  border: 1px solid white;
  border-radius: 10px;
  height: 3.5vw;
}
.fieldHeader {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 5px;
  cursor: pointer;
}
.savedBillFieldHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 1;

 
}
.emed_DialogboxOrder {
  margin-top: 10px;
}
.Avatar {
  display: flex;
  align-items: center;
  right: 30px;
}
.eMed_user {
  width: 17.5vw;
  /* height: 2vw; */
}
.eMed_loginuser {
  font-size: 0.9vw;
}
.eMed_LoginType {
  font-size: 0.8vw;
}
.eMed_servicetype {
  width: 15vw;
}

.eMed_headContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NotiContainer {
  position: absolute;
  top: 9%;
  left: 8%;
  width: 85vw;
  height: 6vw;
  z-index: 99;
}

.IdleNotiContainer {
  position: absolute;
  bottom: 3%;
  left: 8%;
  width: 85vw;
  height: 6vw;
  z-index: 99;
}

.NotiWrapper {
  background-color: #eb4b4b;
  border-radius: .2vw;
  padding: 8px;
  display: flex;
  align-items: center;
}
.IdleNotiWrapper {
  background-color: #ff7c00;
  border-radius: .2vw;
  padding: 8px;
  display: flex;
  align-items: center;
}

.NotiHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NotiText{
  color: white;
  padding-left: .5vw;
  font-size: 1vw;
  font-weight: 400;
}

.NotiIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: .3vw;
}

.NotiPara{
  color: white;
  font-size: 1vw;
  padding-left: 20vw;
}

.eMed_golbalSearch {
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: 10px;
}

.emed_headerLogo {
  width: 5vw;
}

.doctorName {
  font-size: 0.8vw;
  font-weight: bold;
}

.eMed_profile {
  width: 80%;
  margin-left: 0.625vw;
}

.eMed_Avatar{
  width: 20%;
}
.Icon {
  color: white;
}

.opht {
  font-size: 0.7vw;
}

.eMed_searchbox {
  background-color: white;
  border-radius: 4px;
  max-width: 20vw;
}

.img_logo {
  width: 3vw;
}

.img_logo1 {
  height: 60%;
  width: 80%;
}

.label {
  font-size: 0.6vw;
  cursor: pointer;
}

.sublabel {
  font-size: 0.6vw;
}

.MenuDropdown {
  margin-left: 20px;
  font-size: 30px;
}

#emed_sidebarmenu {
  font-size: 12px;
}

.emed_Dialogboxwidth {
  padding: 15px;
  width: 26vw;
  height: 49vh;
}
.emed_Dialog_Pres {
  padding: 1vw;
  width: 30vw;
  height: 70vh;
}
.emed_Dialog_Appoin {
  padding: 1vw;
  width: 30vw;
  height: 74vh;
}
.emed_Dia_SaveBill {
  padding: 1vw;
  width: 40vw;
  height: 70vh;
}

/* .eMed_Drawer ::-webkit-scrollbar {
  display: none;
} */

.eMed_Drawer {
  max-width: 2.3vw;
}

.eMed_AvatarMenu {
  width: 15vw;
}

.eMed_searchbarIcon {
  cursor: pointer;
}

#eMed_menuHieghlight {
  margin: 10px;
  padding-left: 30px;
  border-radius: 4%;
}

.eMed_muiDrawer {
  display: flex;
  flex-grow: 1px;
  padding-top: 4.3vw;
  height: 95vh;
}

#eMed_profileMenu {
  margin-top: 50px;
  margin-left: -1vw;
}

.eMed_menuIcon {
  margin-left: 0px;
}

.eMed_menuText {
  margin-left: -8px;
  font-size: 10px;
}

.eMed_subMenus {
  display: flex;
}

.eMed_muiModulepart {
  flex-grow: 1px;
  min-width: 94vw;
  padding-left: 10px;
  padding-right: 10px;
}

.eMed_menuItems {
  cursor: pointer;
  /* border-radius: 20%; */
}

#eMed_side_list {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  /* height: 85dvh; */
  overflow-y: auto;

}

.eMed_appbar_menu {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: flex-end;
}

#eMed_menu_icons {
  font-size: 2vw;
}
 @media (min-width: 600px) {
  .MuiToolbar-root {
    min-height: 4vw !important;
  }
} 
/*@media (min-width: 600px) {
  .css-13r55hi {
    min-height: 4vw !important;
  }
}*/
@media (min-width: 600px) {
  .MuiDrawer-docked 
  .MuiDrawer-paper{
  width: 4.8vw !important;
}
}
.MuiListItemIcon-root{
  min-width: 2.5vw !important;
  margin-left: -0.5vw !important;
}
.lab_saved_bill_icon {
  align-items: center;
  border-width: 0vw;
  margin-top: 0vw;
  height: 3vw;
}
.fo_requests_icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  border-width: 0vw;
  margin-top: 0vw;
  height: 3vw;
  margin-right: 0.9vw;
  width: 4.2vw;
}
.SavedBillImage {
  height: 5vh;
  width: 3vw;
}
.Lab_Home_Patient_Search {
  background-color: var(--themColor) !important;
  border-radius: 0.3vw;
  margin-left: 4vw;
}
.Patient_Details_Search{
  z-index: 1500;
  position: absolute;
  background-color: #F5F8F7;
  height: 40vh;
  width: 24vw;
  overflow: scroll;
  padding: 0.5vw;
  flex: 1 1;
  border-radius: 0.3vw;
  box-shadow: 0px 4px 8px 0px gray;
}
.search_sub_container {
  display: flex;
  flex-direction: row;
  flex: 0.6 1;
  margin: 0vw;
  padding: 0vw;
}
.Patient_Details_Main {
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
}
.Lab_Search_Button {
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 5vh;
  min-width: 13% ;
  margin-top: 0vw;
}
.Patient_Search_Text {
   color: lightgray;
   margin-left: 0.5vw;
  
}
.placeholderStyle {
  font-size: 0.8vw;
}

.mui_header_card_right{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left:3vw;
  background-color: #E4F2F0;
  border-radius: 0.2vw;
}

.mui_header_card_dropdown{
  height: 4.7vh;
}
.mui_header_card_dropdown_fo{
  height:2.56vw;
}

.eMed_Header_Search_item{
  display: flex;
  flex-direction: row;
  width: 40vw;
  justify-content: space-between;
  align-items: center;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 4vw;
}
.eMed_Header_Filter_Pop{
  margin-top: 2.8vw;
  margin-left: 25vw;
  padding-left:1vw ;
  padding-right:1vw ;
}
.eMed_Header_Search_Pop{
  margin-top: 2.8vw;
  margin-left: 13.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}
.eMed_Header_Filter_item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left:1vw ;
  padding-right:1vw ;
  margin-top: 1vw;
  height: 25vh;
  width: 22vw;
}

.eMed_header_Input{
    width: 23vw;
    height: 2.56vw;
    font-size: 0.8vw;
    background-color:white
}
.eMed_Pharmaheader_Input{
  width: 17vw;
  height: 2.56vw;
  font-size: 0.8vw;
  background-color:white
}

.eMed_WebCam_Btn_div_faceRecog{
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-left: 11vw;
  margin-top: 1.5vw;
}

.eMed_Header_Request_Pop{
  margin-top: 2.8vw;
  margin-right: 8vw;
  margin-left: 15vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50vh;
}
.eMed_Header_CreateRequest_Pop{
  /* margin-top: 10vw; */
  /* margin-right: -20vw; */
  /* margin-left: -9vw; */
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}

.eMed_craeteappoint_Grid_row{
  height: 4vw;
  padding-left: 1.8vw;
}

.eMed_Header_Request_item{
  display: flex;
  flex-direction: row;
  width: 51vw;
  justify-content: space-between;
  align-items: center;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 4vw;
}

.eMed_CreateRequest_BtnBox{
  display: flex;
  flex-direction: row;
  height: 3vw;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.5vw;
  margin-top: 0.5vw;
}

.eMed_Profile_details_div{
  border: 1px solid gray;
  padding: 0.5vw;
  margin: 0.5vw;
  border-radius: 0.5vw;
  box-shadow: 0px 1px 2px 0px gray;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.eMed_Profile_details_text{
  padding-left: 1vw;
}

.eMed_App_reqest_table_head{
  height: 2vw;
  background-color: rgb(226, 226, 226);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:52.5vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.eMed_pharmaHeader_Search_Pop{
  margin-top: 2.8vw;
  margin-left: 5.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}
.eMed_Request_sugg_Head{
  height: 2vw;
  background-color: rgb(226, 226, 226);
  display: flex;
  align-items: center;
  padding-left: 1vw;
  font-size: 0.8vw !important;
}

.eMEd_CreateRequest_Box{
  width:47.3vw;
}

.eMed_rad_header_search{
  margin-top: 2.8vw;
  margin-left: 3.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}

.eMed_lab_history_popup{
  position: absolute;
  top: 55%;
  left: 50.5%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 90vw;
  height:78vh;
  background-color: white;
  border-radius: 8px;
  overflow: auto;
}

.eMed_imgCap_btn{
  width: 2vw;
  height: 2vw;
}
#eMed_dropDown_formcontrol{
    margin:1vw;
    width:20vw
  }

#eMed_DropDown_Box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 30vw;
}
#eMed_individual_div{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
#eMed_dialog_title{
    font-size: 1.3vw;
}
#eMed_dialog_text{
    font-size: 1.2vw;
}
#eMed_dialog_button_div{
    display: flex;
    justify-content: center;
    margin-bottom: 0.5vw;
    
}
#eMed_dialog_button{
    margin-right: 1vw ;
}
* {
  font-size: 1vw;
}

.eMed_page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#eMed_table_name {
  margin-left: 2vw;
  font-size: 1.3vw;
}

#eMed_tbl_paymentcard {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#eMed_name_totalcard {
  display: flex;
  align-items: center;
}

#eMed_totalcard {
  cursor: pointer;
  margin-left: 3.5vw;
}

#eMed_tbl_filters {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}

#eMed_tbl_datefilter {
  margin-right: 0.5vw;
  width: 12vw;
}

#eMed_tbl_searchbox {
  margin-left: 0.5vw;
  width: 13vw;
}

#eMed_tbl_searchicon {
  margin-left: 1vw;
}

.eMed_tbl_card {
  margin-top: 0.8vw;
}

.eMed_tbl_container {
  height: 55vh;
}

.eMed_sticky_start {
  position: sticky;
  left: 0;
}

.eMed_sticky_startbody {
  position: sticky;
  left: 0;
  background-color: white;
}

.eMed_sticky_end {
  position: sticky;
  right: 0;
}

.eMed_sticky_endbody {
  position: sticky;
  right: 0;
  background-color: white;
}

.eMed_sortable_name {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.eMed_sortable_number {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.eMed_sortable_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_sorting_icon {
  font-size: 1vw;
  margin-top: 0.2vw;
  cursor: pointer;
  margin-left: 0.2vw;
}

#eMed_tbl_headtext {
  font-size: 1.1vw;
  display: flex;
  align-items: center;
}

#eMed_tbl_bodytext {
  font-size: 1vw;
}

#eMed_nonsticky_head {
  z-index: 0;
}

.eMed_tbl_headwidth_small {
  min-width: 9vw;
}

.eMed_tbl_headwidth_large {
  min-width: 14vw;
}

.eMed_tbl_headwidth_extralarge {
  min-width: 16vw;
}

#eMed_icons:hover {
  transform: scale(1.2);
}

#eMed_icons {
  font-size: 1.5vw;
}

#eMed_Norecords {
  font-size: 1.5vw;
  display: flex;
  position: absolute;
  top: 55%;
  left: 43%;
}

#eMed_tbl_pagination {
  display: flex;
  float: right;
  font-size: 1vw;
}

#eMed_form_helpertext {
  font-size: 0.7vw;
  height: 1.2vw;
}

.eMed_page_top_div {
  border: 0 0 3px 3px;
  border-radius: 0 0 50px 50px;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 5px 8px #00000029;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eMed_page_top_div_icons {
  display: flex;
  justify-content: space-around;
}

#eMed_Icons_Avatar {
  width: 2.5vw;
  height: 2.5vw;
  box-shadow: 0px 3px 6px #00000029;
}

#eMed_actions_column_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_totalcard {
  display: flex;
  flex-direction: row;
}

#eMed_tbl_yearandmonth {
  width: 8vw;
}

#eMed_year_filter {
  width: 7vw;
}

#eMed_clickable_tbl_row {
  cursor: pointer;
}

.eMed_splitup_container {
  height: 23vw;
}

.eMed_splitup_card {
  height: 28vw;
  margin-top: 1vw;
}

::-webkit-scrollbar {
  width: 0.4vw;
  height: 0.5vw;
}

::-webkit-scrollbar-thumb {
  background: var(--themColor);
  border-radius: 100vw;
  visibility: visible;
  height: 1vw;
  width: 10vw;
}

.eMed_cursor{
  cursor: pointer;
}

.MuiDataGrid-columnHeaderTitle{
  font-weight: 600 !important;
  color: #616161 !important;
  font-size: 0.9vw !important;
}

.MuiDataGrid-columnHeader {
  background-color: #F5F8F7;
}

.MuiSvgIcon-colorPrimary{
  color: var(--themColor) !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.Common_Btn_Min_Width{
  min-width: 0vw !important;
}

.emed_Branchial_container {
  width: 50%;
  overflow: hidden;
  transition: transform 0.5s ease;
  z-index: -1;
  margin: 0.25vw 0;
  object-fit: contain;
}

.emed_Branchial_TopBtn {
  position: absolute;
  bottom: 22vw;
  left: 14.5vw;
  cursor: pointer;
}

.emed_Branchial_btmBtn {
  position: absolute;
  bottom: 9vw;
  left: 14.3vw;
  cursor: pointer;
}

.emed_Branchial_BackBtn {
  position: absolute;
  top: 1vw;
  left: 1.5vw;
  cursor: pointer;
}

.emed_Branchial_zoomTop {
  transform: scale(2);
  transform-origin: center top;
}

.emed_Branchial_zoomBottom {
  transform: scale(2);
  transform-origin: center bottom;
}

#emed_Branchial_txt {
  font-size: 0.7vw;
  color: #616161;
}

#emed_Branchial_value {
  font-size: 0.8vw;
  font-weight: 600;
}

.emed_Branchial_leftVal {
  position: absolute;
  left: 5vw;
}

.emed_Branchial_rightVal {
  position: absolute;
  left: 22vw;
}

.emed_Branchial_Impression {
  position: absolute;
  top: 1vw;
  right: 20vw;
}

.eMed_tbl_pagination{
  min-height: 0vw !important
}
