.eMed_OP_Appointment {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_OP_AptHead {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6vh;
}

.eMed_OP_AddPatient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_OP_Apt_Cards {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
}

.eMed_OP_PatientType_CreateBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.eMed_OP_PatientType {
    display: flex;
    flex-direction: row;
}

.eMed_OP_TypeNew {
    font-size: 0.8vw;
}

.eMed_OP_TypeEstablished {
    font-size: 0.8vw;
    margin-top: -.3vw;
}

.eMed_OP_AddPatient_Icon {
    width: 4vw;
    height: 4vw;
}

.eMed_Apt_TblHead {
    background-color: #FAF9F9;
}

.eMed_OP_Patient_Iconcircle {
    width: 0.8vw;
    height: 0.8vw;
}
.eMed_OP_Patient_Iconcircle_Appointment{
    width: 1.1vw;
    height: 1.1vw;
    margin-top: 0.8vh;
}

.eMed_OP_AptType_Icon {
    margin-left: 0.3vw;
}

.eMed_OP_APtTbl_PayPending {
    margin-left: 0.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_OP_AptTbl_PayIcon {
    height: 0.8vw;
    width: 0.8vw;
    margin-right: 0.3vw
}

#eMed_OP_AptTbl_PayText {
    font-size: 0.9vw;
}

.eMed_OP_AptTbl_PayCompletedIcon {
    height: 1vw;
    width: 1vw;
    margin-right: 0.3vw;
}

.eMed_OP_AptTbl_Status_Icon {
    width: 1.5vw;
    height: 1.5vw;
}

.eMed_OP_AptTbl_StatusCom_Icon {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_OP_Apt_ActionBtn {
    display: flex;
    flex-direction: row;
    width: 3vw;
    justify-content: space-between;
}

.eMed_OP_Apt_BtnDiv {
    display: flex;
    flex-direction: row;
}

.eMed_AptTbl_Con {
    height: 65vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_OP_AptTbl_Comment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 12vw;
}

#eMed_OP_AptTbl_AptText {
    font-size: 0.8vw;
}

#eMed_OP_AptTbl_AptText1 {
    font-size: 0.7vw;
}

#eMed_OP_Btn {
    min-width: 0.2vw !important;
    padding: 0.5vw !important;
    padding-top: 0.2vw !important;
    padding-bottom: 0.2vw !important;
}

.eMed_NxtAppPop_Title_Div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}

.eMed_NxtAppPop_TextDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.5vw;
    margin-top: 0.5vw;
    margin-left: 1.3vw;
}

.eMed_NxtAppPop_Duration_Grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 1vw;
}

.eMed_NxtAppPop_Dropdown {
    width: 10vw;
}

.eMed_NxtAppPop_Duration_GridItem {
    margin-top: 1vw !important;

}

.eMed_details_label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 10vw;
}

.eMed_NxtAmt_POp_BtnDiv {
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}

.eMed_nxtAppoint_img {
    height: 1vw;
    width: 1vw;
}

.eMed_nxtAppoint_img_small {
    height: 0.8vw;
    width: 0.8vw;
}

.eMed_bill_container {
    /* margin-top: 0.7vw; */
    background-color: #F5F8F7;
}

.eMed_bill_content {
    height: 80vh;
}
.eMed_bill_content1 {
    height: 70vh;
}
.eMed_bill_ipContainer{
    height: 70vh;
    position: relative;
}
.eMed_bill_ipContent{
    height: 68vh;
}
.eMed_bill_ipContent1{
    height: 66vh;
}
.eMed_bill_content2 {
    height: 7vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95vw;
    margin-top: 3vw;
}
.eMed_bill_Ipcontent2{
    /* width: 100%; */
    height: 7vh;
    /* background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
    /* position: absolute;
    bottom: 0.3vw; */
}

.eMed_createapt_right1 {
    height: 42vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_createapt_right2 {
    height: 33vh;
    background-color: white;
    width: 30vw;
    margin-top: 0.5vw;
    margin-right: 1vw;
    overflow-y: scroll;
}

.eMed_createapt_right1_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 1vw;
}

.eMed_createapt_right2_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 27vw;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vw;
}

.eMed_createapt_right2_field_img{
    width: 4vh;
    height: 4vh;
}

.eMed_createapt_left {
    width: 60vw;
    height: 73vh;
    margin-top: 1vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.eMed_ipcreateapt_left{
    margin-top: 0.4vw;
}
.eMed_createapt_left1 {
    height: 15vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
}

.eMed_createapt_left2 {
    height: 54vh;
    margin-left: 1vw;
    margin-top: 1vw;
    width: 60vw;
    overflow-y: scroll;
}
.eMed_ipcreateapt_left2{
    height: 52vh;
    margin-top: 0.625vw;
}

.eMed_createapt_left1_paper1{
    background-color: white;
    height: 15vh;
    width: 48vw;
}

.eMed_createapt_left1_paper2{
    background-color: white;
    height: 15vh;
    width: 11vw;
    margin-left: 1vw;
}

.eMed_createapt_left1_paper1_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    width:95%;
    height: 9vh;
}


.eMed_createapt_left1_paper2_icon {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.eMed_createapt_left1_paper2_img {
    width: 6.5vw;
    height: 6.5vw;
    border-radius: 5vw;
}

.emed_qckbill_box1 {
    height: 10vh;
    width: 93vw;
}

.emed_qckbill_box1_accordian {
    height: 10vh;
    width: 93vw;
    background-color:  #F5F8F7 !important;
}
.emed_qckbill_box1_common {
    background-color:  #ffffff !important;
}

.emed_qckbill_box2 {
    height: 60vh;
    width: 90vw;
}

.emed_qckbill_box2_Grid {
    display: flex;
    flex-direction: row;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
}

.eMed_quickbill_left {
    width: 60vw;
    height: 70vh;
    margin-top: 0.3vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.eMed_quickbill_left_view1 {
    width: 61.5vw;
    height: 7vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_quickbill_left_view2 {
    width: 60vw;
    height: 62vh;
    margin-left: 1vw;
    background-color: white;
    overflow-y: scroll;
}
 
.eMed_quickbill_right {
    width: 32vw;
    height: 70vh;
    margin-left: 1vw;
    margin-top: 0.3vw;
}

.eMed_quickbill_right_view1{
    height: 7vh;
    display: flex;
    align-items: center;
    margin-left: 0.5vw;
}

.eMed_quickbill_right_view2 {
    height: 62vh;
    background-color: white;
    overflow-y: scroll;
}

.emed_qckbill_box1_accordian_detail_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#eMed_OP_oldComments_Con {
    height: 35vh;
    margin-bottom: 1vw;

}

.eMed_OP_oldComments_Con1 {
    width: 25vw;
    border: 1px solid #E3E2E2;
    background-color: #E4F2F0;
    border-radius: 0.3vw;
    padding: 0.3vw;
    display: flex;
    flex-direction: column-reverse;
}

.eMed_OP_Cmts_Text {
    color: #616161;
    font-size: 0.7vw;
    font-weight: bold;
}

.eMed_OP_Cmts_Text1 {
    color: #616161;
    font-size: 0.5vw;
    display: flex;
    justify-content: flex-end;
}
.eMed_OP_CancelPatient{
    background-color: #E3E2E2;
}

.eMed_TextBox_SearchIcon{
    height: 2vw;
    width: 2vw;
    margin-left: 2vw;
}

.eMed_Services_chips{
    margin: 0.2vw !important;
}
.eMed_Chips_Wrapper{
    display: flex;
    flex-wrap: wrap;
    max-height: 8vw;
    overflow-y: scroll;
    margin: 0.3vw;
    padding-top: 0.5vw;
    padding-left: 1vw;
}
.eMed_Date_Time_Style{
    width: 14.5vw;
    margin: 1vw 0vw 0vw 1vw;
    border-top-right-radius:5px ;
    border-top-left-radius: 5px;
}

.eMed_service_select_div{
    height: 18vw;
}

.eMed_Buttons_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5vh;
    padding: 0.3vw;
}

.eMed_OP_no_comments_txt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
}

#eMed_OP_no_comments_div {
    height: 16vh;
    margin-bottom: 1vw;
    width: 25vw;
}

.eMed_Header_OP_home_div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Header_OP_Noshow_btn{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-bottom: 0.2vw red solid !important;
    border-radius: 2 !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
}

.eMed_Header_OP_Noshow_btn_on{
    margin-right: 1vw !important;
    text-transform: capitalize !important;
    color: black !important;
    border-bottom: 0.4vw red solid !important;
    border-radius: 2 !important;
    box-shadow: 0px 0px 5px gray !important;
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
}

.eMed_Demo_Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_Demo_Created_Con {
    display: flex;
    flex-direction: row;
    width: 4vw;
    justify-content: space-between;
    margin-right: 0.3vw;
}

#eMed_Demo_Created_Text {
    font-size: 0.6vw;
    color: #888888;
}
.eMed_App_Doctor_table_head{
    height: 2vw;
    background-color: rgb(226, 226, 226);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  .eMed_App_Doctor_table_data {
    height: 2vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 23vw;
  }

  .emedhub_popup_lobby {
    width: 100%;
    height: 36dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
  }