.image_card {
    margin-right: 0.5vw;
    padding-left: 1vw;
    height: 2.2vw;
    cursor: pointer;
}

.eMed_pharmaAdd_Container {
    padding: 0.625vw;
    height: 80vh;
    background-color: #faf9f9;
}

.eMed_pharmaAdd_mainBox {
    height: 70vh;
    margin: 0.625vw;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}

.eMed_pharmaAdd_subDiv {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    align-items: center; */
    margin: 1.5vw 0.5vw;
}

#eMed_pharmaAdd_headTxt {
    color: #616161;
    font-weight: 600;
    text-align: left;
    padding: 0.625vw;
    border-bottom: 1px solid lightgray;
}

#eMed_pharmaAdd_btnCont {
    height: 8vh;
    padding: 0.625vw;
    margin-top: 3vw;
    margin-right: 3vw;
}

.eMed_pharmaAdd_txtBox {
    width: 17vw;
}

.eMed_pharmaUpdatePop_Con {
    min-height: 14vw;
}

.eMed_pharmaUpdatePop_subDiv {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
    align-items: center; */
    margin: 0.5vw;
}

.eMed_pharmaUpdatePop_txtBox {
    width: 14vw;
}

#eMed_pharmaUpdatePop_btnCont {
    height: 5vh;
    padding: 0.625vw;
    margin-right: 0.625vw;
}

.eMed_pharmaRtnStk_container {
    height: 85vh;
}

.eMed_pharmaRtnStk_Head {
    height: 8vh;
    margin: 0.625vw;
}

#eMed_pharmaRtnStk_txt {
    color: #616161;
}

.eMed_pharmaRtnStk_content {
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_pharmaRtnStk_rtncont {
    height: 70.9vh;
}

.eMed_pharmaRtnStk_rtnDiv {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#eMed_pharmaRtnStk_rtnBtnCon {
    background-color: #faf9f9;
    height: 5vh;
    padding: 0.625vw;
}

.eMed_pharma_box{
    background-color: white;
    height: 70vh;
    box-shadow: 1px 1px 3px lightgray;
    border-radius: 10px;
}

.eMed_view_popup {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
  }