.com_billing_popup {
    width: 100%;
    /* height: 15dvw; */
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.eMed_CashCounter_IPOP_Search_Pop{
    margin-top: 29vh;
    margin-left: 32vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 23vw !important;
  }
  
.billing_tbl_head {
    background-color: #F5F8F7 !important;
    font-size: 0.85rem !important;
    font-weight: 600 !important;
    padding: 0.1rem 0.5rem !important;
}

.billing_tbl_body {
    font-size: 0.85rem !important;
    padding: 0.1rem 0.5rem !important;
}
