
.eMed_Pharma_rts_header{
    height: 15vh;
    padding: 1vw 0vw;
    display: flex;
}

.eMed_Pharma_rts_tbl_card{
    height: 65vh;
}

.eMed_Pharma_table{
    background-color: white;
    width: 92vw;
    margin: auto;
}

.eMed_Pharma_rts_btn{
    min-width: 0vw !important;
    width: 2.8vw !important; 
    height: 2vw !important;
    margin-right: 0.5vw !important;
}

.eMed_Pharma_rts_actionimg {
    width: 2.3vw;
    height: 2.3vw;
}