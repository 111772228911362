.eMed_PayCard_Icon{
    width:30%;
    display:flex;
    justify-content:center
}
.eMed_Icon_Img{
    height:2vw;
    width:2vw;
}
.eMed_PayCard_Content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
.eMed_PayCard_div {
    width: 12vw;
    height: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.eMed_Amt_card_div{
    width: 10vw;
    height: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
    overflow: visible !important;
}
.eMed_Amt_card{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width:10vw !important
}
.eMed_Amt_card_amount{
    font-size:1.1vw;
    font-weight:600;
}
.eMed_Amt_card_label{
    font-size:0.9vw !important;
    text-align: center;
}
.eMed_Amt_small_size_label{
    font-size:0.85vw !important;
    text-align: center;
}