.doc_notes_header_card {
    height: 5dvh;
    background-color: #F5F8F7;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1.5px solid lightgray; */
}

.doc_notes_tbl_header {
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    background-color: #F5F8F7 !important;
    padding: 0.3rem 0.5rem !important;
    border-bottom: none !important;
}

.doc_notes_layout {
    background-color: white !important;
    border: 1.5px solid lightgray !important;
}

.doc_notes_header_card_font {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
}

.doc_notes_tbl_body_cell {
    padding: 0.3rem 0.5rem !important;
}

.doc_autocomplete_height {
    height: 4.5dvh !important;
}

.doc_autocomp_drop_font {
    font-size: 0.9rem !important;
    padding: 0rem !important;
}

.doc_medi_popup {
    width: 100%;
    height: 48dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_med_tab {
    min-height: 0vw !important;
    height: 2.5vw !important;
}

.doc_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.doc_diagram_popup {
    width: 100%;
    height: 34dvw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_addendum_popup {
    width: 100%;
    /* height: 15dvw; */
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 60dvw;
}

.doc_med_box {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 0.7rem;
}

.doc_med_margin_top{
    margin-top: 0.5rem !important;
}

.doc_med_tbl{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}