.eMed_rts_container{
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_rts_content{
    background-color: white;
    margin: 1vw 1vw 0vw 1vw;
    width: 91.8vw;
    min-height: 6vw;
    flex-wrap: wrap;
}

.eMed_rts_content_top{
    padding: 1vw;
}

.eMed_rts_txt_one{
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vw;
}

.eMed_rts_content_btm{
    padding-top: 0.5vw;
    display: flex;
    flex-direction:row ;
    flex-wrap: wrap;
}

.eMed_rts_icon{
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 1vw;
}

.eMed_rts_btn{
    text-transform: capitalize;
}

.eMed_rts_image{
    width: 2vw;
    height: 2vw;
    margin-right: 0.5vw;
}

.eMed_Cards_Container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    padding-right: 0.5vw;
}

.eMed_Cards_amount{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5vw;
}

.eMed_Action_Container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
    padding-right: 1vw;
}

.eMed_Top_Container {
    height: 13vh;
    display: flex;
    flex-direction: row;
}

.eMed_rts_table{
    height: 66vh;
    width: 92vw;
    margin: auto;
    background-color: white;
}

.eMed_rts_filter_container{
    width:25vw;
    margin-top: 5vw;
}

.eMed_filter_Top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px slategray solid;
    padding-bottom: 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
}

.eMed_action_img_small{
    height: 1vw;
    width: 1vw;
}

.eMed_rts_dropdown{
    width: 20vw;
    margin: 1vw 1vw !important;
}
.eMed_age_container{
    width: 20vw;
    margin: 1vw 1vw !important;
    display: flex;
    flex-direction: row;
}

.eMed_rts_middle{
    width: 23vw;
    margin: auto;
    height: 75vh;
}

.eMed_date_filter{
    width: 11vw;
    margin-top: 1.5vw;
    height: 5vh;
    position: relative;
    left: 11vw;
    margin-bottom:1.5vw;
}

.eMed_rts_btn{
    padding: 0.625;
}

.eMed_rts_home_container{
    background-color: #F5F8F7;
    height: 83vh;
    margin-top: 0.7vw;
}

.eMed_rts_popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 20vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_rts_home_header{
    padding: 1vw;
}

.eMed_rts_home_txt_one{
    color: gray;
}
.eMed_rts_home_txt{
    background-color: #E4F2F0;
}

.eMed_rts_home_btn{
    margin-right:1vw !important;
    text-transform: capitalize !important;
}

.eMed_rts_home_btn_two{
    text-transform: capitalize !important;
}

.eMed_rts_fab{
    position: absolute !important;
    bottom: 5vw !important;
    right: 5vw !important;
}

.eMed_reports_text_btn{
    text-transform: capitalize !important;
    color: black !important;
}
.eMed_reports_text_btn:hover{
    text-transform: capitalize !important;
    color: var(--themColor) !important;
    transform: scale(1.01) !important;
}

.stickyHeader {
    position: "sticky";
    left: 0;
    z-index: "9999 !important";
 }

 .eMed_rts_filter_container_New{
    width:29vw;
    margin-top: 5vw;
}

.eMed_rts_middle_New{
    /* width: 29vw; */
    max-height: 74.5vh;
    overflow: auto;
}