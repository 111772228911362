.rad_home_container {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.rad_home_header_card {
    border-bottom: 1px solid rgb(203, 191, 191);
    box-shadow: 0px 2px #e3e0e0;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 7vh;
    align-items: center;
}

.rad_home_header{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rad_home_card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rad_home_icon {
    width: 4vw;
    height: 4vw;
}

.rad_home_font {
    font-size: 0.8vw;
}

.rad_home_icon_circle {
    width: 0.8vw;
    height: 0.8vw;
}

.rad_home_icon_est {
    font-size: 0.8vw;
    margin-top: -.3vw;
}

.rad_home_cards {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
}

.rad_bill_tbl_head{
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.rad_input_size {
    width: 12vw;
    height: 2.5vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    background-color: #F5F8F7;
}

.rad_demo_header_one{
    background-color: white;
    height: 15vh;
    width: 50vw;
}

.rad_demo_header_card {
    height: 15vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5vw;
}

.rad_demo_header_two{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    height: 9vh;
    padding-right: 0.5vw;
}

.rad_demo_header_lft{
    background-color: white;
    height: 15vh;
    margin-left: 1vw;
}

.rad_popup_style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78vw;
    height: 82vh;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 17%;
    margin-left: 1vw;
    cursor: pointer;
  }
  
  .editor {
    padding: 0.5vw;
    margin: 0.2vw;
  }
  .otherEditor {
    padding: 0.5vw;
    margin: 0.2vw;
    height: 45vh;
    overflow: scroll;
  }